import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';


const LDUserConfig = () => {
  const client = useLDClient();
  const state = useSelector((state) => state.auth);
  const { userId, organization, userEmail } = state;

  // sets user with launch darkly when that data becomes available
  useEffect(() => {
    if (userId && organization && userEmail) {
      client.identify({
        key: userId,
        email: userEmail,
        custom: { organization }
      }, null);
    }
  }, [userId, organization, userEmail, client]);

  // sets user with full story when that data becomes available
  useEffect(() => {
    if (userEmail) {
      window.FS.identify(userId, {
        email: userEmail
      });
    }
  }, [userEmail]);

  return null
};

export default LDUserConfig;
