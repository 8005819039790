import {
  Avatar,
  FormControl,
  Grid,
  InputLabel,
  withStyles,
  Typography,
} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "15px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 12,
    color: "red",
  },
  avtarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: "#f0f0f0",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuItem: {
    height: "22px",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.57",
    letterSpacing: "normal",
    color: "#000000",
  },
});

class SelectMapping extends Component {
  state = {};

  render() {
    const {
      classes,
      fileObject,
      handleMappingChange,
      extraInputOptions,
      handleDeleteFile,
    } = this.props;
    return (
      <div className={classes.root}>
        <hr></hr>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={3}>
            <table width="100%">
              <tr>
                <td align="left" style={{ paddingTop: "15px" }}>
                  <Avatar className={classes.avtarSmall}>
                    <FolderIcon style={{ color: "black" }}></FolderIcon>
                  </Avatar>
                </td>
                <td align="left" style={{ paddingTop: "15px" }}>
                  <span
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    <Typography style={{ overflowWrap: "anywhere" }}>
                      {fileObject["file"].name || ""}
                    </Typography>
                  </span>
                </td>
              </tr>
            </table>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="organization">Select Organization</InputLabel>
              <Select
                id="selectedOrganization"
                className={classes.selectEmpty}
                style={{
                  width: "260px",
                  height: "30px",
                }}
                value={fileObject.selectedOrganization}
                onChange={(e) =>
                  handleMappingChange(e, "selectedOrganization", fileObject)
                }
              >
                <MenuItem value="" disabled>
                  Select Organization
                </MenuItem>
                {extraInputOptions &&
                  extraInputOptions.organizations.map((mapping) => {
                    return (
                      <MenuItem
                        key={mapping.subdomain}
                        value={mapping.subdomain}
                        className={classes.menuItem}
                      >
                        {mapping.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="application">Select Application</InputLabel>
              <Select
                id="selectedApplication"
                className={classes.selectEmpty}
                style={{
                  width: "260px",
                  height: "30px",
                }}
                value={fileObject.selectedApplication}
                onChange={(e) =>
                  handleMappingChange(e, "selectedApplication", fileObject)
                }
              >
                <MenuItem value="" disabled>
                  Select file type
                </MenuItem>
                {extraInputOptions &&
                  extraInputOptions.applications.map((mapping) => {
                    return (
                      <MenuItem
                        key={mapping}
                        value={mapping}
                        className={classes.menuItem}
                      >
                        {mapping}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl className={classes.formControl}>
              <InputLabel id="engine">Select engine</InputLabel>
              <Select
                id="selectedEngine"
                className={classes.selectEmpty}
                style={{
                  width: "260px",
                  height: "30px",
                }}
                value={fileObject.selectedEngine}
                onChange={(e) =>
                  handleMappingChange(e, "selectedEngine", fileObject)
                }
              >
                <MenuItem value="" disabled>
                  Select Engine
                </MenuItem>
                {extraInputOptions &&
                  extraInputOptions.engines.map((mapping) => {
                    return (
                      <MenuItem
                        key={mapping}
                        value={mapping}
                        className={classes.menuItem}
                      >
                        {mapping}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}>
            <table width="100%">
              <tr>
                <td align="right" style={{ paddingTop: "10px" }}>
                  <Avatar className={classes.avtarSmall}>
                    <HighlightOffIcon
                      style={{ color: "black" }}
                      onClick={(e) =>
                        handleDeleteFile(e.target.value, fileObject)
                      }
                    />
                  </Avatar>
                </td>
              </tr>
            </table>
          </Grid>
          <Grid item xs={12} sm={12}>
            {fileObject && fileObject.error && (
              <Typography style={{ color: "red" }}>
                {fileObject.error}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            {fileObject["fileExtensionError"] && (
              <Typography style={{ color: "red" }}>
                {fileObject["fileExtensionError"]}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SelectMapping);
