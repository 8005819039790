import {
  PARAMETER_SET_FAIL,
  PARAMETER_SET_SUCCESS,
  PARAMETER_VERSION_SUCCESS,
  PARAMETER_VERSION__FAIL,
  ORGANIZATION_FAIL,
  ORGANIZATION_SUCCESS,
  PARAMETER_SCHEMA_SUCCESS,
  PARAMETER_READ_ONLY_STATUS_SUCCESS,
  SET_PARAMETER_READ_ONLY_STATUS_SUCCESS,
  PARAMETER_SCHEMA_FAIL,
  SCHEMA_EDITOR_SUCCESS,
  SET_ORGANIZATION,
  SET_PARAMETER_VERSION,
  SET_PARAMETER_SET,
  RESET_PARAMETER_VERSION,
  RESET_PARAMETER_VIEW,
  SAVE_PARAMETER_SCHEMA_FAIL,
  SAVE_PARAMETER_SCHEMA_SUCCESS,
  UPDATE_PARAMETER_SCHEMA,
  COPY_PARAMETER_VERSION_SUCCESS,
  COPY_PARAMETER_VERSION_FAIL,
  RESET_PARAMETER_ON_COPY,
  GET_USER_FAVOURITES,
  SAVE_USER_FAVOURITES_FAIL,
  SAVE_USER_FAVOURITES_SUCCESS,
  SET_SELECTED_FAVOURITES,
  SHOW_SELECTED_FAVOURITES,
  SET_APPLICATION,
  UPDATE_INCULDE_VERSION,
  GET_APPLICATION_TYPES,
  DELETE_PARAM_VERSION_SUCCESS,
  DELETE_PARAM_VERSION_FAIL,
  HIDE_ERROR_MESSAGE,
  SET_ORGANTIZATION_FILTER,
} from "../constants/actionTypes";

const initialState = {
  organization: [],
  parameterSet: [],
  parameterVersion: [],
  parameterSchema: [],
  schemaEditor: {},
  selectedOrganization: "",
  selectedParameterVersion: "",
  selectedParameterSet: "",
  parameterError: [],
  isSchemaUpdated: false,
  userFavourites: [],
  selectedFavourites: [],
  selectedApplication: "",
  applicationTypes: [],
  copyError: null,
  paramDeleteError: false,
  organizationFilter: "",
  read_only: false,
};

const organizationSuccess = (state, action) => {
  return {
    ...state,
    organization: action.organization,
  };
};
const organizationFail = (state, action) => {
  return {
    ...state,
    organization: [],
  };
};

const parameterSetSuccess = (state, action) => {
  return {
    ...state,
    parameterSet: action.parameterSet,
  };
};
const parameterSetFail = (state, action) => {
  return {
    ...state,
    parameterSet: [],
  };
};

const parameterReadOnlyStatusSuccess = (state, action) => {
  return {
    ...state,
    read_only: action.read_only
  }
}

const parameterVersionSuccess = (state, action) => {
  const paramVersion = action.parameterVersion;
  const filteredVersion = paramVersion.filter(
    (item) => item.application === state.selectedApplication
  );
  return {
    ...state,
    parameterVersion: filteredVersion,
  };
};
const parameterVersionFail = (state, action) => {
  return {
    ...state,
    parameterVersion: [],
  };
};

const parameterSchemaSuccess = (state, action) => {
  let modifiedSchema = action.parameterSchema;
  for (let i = 0; i < modifiedSchema.length; i++) {
    modifiedSchema[i]["schemaData"] = null;
    modifiedSchema[i]["schemaFormData"] = null;
    modifiedSchema[i]["isExpanded"] = false;
    modifiedSchema[i]["schemaUIData"] = null;
  }

  return {
    ...state,
    parameterSchema: modifiedSchema,
  };
};
const parameterSchemaFail = (state, action) => {
  return {
    ...state,
    parameterSchema: [],
  };
};

const schemaEditorSuccess = (state, action) => {
  let modifiedSchema = state.parameterSchema;
  for (let i = 0; i < modifiedSchema.length; i++) {
    if (Number(action.schemaEditor.schemaId) === Number(modifiedSchema[i].id)) {
      modifiedSchema[i]["schemaData"] =
        action.schemaEditor.schemaData.schema_json;
      modifiedSchema[i]["schemaFormData"] =
        action.schemaEditor.schemaData.values_json;
      modifiedSchema[i]["isExpanded"] = !modifiedSchema[i]["isExpanded"];
      modifiedSchema[i]["schemaUIData"] =
        action.schemaEditor.schemaData.schema_ui_json;
    }
  }

  return {
    ...state,
    schemaEditor: action.schemaEditor,
    parameterSchema: modifiedSchema,
  };
};

const setOrganization = (state, action) => {
  return {
    ...state,
    selectedOrganization: action.selectedOrganization,
    selectedParameterVersion: "",
    selectedParameterSet: "",
    selectedApplication: "",
    parameterSchema: [],
    schemaEditor: {},
    paramDeleteError: false,
  };
};
const setParameterSet = (state, action) => {
  return {
    ...state,
    selectedParameterSet: action.selectedParameterSet,
    selectedParameterVersion: "",
    parameterSchema: [],
    schemaEditor: {},
    paramDeleteError: false,
  };
};

const SetSelectedApplication = (state, action) => {
  return {
    ...state,
    selectedApplication: action.selectedApplication,
    selectedParameterVersion: "",
    selectedParameterSet: "",
    parameterSchema: [],
    schemaEditor: {},
    paramDeleteError: false,
  };
};

const setParameterVersion = (state, action) => {
  return {
    ...state,
    selectedParameterVersion: action.selectedParameterVersion,
    paramDeleteError: false,
  };
};

const resetParameterVersion = (state, action) => {
  return {
    ...state,
    parameterVersion: [],
    parameterSchema: [],
    schemaEditor: {},
    selectedParameterVersion: "",
    isCopySuccess: false,
    paramDeleteError: false,
  };
};
const resetParameterView = (state, action) => {
  return {
    ...state,
    organization: [],
    parameterSet: [],
    parameterVersion: [],
    parameterSchema: [],
    schemaEditor: {},
    selectedOrganization: "",
    selectedParameterVersion: "",
    selectedParameterSet: "",
    parameterError: [],
    isSchemaUpdated: false,
    userFavourites: [],
    selectedFavourites: [],
    selectedApplication: "",
    applicationTypes: [],
    paramDeleteError: false,
  };
};

const saveParameterSchemaSuccess = (state, action) => {
  let modifiedSchema = state.parameterSchema;
  for (let i = 0; i < modifiedSchema.length; i++) {
    modifiedSchema[i]["schemaData"] = null;
    modifiedSchema[i]["schemaFormData"] = null;
    modifiedSchema[i]["isExpanded"] = false;
    modifiedSchema[i]["schemaUIData"] = null;
  }
  return {
    ...state,
    isSchemaUpdated: action.status.success,
    parameterSchema: modifiedSchema,
    schemaEditor: {},
  };
};
const saveParameterSchemaFail = (state, action) => {
  return {
    ...state,
    parameterError: action.error.errors,
  };
};

const updateParameterSchema = (state, action) => {
  let parameterSchema = state.parameterSchema.map((schema) => {
    if (action.schema.schemaId === schema.id) {
      // const originalJson = JSON.stringify(schema.schemaData);
      // const modifiedJson = JSON.stringify(action.schema.schema);
      // if (originalJson !== modifiedJson)
      if (action.schema.isUISchema) {
        schema.schemaUIData = action.schema.schema;
      } else {
        schema.schemaData = action.schema.schema;
      }
    }
    return schema;
  });
  return {
    ...state,
    parameterSchema,
  };
};

const copyParameterVersionSuccess = (state, action) => {
  let paramVersion = state.parameterVersion;
  paramVersion.push(action.newParameterVersion.parameter_version);
  return {
    ...state,
    newParameterVersion: action.newParameterVersion.parameter_version,
    selectedParameterVersion: "",
    parameterVersion: [],
    selectedApplication: "",
    selectedOrganization: action.newParameterVersion.parameter_version.org_id,
    selectedParameterSet: "",
    parameterSet: [],
    isCopySuccess: true,
    paramDeleteError: false,
  };
};

const copyParameterVersionFail = (state, action) => {
  let errorMsg = "";
  if (action.error.status === 304) {
    errorMsg = "duplicate parameter version name";
  } else {
    errorMsg = action.error.data;
  }
  return {
    ...state,
    copyError: errorMsg,
    isCopySuccess: false,
  };
};

const reSetParameterVersiononCopy = (state, action) => {
  return {
    ...state,
    isCopySuccess: false,
    copyError: null,
  };
};

const getUserFavourites = (state, action) => {
  return {
    ...state,
    userFavourites: action.favourites,
  };
};

const saveUserFavouritesSuccess = (state, action) => {
  return {
    ...state,
    isFavSaved: true,
  };
};
const saveUserFavouritesFail = (state, action) => {
  return {
    ...state,
    isFavSaved: false,
    favSaveError: action.favSaveError,
  };
};

const setSelectedFavourites = (state, action) => {
  return {
    ...state,
    selectedFavourites: action.selectedFavourites,
  };
};

const showSelectedFavourites = (state, action) => {
  let parameterSchema = state.parameterSchema.map((schema) => {
    if (action.selectedFavourites.parameter_schema === schema.id) {
      schema.isExpanded = true;
    }
    return schema;
  });

  return {
    ...state,
    parameterSchema: parameterSchema,
  };
};

const updateInculdeInVersion = (state, action) => {
  let parameterSchema = state.parameterSchema.map((schema) => {
    if (action.schema.parameter_values.parameter_schema === schema.id) {
      schema.parameter_values.include_in_version =
        action.schema.parameter_values.include_in_version;
    }
    return schema;
  });
  return {
    ...state,
    parameterSchema: parameterSchema,
  };
};

const getApplicationTypes = (state, action) => {
  let tempApplication = [];
  for (let i = 0; i < action.applicationTypes.length; i++) {
    tempApplication.push({
      id: action.applicationTypes[i],
      name: action.applicationTypes[i],
    });
  }
  return {
    ...state,
    applicationTypes: tempApplication,
  };
};
const deleteParameterVersionSuccess = (state, action) => {
  const paramVersion = state.parameterVersion.filter(
    (item) => item.id.toString() !== action.deletedItem.deleted.id
  );
  return {
    ...state,
    selectedParameterVersion: "",
    parameterSchema: [],
    paramDeleteError: false,
    parameterVersion: paramVersion,
  };
};

const deleteParameterVersionFail = (state, action) => {
  return {
    ...state,
    paramDeleteError: true,
  };
};

const hiderErrorMessage = (state, action) => {
  return {
    ...state,
    paramDeleteError: false,
  };
};

const setOrganizationFilter = (state, action) => {
  return {
    ...state,
    organizationFilter: action.organizationFilter,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATION_SUCCESS:
      return organizationSuccess(state, action);
    case ORGANIZATION_FAIL:
      return organizationFail(state, action);
    case PARAMETER_SET_SUCCESS:
      return parameterSetSuccess(state, action);
    case PARAMETER_SET_FAIL:
      return parameterSetFail(state, action);
    case PARAMETER_VERSION_SUCCESS:
      return parameterVersionSuccess(state, action);
    case PARAMETER_VERSION__FAIL:
      return parameterVersionFail(state, action);
    case PARAMETER_SCHEMA_SUCCESS:
      return parameterSchemaSuccess(state, action);
    case PARAMETER_READ_ONLY_STATUS_SUCCESS:
      return parameterReadOnlyStatusSuccess(state, action);
    case SET_PARAMETER_READ_ONLY_STATUS_SUCCESS:
      return parameterReadOnlyStatusSuccess(state, action);
    case PARAMETER_SCHEMA_FAIL:
      return parameterSchemaFail(state, action);
    case SCHEMA_EDITOR_SUCCESS:
      return schemaEditorSuccess(state, action);
    case SET_ORGANIZATION:
      return setOrganization(state, action);
    case SET_PARAMETER_SET:
      return setParameterSet(state, action);
    case SET_PARAMETER_VERSION:
      return setParameterVersion(state, action);
    case RESET_PARAMETER_VERSION:
      return resetParameterVersion(state, action);
    case RESET_PARAMETER_VIEW:
      return resetParameterView(state, action);
    case SAVE_PARAMETER_SCHEMA_SUCCESS:
      return saveParameterSchemaSuccess(state, action);
    case SAVE_PARAMETER_SCHEMA_FAIL:
      return saveParameterSchemaFail(state, action);
    case UPDATE_PARAMETER_SCHEMA:
      return updateParameterSchema(state, action);
    case COPY_PARAMETER_VERSION_SUCCESS:
      return copyParameterVersionSuccess(state, action);
    case COPY_PARAMETER_VERSION_FAIL:
      return copyParameterVersionFail(state, action);
    case RESET_PARAMETER_ON_COPY:
      return reSetParameterVersiononCopy(state, action);
    case GET_USER_FAVOURITES:
      return getUserFavourites(state, action);
    case SAVE_USER_FAVOURITES_SUCCESS:
      return saveUserFavouritesSuccess(state, action);
    case SAVE_USER_FAVOURITES_FAIL:
      return saveUserFavouritesFail(state, action);
    case SET_SELECTED_FAVOURITES:
      return setSelectedFavourites(state, action);
    case SHOW_SELECTED_FAVOURITES:
      return showSelectedFavourites(state, action);
    case SET_APPLICATION:
      return SetSelectedApplication(state, action);
    case UPDATE_INCULDE_VERSION:
      return updateInculdeInVersion(state, action);
    case GET_APPLICATION_TYPES:
      return getApplicationTypes(state, action);
    case DELETE_PARAM_VERSION_SUCCESS:
      return deleteParameterVersionSuccess(state, action);
    case DELETE_PARAM_VERSION_FAIL:
      return deleteParameterVersionFail(state, action);
    case HIDE_ERROR_MESSAGE:
      return hiderErrorMessage(state, action);
    case SET_ORGANTIZATION_FILTER:
      return setOrganizationFilter(state, action);
    default:
      return state;
  }
};

export default reducer;
