import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../utils/axios_instance";
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, TextField, Link } from "@material-ui/core";
import moment from "moment";
import { ExpandMore } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "25px",
  },
  customerName: {
    fontWeight: 600,
  },
  group: {
    display: "flex",
    alignItems: "flex-end"
  }
}));

const OnboardingStatusDetails = ({status, classes}) => {

  const linkSlug = (subdomain, string) => {
    if (!string) {
      return null;
    }

    let env = 'dev'
    if (process.env.REACT_APP_ENV === 'production') {
      env = 'prod'
    } else if ( process.env.REACT_APP_ENV === 'qa' ) {
      env = 'qa'
    }

    return `https://s3.console.aws.amazon.com/s3/buckets/${env}-operational-data?region=us-east-2&prefix=${string}`
  }

  if(Object.keys(status).length <= 3) {
    return (
      <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
        <section style={{ paddingTop: '48px' }}>
          <Grid container spacing={1} xs={12}>
            <Grid xs={12} item className={classes.group}>
              <Grid item xs={1}>
                <Typography variant="caption">
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Skeleton variant="rectangular" width={'100%'} height={45} />
              </Grid>
            </Grid>
            <Grid xs={12} item className={classes.group}>
              <Grid item xs={1}>
                <Typography variant="caption">
                  <Skeleton />
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <Skeleton variant="rectangular" width={'100%'} height={45} />
              </Grid>
            </Grid>
          </Grid>
        </section>
      </Grid>
    )
  }

  return (
    <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
      <section style={{ paddingTop: '48px' }}>
        <Grid container spacing={1} xs={12}>
          <Grid xs={12} item className={classes.group}>
            <Grid item xs={1}>
              <Typography>Total Loads</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                variant="filled"
                size="small"
                value={status.load_count || ''}
                readonly
                fullWidth />
            </Grid>
          </Grid>
        </Grid>
      </section>
      <section style={{ paddingTop: '48px' }}>
        <Grid container xs={12} spacing={1}>
          <Grid xs={12} item className={classes.group}>
            <Grid item xs={1}>
              <Typography>Relay Pars</Typography>
            </Grid>
            <Grid item xs={5}>
              <Link
                target="_blank"
                rel="noopener"
                underline="always"
                href={linkSlug(status.subdomain, status.latest_forecasting_vfa_paths[0]?.forecasting_relay_pars_path)}
              >
                <TextField
                  variant="filled"
                  size="small"
                  value={linkSlug(status.subdomain, status.latest_forecasting_vfa_paths[0]?.forecasting_relay_pars_path) || ''}
                  multiline
                  fullWidth />
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.group}>
            <Grid item xs={1}>
              <Typography>Shipper Pars</Typography>
            </Grid>
            <Grid item xs={5}>
              <Link
                target="_blank"
                rel="noopener"
                underline="always"
                href={linkSlug(status.subdomain, status.latest_forecasting_vfa_paths[0]?.forecasting_shipper_file_path)}
              >
                <TextField
                  variant="filled"
                  size="small"
                  value={linkSlug(status.subdomain, status.latest_forecasting_vfa_paths[0]?.forecasting_shipper_file_path) || ''}
                  multiline
                  fullWidth />
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.group}>
            <Grid item xs={1}>
              <Typography>Distance Pickle</Typography>
            </Grid>
            <Grid item xs={5}>
              <Link
                target="_blank"
                rel="noopener"
                underline="always"
                href={linkSlug(status.subdomain, status.latest_forecasting_vfa_paths[0]?.forecasting_distance_pickle)}
              >
                <TextField
                  variant="filled"
                  size="small"
                  value={linkSlug(status.subdomain, status.latest_forecasting_vfa_paths[0]?.forecasting_distance_pickle) || ''}
                  multiline
                  fullWidth />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </section>
      <section style={{ paddingTop: '48px' }}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Grid item className={classes.group}>
              <Grid item xs={1}>
                <Typography>Pickle</Typography>
              </Grid>
              <Grid item xs={5}>
                <Link
                  target="_blank"
                  rel="noopener"
                  underline="always"
                  href={linkSlug(status.subdomain, status.latest_forecasting_vfa_paths[0]?.forecasting_pickle_path)}
                >
                  <TextField
                    variant="filled"
                    size="small"
                    value={linkSlug(status.subdomain, status.latest_forecasting_vfa_paths[0]?.forecasting_pickle_path) || ''}
                    multiline
                    fullWidth />
                </Link>
              </Grid>
              <Grid item xs={1} />
              <Grid container spacing={1} xs={4}>
                <Grid item xs={3} className={classes.group}>
                  <Typography>Start</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="filled"
                    size="small"
                    value={moment(status.object_pickle_status?.init_date).format("DD MMM YYYY") || ''}
                    fullWidth />
                </Grid>
                <Grid item xs={3} className={classes.group}>
                  <Typography>End</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="filled"
                    size="small"
                    value={moment(status.object_pickle_status?.end_date).format("DD MMM YYYY") || ''}
                    fullWidth />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item className={classes.group}>
              <Grid item xs={1}>
                <Typography>V2 Pickle</Typography>
              </Grid>
              <Grid item xs={5}>
                <Link
                  target="_blank"
                  rel="noopener"
                  underline="always"
                  href={linkSlug(status.subdomain, status.v2OperationalModel?.forecasting_pickle_path)}
                >
                  <TextField
                    variant="filled"
                    size="small"
                    value={linkSlug(status.subdomain, status.v2OperationalModel?.forecasting_pickle_path) || ''}
                    multiline
                    fullWidth />
                </Link>
              </Grid>
              <Grid item xs={1} />
              <Grid container spacing={1} xs={4}>
                <Grid item xs={3} className={classes.group}>
                  <Typography>Start</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="filled"
                    size="small"
                    value={moment(status.v2OperationalRun?.init_date_training, 'YYYYMMDD').format("DD MMM YYYY") || ''}
                    fullWidth />
                </Grid>
                <Grid item xs={3} className={classes.group}>
                  <Typography>End</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="filled"
                    size="small"
                    value={moment(status.v2OperationalRun?.end_date_training, 'YYYYMMDD').format("DD MMM YYYY") || ''}
                    fullWidth />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.group}>
            <Grid item xs={1}>
              <Typography>Samples</Typography>
            </Grid>
            <Grid item xs={5}>
              <Link
                target="_blank"
                rel="noopener"
                underline="always"
                href={linkSlug(status.subdomain, status.latest_forecasting_vfa_paths[0]?.forecasting_zip_path)}
              >
                <TextField
                  variant="filled"
                  size="small"
                  value={linkSlug(status.subdomain, status.latest_forecasting_vfa_paths[0]?.forecasting_zip_path) || ''}
                  multiline
                  fullWidth />
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.group}>
            <Grid item xs={1}>
              <Typography>V2 Samples</Typography>
            </Grid>
            <Grid item xs={5}>
              <Link
                target="_blank"
                rel="noopener"
                underline="always"
                href={linkSlug(status.subdomain, status.v2OperationalModel?.forecasting_zip_path)}
              >
                <TextField
                  variant="filled"
                  size="small"
                  value={linkSlug(status.subdomain, status.v2OperationalModel?.forecasting_zip_path) || ''}
                  multiline
                  fullWidth />
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.group}>
            <Grid item xs={1}>
              <Typography>VFA</Typography>
            </Grid>
            <Grid item xs={5}>
              <Link
                target="_blank"
                rel="noopener"
                underline="always"
                href={linkSlug(status.subdomain, status.latest_forecasting_vfa_paths[0]?.vfas_zip_path)}
              >
                <TextField
                  variant="filled"
                  size="small"
                  value={linkSlug(status.subdomain, status.latest_forecasting_vfa_paths[0]?.vfas_zip_path) || ''}
                  multiline
                  fullWidth />
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.group}>
            <Grid item xs={1}>
              <Typography>V2 Model Status Payload</Typography>
            </Grid>
            <Grid item xs={5}>              
              <TextField
                variant="filled"
                size="small"
                value={JSON.stringify(status.v2) || ''}
                multiline
                maxRows={8}
                fullWidth />
            </Grid>
          </Grid>
        </Grid>
      </section>
      <Grid style={{fontWeight: 600, padding: '32px', textAlign: 'center'}} item xs={12}>Initialization Folder for {status.subdomain}</Grid>
    </Grid>
  )
}

export default function OnboardingStatus() {
  const classes = useStyles();
  const [statusData, setStatusData] = useState([]);

  const getOnboardingStatus = async () => {
    await axios
      .get(('/application-setting/get-initialization-orglist'))
      .then(response => {
        const data = response.data.results;
        data.sort((a, b) => (a.subdomain > b.subdomain) ? 1 : -1);
        setStatusData(data);
      }).catch(err => console.log(err))
  }

  const getOnboardingStatusDetail = async (subdomain) => {
    await axios
      .get((`/application-setting/get-initialization-status/${subdomain}`))
      .then(({data}) => {
        setStatusData((currentData) => {
          return currentData.map((cd) => {
            if(cd.id === data.id) {
              const v2OperationalModel = data.v2?.orgs[0]?.latest_files.find((model) => {
                return model.model_type === 'OPERATIONAL'
              })

              const v2LatestOperationalUpdate = data.v2?.orgs[0]?.latest_sessions.find((session) => {
                return session.message_payload?.runs[0]?.action === 'RUN_FORECASTING_UPDATE_V2'
              })?.message_payload?.runs[0]

              return {
                'v2OperationalModel': v2OperationalModel,
                'v2OperationalRun': v2LatestOperationalUpdate,
                ...cd,
                ...data
              }
            } else {
              return cd
            }
          })
        });
      }).catch(err => console.log(err))

  }

  useEffect(() => {
    getOnboardingStatus()
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {statusData.map(status =>
          <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
            <Accordion onClick={() => getOnboardingStatusDetail(status.subdomain)}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.customerName}>
                  {status.subdomain}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <OnboardingStatusDetails status={status} classes={classes} />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
