import Cookies from "universal-cookie";
import EnvConfig from "../config/config";
const cookies = new Cookies();

const domain =
  window.location.hostname === "localhost"
    ? window.location.hostname
    : EnvConfig.getConfig().domain;
const cookieKey = (key) => `${EnvConfig.getConfig().env || ""}${key}`;
const cookieOptions = {
  path: "/",
  domain,
};

const set = (key, value) => {
  cookies.set(cookieKey(key), value, cookieOptions);
};

const get = (key) => cookies.get(cookieKey(key));

const remove = (key) => {
  return cookies.remove(cookieKey(key), cookieOptions);
};

export default { set, get, remove };
