/* eslint-disable react/jsx-no-target-blank */
import { Avatar, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../utils/axios_instance";
import { makeStyles } from "@material-ui/core/styles";
import Editor from "@monaco-editor/react";
import { KeyboardArrowLeft } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "20px",
  },
  btnStyle: {
    marginTop: theme.spacing(2),
    backgroundColor: "#312e3a",
    color: "#fff",
    justifyContent: "inherit",
    "&:hover": {
      backgroundColor: "#47434f",
    },
  },
  capitalize: {
    textTransform: "capitalize",
  },

  paperStyle: {
    paddingTop: "10%",
    textAlign: "center",
  },
  header: {
    fontWeight: "600",
  },
  smallMapIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: "#332d3c",
    cursor: "pointer",
  },
  actionLink: {
    fontSize: "1.6rem",
    color: "blue",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: "blue",
      textDecoration: "underline",
    },
  },
}));

const KPIRun = (props) => {
  const [engineRunDetail, setEngineRunDetail] = useState(null);
  const [fullPayload, setFullPayload] = useState(null);
  const [error, setError] = useState(null);
  let editorRef = useRef();
  let editorFullpayLoadRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    getEngineRunDetail();
    return () => {
      //cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleValueEditorDidMount(_, editorReference) {
    editorRef.current = editorReference;
    setTimeout(() => {
      editorRef.current.getAction("editor.action.formatDocument").run();
    }, 2000);
  }
  function handleFullpayLoadEditorDidMount(_, editorReference) {
    editorFullpayLoadRef.current = editorReference;
    setTimeout(() => {
      editorFullpayLoadRef.current
        .getAction("editor.action.formatDocument")
        .run();
    }, 2000);
  }

  const getEngineRunDetail = async () => {
    try {
      const response = await axios.get(
        `/parameters/kpi-runs-api/${props.match.params.id}/`
      );
      if (response && response.status === 200) {
        setEngineRunDetail(response.data);

        setFullPayload(JSON.stringify(response.data));
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const getRunStatus = (payLoad) => {
    return JSON.stringify(payLoad.status_payload);
  };

  const onBack = () => {
    props.history.goBack();
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.header}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container item xs={11}>
          {error && (
            <Grid item xs={12} style={{ color: "red", paddingBottom: "10px" }}>
              {`Error : ${error}`}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            onClick={() => onBack()}
            style={{
              display: "flex",
              cursor: "pointer",
              paddingBottom: "20px",
            }}
          >
            <Grid>
              <Avatar className={classes.smallMapIcon}>
                <KeyboardArrowLeft style={{ color: "white" }} />
              </Avatar>
            </Grid>
            <Grid
              style={{
                paddingTop: "5px",
                paddingLeft: "5px",
                cursor: "pointer",
              }}
            >
              Back to KPI Runs
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ display: "flex", paddingBottom: "10px" }}>
            <Grid item xs={3}>
              <Typography
                variant="h4"
                style={{ fontSize: "1.6rem", paddingBottom: "10px" }}
              >
                KPI Run
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.header}>
              {`Run Action : ${
                engineRunDetail ? engineRunDetail.run_action : ""
              }`}
            </Grid>
            <Grid item xs={5} className={classes.header} data-testid='kpi-creation-date'>
              {`Creation  Date : ${
                engineRunDetail ? engineRunDetail.created_at : ""
              }`}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ display: "flex", paddingBottom: "15px" }}>
            <Grid item xs={3} data-testid='kpi-run-id'>
              {`Run ID : ${
                engineRunDetail && engineRunDetail.payload
                  ? engineRunDetail.payload.task_id
                  : ""
              }`}
            </Grid>
            <Grid item xs={4} className={classes.header}>
              {`Run Type : ${
                engineRunDetail && engineRunDetail.payload
                  ? engineRunDetail.payload.action
                  : ""
              }`}
            </Grid>
            <Grid item xs={5} className={classes.header}></Grid>
          </Grid>
          {engineRunDetail && engineRunDetail.payload && (
            <>
              <Grid
                item
                xs={12}
                style={{ display: "flex", paddingBottom: "10px" }}
              >
                <Grid item xs={3}></Grid>
                <Grid item xs={3}>
                  <a
                    target="_blank"
                    className={classes.actionLink}
                    href={`${engineRunDetail.input_folder}`}
                  >
                    <Typography variant="h4" className={classes.actionLink}>
                      Input Folder
                    </Typography>
                  </a>
                </Grid>
                <Grid item xs={3}>
                  <a
                    className={classes.actionLink}
                    target="_blank"
                    href={`${engineRunDetail.output_folder}`}
                  >
                    <Typography variant="h4" className={classes.actionLink}>
                      Output Folder
                    </Typography>
                  </a>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  border: "1px solid #000000",
                  marginBottom: "30px",
                }}
              >
                <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                  Run Status :
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    height="45vh"
                    theme={"light"}
                    language={"json"}
                    editorDidMount={handleValueEditorDidMount.bind(this)}
                    options={options}
                    value={getRunStatus(engineRunDetail)}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} style={{ border: "1px solid #000000" }}>
            <Grid item xs={12} style={{ paddingBottom: "10px" }}>
              Full Payload :
            </Grid>
            <Grid item xs={12}>
              <Editor
                height="55vh"
                theme={"light"}
                language={"json"}
                editorDidMount={handleFullpayLoadEditorDidMount.bind(this)}
                options={options}
                value={fullPayload}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default KPIRun;

const options = {
  acceptSuggestionOnCommitCharacter: true,
  acceptSuggestionOnEnter: "on",
  accessibilitySupport: "auto",
  autoIndent: true,
  automaticLayout: true,
  codeLens: false,
  colorDecorators: true,
  contextmenu: true,
  cursorBlinking: "blink",
  cursorSmoothCaretAnimation: false,
  cursorStyle: "line",
  disableLayerHinting: false,
  disableMonospaceOptimizations: false,
  dragAndDrop: false,
  fixedOverflowWidgets: false,
  folding: true,
  foldingStrategy: "auto",
  fontLigatures: false,
  formatOnPaste: true,
  formatOnType: true,
  hideCursorInOverviewRuler: false,
  highlightActiveIndentGuide: false,
  links: true,
  mouseWheelZoom: false,
  multiCursorMergeOverlapping: false,
  multiCursorModifier: "alt",
  overviewRulerBorder: false,
  overviewRulerLanes: 2,
  quickSuggestions: true,
  quickSuggestionsDelay: 100,
  readOnly: false,
  renderControlCharacters: false,
  renderFinalNewline: true,
  renderIndentGuides: true,
  renderLineHighlight: "all",
  renderWhitespace: "none",
  revealHorizontalRightPadding: 30,
  roundedSelection: true,
  rulers: [],
  scrollBeyondLastColumn: 5,
  scrollBeyondLastLine: true,
  selectOnLineNumbers: true,
  selectionClipboard: true,
  selectionHighlight: true,
  showFoldingControls: "mouseover",
  smoothScrolling: true,
  suggestOnTriggerCharacters: true,
  wordBasedSuggestions: true,
  wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
  wordWrap: "off",
  wordWrapBreakAfterCharacters: "\t})]?|&,;",
  wordWrapBreakBeforeCharacters: "{([+",
  wordWrapBreakObtrusiveCharacters: ".",
  wordWrapColumn: 80,
  wordWrapMinified: false,
  wrappingIndent: "none",
};
