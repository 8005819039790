import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#332D3C",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: "#332D3C",
        //color: "#00000",
      },

      daysHeader: {
        color: "#00000",
        fontWeight: "600",
      },
      dayLabel: {
        color: "#00000",
        fontWeight: "600",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#000000",
        "&:hover": {
          backgroundColor: "#332D3C",
          color: "#ffffff",
        },
      },
      daySelected: {
        backgroundColor: "#332D3C",
        "&:hover": {
          backgroundColor: "#332D3C",
        },
      },
      dayDisabled: {
        color: "grey",
      },
      current: {
        color: "#332D3C",
      },
    },
    MuiPickersClock: {
      clock: {
        // backgroundColor: "#332D3C",
      },
      pin: {
        backgroundColor: "#332D3C",
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: "#332D3C",
        "&:hover": {
          backgroundColor: "#332D3C",
        },
      },
      thumb: {
        border: "14px solid #332D3C",
      },
      noPoint: {
        backgroundColor: "#332D3C",
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: "#000000",
      },
      clockNumberSelected: {
        backgroundColor: "#332D3C",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        backgroundColor: "#332D3C",
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#231f29",
      },
    },
  },
});

export const DateTimeContainer = ({
  title,
  style,
  value,
  handleChange,
  onlyDatePicker = false,
  disableFuture = false,
}) => {
  value = value && value !== "00/00/0000" ? value : null;
  if (value !== null) {
    value = moment(value, "YYYY-MM-DD HH:mm A").format("YYYY-MM-DDTHH:mm");
  }

  const onDateChange = (date) => {
    const format = onlyDatePicker ? "YYYY-MM-DD" : "YYYY-MM-DDTHH:mm";
    const formatedDate = moment(date).format(format);
    handleChange(formatedDate);
  };

  if (onlyDatePicker) {
    return (
      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            label={title}
            value={value}
            variant="inline"
            inputVariant="outlined"
            onChange={(e) => onDateChange(e)}
            format="yyyy/MM/dd"
            style={{ width: "100%", ...style }}
            inputProps={{
              style: { cursor: "pointer", fontSize: "14px", width: "100%" },
            }}
            allowKeyboardControl="true"
            animateYearScrolling="true"
            disableFuture={disableFuture}
            //disableToolbar="false"
            //invalidDateMessage="Date should be in yyyy/MM/dd HH:mm format."
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            autoOk
            label={title}
            value={value}
            variant="inline"
            inputVariant="outlined"
            onChange={(e) => onDateChange(e)}
            format="yyyy/MM/dd HH:mm"
            style={{ width: "100%", ...style }}
            inputProps={{
              style: { cursor: "pointer", fontSize: "14px", width: "100%" },
            }}
            allowKeyboardControl="true"
            animateYearScrolling="true"
            //disableToolbar="false"
            //invalidDateMessage="Date should be in yyyy/MM/dd HH:mm format."
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
};
