export const HISTORICAL_PERFORMANCE = 'Historical Performance'
export const ADOPTION_ADHERENCE = 'Adoption & Adherence'

export const ANALYSIS_TYPES = [
    {"name": HISTORICAL_PERFORMANCE},
    {"name": ADOPTION_ADHERENCE}
]
export const ANALYSIS_TYPES_ENDPOINT_MAPPING = {
    [HISTORICAL_PERFORMANCE]: 'historical-data/pov',
    [ADOPTION_ADHERENCE]: 'historical-data/adoption-metrics'
}