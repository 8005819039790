import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth0 } from "@auth0/auth0-react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import DefaultDashboard from "./layouts/default_dashboard";
import LoginView from "./components/login/login";
import PageNotFound from "./components/404/page_not_found";
import configureStore from "./store/configureStore";
import parameterView from "./components/parameterView/parameterView";
import HistoricalAnalysisView from './components/historicalAnalysis/historicalAnalysisView'
import SchemaView from "./components/parameterView/schemaView";
import ExtraFiles from "./components/uploader/Upload";
import EngineRuns from "./components/engineRun/engineRuns";
import IndividualRuns from "./components/engineRun/individualRuns";
import UploadRuns from "./components/engineRun/uploadRun";
import EngineRun from "./components/engineRun/engineRun";
import ForecastingRuns from "./components/forecastingRun/forecastingRuns";
import ForecastingRun from "./components/forecastingRun/forecastingRun";
import IMRuns from "./components/IMRun/IMRuns";
import IMRun from "./components/IMRun/IMRun";
import KPIRuns from "./components/KPIRun/KPIRuns";
import KPIRun from "./components/KPIRun/KPIRun";
import Settings from "./components/settings/settingsView";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import EnvConfig from "./config/config";
import LDUserConfig from './config/ldUserConfig';


const store = configureStore();
const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ["IBM Plex Sans", "sans-serif"].join(","),
    fontWeight: 600,
  },
});

const styles = () => ({});

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const {
    location: { search },
  } = rest;
  const isAuth0 = search.includes("code=");
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {isAuthenticated || isLoading ? (
            <Layout history={props.history}>
              <Component {...props} />
            </Layout>
          ) : (
            !isAuth0 && <Redirect to="/sign-in" />
          )}
        </>
      )}
    />
  );
};

const App = () => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
    <LDUserConfig />
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>core-ai-admin-ui</title>
        </Helmet>
        <Router>
          <Switch>
            <AppRoute
              exact
              path="/"
              layout={DefaultDashboard}
              component={parameterView}
            />
            <AppRoute
              exact
              path="/parameterView"
              layout={DefaultDashboard}
              component={parameterView}
            />
            <AppRoute
              exact
              path="/schema"
              layout={DefaultDashboard}
              component={SchemaView}
            />
            <AppRoute
              exact
              path="/ExtraFiles"
              layout={DefaultDashboard}
              component={ExtraFiles}
            />
            <AppRoute
              exact
              path="/engine-runs"
              layout={DefaultDashboard}
              component={EngineRuns}
            />
            <AppRoute
              exact
              path="/engine-runs/:id"
              layout={DefaultDashboard}
              component={EngineRun}
            />
            <AppRoute
              exact
              path="/forecasting-runs"
              layout={DefaultDashboard}
              component={ForecastingRuns}
            />
            <AppRoute
              exact
              path="/forecasting-runs/:id"
              layout={DefaultDashboard}
              component={ForecastingRun}
            />
            <AppRoute
              exact
              path="/im-runs"
              layout={DefaultDashboard}
              component={IMRuns}
            />
            <AppRoute
              exact
              path="/kpi-runs"
              layout={DefaultDashboard}
              component={KPIRuns}
            />
            <AppRoute
              exact
              path="/historical-analysis"
              layout={DefaultDashboard}
              component={HistoricalAnalysisView}
            />
            <AppRoute
              exact
              path="/im-runs/:id"
              layout={DefaultDashboard}
              component={IMRun}
            />
            <AppRoute
              exact
              path="/kpi-runs/:id"
              layout={DefaultDashboard}
              component={KPIRun}
            />
            <AppRoute
              exact
              path="/settings"
              layout={DefaultDashboard}
              component={Settings}
            />
            <AppRoute
              exact
              path="/individual-runs"
              layout={DefaultDashboard}
              component={IndividualRuns}
            />
            <AppRoute
              exact
              path="/upload-runs"
              layout={DefaultDashboard}
              component={UploadRuns}
            />
            <Route exact path="/sign-in" component={LoginView} />
            <Route path="/404" component={PageNotFound} />
            <Redirect path="*" to="/404" />
          </Switch>
        </Router>
      </div>
    </Provider>
  </MuiThemeProvider>
);

export default withLDProvider(
  {
    clientSideID: EnvConfig.getConfig().clientSideId,
    user: {
      key: EnvConfig.getConfig().sdkKey
    },
  },
  withStyles(styles, { withTheme: true })
)(App);