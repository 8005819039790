import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
export default function DialogOverrideParam(props) {
  return (
    <div>
      <Dialog
        open={props.show}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Override Parameter : {props.paramName}{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide the value you want to override.
          </DialogContentText>
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Parameter Value"
            id="name"
            type="text"
            style={{ minHeight: "50px", width: "100%" }}
            onChange={props.onParamValueOverride}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={props.handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
