import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Button, TextField } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { SelectContainer } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { GetApplicationTypes } from "../../store/actions/parameterViewActions";
import axios from "../../utils/axios_instance";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "25px",
  },
}));

export default function EditAppSettings(props) {
  const { settings, onSaveSuccess } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.parameterView);
  const [isEditMode, setEditMode] = useState(false);
  const [selectedApplication, setselectedApplication] = useState("");
  const [settingName, setSettingname] = useState("");
  const [error, setError] = useState(null);
  useEffect(() => {
    dispatch(GetApplicationTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (settings) {
      setSettingname(settings.name);
      setselectedApplication(settings.application);
      setEditMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const handleApplicationChange = (e) => {
    setselectedApplication(e.target.value);
  };
  const handleSettingNameChange = (e) => {
    setSettingname(e.target.value);
  };

  const createUpdateSettings = async () => {
    setError(null);
    if (!settingName && !selectedApplication) return;
    try {
      if (isEditMode) {
        const data = {
          name: settingName,
          application: selectedApplication,
        };
        const response = await axios
          .put(`/application-setting/${settings.id}/`, data)
          .catch(function (error) {
            if (error.response) {
              setError(error.response.data.error);
            }
          });
        if (response) {
          setselectedApplication("");
          setSettingname("");
          onSaveSuccess();
        }
      } else {
        const data = {
          name: settingName,
          application: selectedApplication,
        };
        const response = await axios
          .post(`/application-setting/`, data)
          .catch(function (error) {
            if (error.response) {
              setError(error.response.data.error);
            }
          });
        if (response) {
          setselectedApplication("");
          setSettingname("");
          onSaveSuccess();
        }
      }
    } catch (error) {
      setError("some error occured.");
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid item xs={12} style={{ display: "flex" }}>
            <SelectContainer
              style={{ width: "400px", marginRight: "25px" }}
              type={"Application Select"}
              title={"Application"}
              value={selectedApplication || ""}
              optionLabel={"name"}
              valueLabel={"id"}
              options={state.applicationTypes}
              handleChange={(e) => handleApplicationChange(e)}
            />

            <TextField
              style={{ width: "400px", marginRight: "25px" }}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={settingName || ""}
              onChange={(e) => handleSettingNameChange(e)}
            />

            <Button
              style={{ width: "200px", margin: "0px !important" }}
              variant="contained"
              color="default"
              className="primaryBtnSmll"
              endIcon={<ArrowForward />}
              onClick={() => createUpdateSettings()}
            >
              {isEditMode ? "Update" : "Create"}
            </Button>
          </Grid>
        </Grid>
        {error !== null && (
          <Grid item xs={12} style={{ color: "red" }}>
            {error}
          </Grid>
        )}
      </Grid>
    </div>
  );
}
