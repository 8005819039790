import React from "react";
import { SvgIcon } from "@material-ui/core/";

export const FilterIcon = (props) => {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M2 2L7 9 7 12.5 9 14 9 9 14 2z" />
    </SvgIcon>
  );
};
