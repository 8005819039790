import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Avatar } from "@material-ui/core/";
import { KeyboardArrowLeft } from '@material-ui/icons';
import { Button, TextField } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import axios from "../../utils/axios_instance";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const styles = (theme) => ({
  root: {
    paddingTop: "20px",
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  icon: {
    backgroundColor: "#f0f0f0",
    color: "#000000",
    fontWeight: "500",
    margin: theme.spacing(1),
    width: "32px",
    cursor: "pointer",
    height: "32px",
    "& > *": {
      fontSize: "1.3rem",
    },
    "&:hover": {
      backgroundColor: "#e8e8e8",
    },
    "&:disabled": {
      backgroundColor: "#b1b3b3",
    },
  },
  smallMapIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: '#332d3c',
  },
});

class IndividualRuns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFilePath : "",
      runIdentifier : "",
      multiRunConfig : "",
      disableForm : false
    };
  }

  componentDidMount() {

  }

  onBack = () => {
    this.props.history.goBack();
  }

  handleInputPathChange = (event) => {
    this.setState({inputFilePath: (event.target.value)});
  }

  handleRunIndentifierChange = (event) => {
    this.setState({runIdentifier: (event.target.value)});
  }

   handleMultiRunConfigChange = (event) => {
    this.setState({multiRunConfig: (event.target.value)});
  }

  handleClearState = (event) => {
    this.setState({inputFilePath: "", runIdentifier: "", multiRunConfig: "", disableForm: false});
  }

  inputFilePathValiation(input_file_path){
      const input_file_path_validation = /(tar|zip|gz)$/.test(input_file_path)
      return input_file_path_validation
  }

  multiRunConfigValidation(multi_run_config){
    try {
      console.log(multi_run_config)
      let json_config = JSON.parse(multi_run_config);
      if(Object.keys(json_config).length === 0){
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;  
  }

  runEngine = async () => {
    try {
      const data = {
        input_file_path: this.state.inputFilePath,
        run_id: this.state.runIdentifier,
        multi_run_config: this.state.multiRunConfig.replace(/\s+/g, '')
      };

      const input_file_path_validation = this.inputFilePathValiation(data.input_file_path)
      const multi_run_config_validation = this.multiRunConfigValidation(data.multi_run_config)

      if (input_file_path_validation && multi_run_config_validation){
        const response = await axios
          .post(`/parameters/engine-runs-api/individual-smart-tl-run/`, data)
          .catch(function (error) {
            toast.error(error.message);
            console.log(error);
          });
        if (response) {
          toast.success("Successfully submitted new engine run!");
          console.log(response);
          this.handleClearState();
        }
      }
      else{
        let error_message = ""
        if (!input_file_path_validation){
          error_message = "Check that your input file path ends in one of the following: [tar, zip, tar.gz]"
        }else{
          error_message = "Check that your multi run json is a valid json"      
        }
        toast.error(error_message);
        console.log(error_message);   
      }
    } catch (error) {
        toast.error(error.message);
        console.log(error);   
    }
    
  }

  render() {
    const { classes } = this.props;
    const { inputFilePath, runIdentifier, multiRunConfig, disableForm } = this.state;
    return (
      <div className={classes.root}>
        <ToastContainer />
        <Grid
          container
          className={classes.header}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid container item xs={11}>
            <Grid
              item
              xs={12}
              onClick={() => this.onBack()}
              style={{
                display: 'flex',
                cursor: 'pointer',
                paddingBottom: '20px',
              }}
            >
              <Grid>
                <Avatar className={classes.smallMapIcon}>
                  <KeyboardArrowLeft style={{ color: 'white' }} />
                </Avatar>
              </Grid>
              <Grid
                style={{
                  paddingTop: '5px',
                  paddingLeft: '5px',
                }}
              >
                Back to SMART-TL Engine Runs
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.tableContainer}>
              <TextField
                id="outlined-basic"
                label="Input File S3 Path"
                variant="outlined"
                onChange={(e) => this.handleInputPathChange(e)}
                value={inputFilePath}
                disabled={disableForm}
              />
            </Grid>
            <Grid item xs={12} className={classes.tableContainer}>
              <TextField
                id="outlined-basic"
                label="Run Identifier"
                type="number"
                variant="outlined"
                onChange={(e) => this.handleRunIndentifierChange(e)}
                value={runIdentifier}
                disabled={disableForm}
              />
            </Grid>
            <Grid item xs={12} className={classes.tableContainer}>
              <textarea
                id="outlined-basic"
                label="Multi Run Config"
                variant="outlined"
                rows={25}
                cols={50}
                onChange={(e) => this.handleMultiRunConfigChange(e)}
                value={multiRunConfig}
                disabled={disableForm}
              />
            </Grid>
            <Grid item xs={12} className={classes.tableContainer}>
              <Button
                variant="contained"
                color="default"
                endIcon={<ArrowForward />}
                onClick={() => this.runEngine()}
                disabled={disableForm}
              >
                Run Engine
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(IndividualRuns);
