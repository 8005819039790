import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { TextField, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import Editor from "@monaco-editor/react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Button from "@material-ui/core/Button";
import API from "../../utils/axios_instance";
import { Alert, AlertTitle } from "@material-ui/lab";
import { SelectContainer } from "../../common/index";
import {
  GetParameterSet,
  ReSetParameterView,
  SetParameterSet,
} from "../../store/actions";
const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  rootAccordian: {
    width: "100%",
    paddingBottom: "15px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridSection: {
    paddingBottom: "10px;",
  },
  btnParamAction: {
    "& > *": {
      margin: theme.spacing(1),
    },
    textAlign: "end",
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    width: 310,
    textAlign: "left",
    padding: 5,
    margin: 5,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    backgroundColor: "#fff",
    padding: "10px",
    minWidth: "30%",
    minHeight: "30%",
    "&:focus": {
      outline: "none",
    },
  },
  secondary: {
    color: "red",
  },
});

class CreateSchema extends Component {
  constructor() {
    super();
    this.valueEditorRef = React.createRef();
    this.schemaEditorRef = React.createRef();
    this.state = {
      isValueEditorReady: false,
      isSchemaEditorReady: false,
      language: "json",
      theme: "light",
      jsonValues: {},
      generatedSchema: null,
      createError: null,
      isCreateError: false,
      schemaName: "",
      keyName: "",
      valueJson: {},
    };
  }

  async componentDidMount() {
    this.props.reset();
    await this.props.getParameterSet();
  }

  handleParameterSetChange = (e) => {
    this.props.setSelectedParameterSet(e.target.value);
  };

  handleValueEditorDidMount(_, editorReference) {
    this.valueEditorRef.current = editorReference;
    this.setState({ isValueEditorReady: true });
    setTimeout(() => {
      this.valueEditorRef.current
        .getAction("editor.action.formatDocument")
        .run();
    }, 200);
  }
  handleSchemaEditorDidMount(_, editorReference) {
    this.schemaEditorRef.current = editorReference;
    this.setState({ isSchemaEditorReady: true });
    setTimeout(() => {
      this.schemaEditorRef.current
        .getAction("editor.action.formatDocument")
        .run();
    }, 200);
  }

  convertValuesToJson = async () => {
    const valueJson = {
      values_json: JSON.parse(this.valueEditorRef.current.getValue()),
    };
    const response = await API.post(
      "/parameters/get-schema-from-values/",
      valueJson
    );
    if (response && response.status === 200) {
      this.setState({ generatedSchema: response.data, valueJson });

      setTimeout(() => {
        this.schemaEditorRef.current
          .getAction("editor.action.formatDocument")
          .run();
      }, 200);
    }
  };

  createSchemaEntities = async () => {
    this.setState({ createError: null, isCreateError: false });
    let generatedSchema = {};
    const values_json = this.state.valueJson.values_json;
    const schema_json = JSON.parse(this.schemaEditorRef.current.getValue());
    generatedSchema["parameter_set"] = this.props.selectedParameterSet;
    generatedSchema["name"] = this.state.schemaName;
    generatedSchema["key_name"] = this.state.keyName;
    generatedSchema["values_json"] = values_json;
    generatedSchema["schema_json"] = schema_json;
    debugger;
    try {
      const response = await API.post(
        "/parameters/create-schema-entities/",
        generatedSchema
      );
      if (response && response.status === 200) {
        alert("Schema created successfully");
        this.setState({
          generatedSchema: null,
          createError: null,
          isCreateError: false,
          schemaName: "",
          keyName: "",
        });
      }
    } catch (error) {
      this.setState({ createError: error.toString(), isCreateError: true });
    }
  };

  onSchemaNameChange = (e) => {
    this.setState({ schemaName: e.target.value });
  };

  onKeyNameChange = (e) => {
    this.setState({ keyName: e.target.value });
  };

  render() {
    const { classes, selectedParameterSet, parameterSet } = this.props;
    const {
      theme,
      language,
      generatedSchema,
      jsonValues,
      createError,
      isCreateError,
      schemaName,
      keyName,
    } = this.state;
    const valueEditor = JSON.stringify(jsonValues ? jsonValues : {});
    const schemaEditor = JSON.stringify(
      generatedSchema ? generatedSchema.schema_json : {}
    );
    return (
      <div className={classes.root}>
        {isCreateError && (
          <Grid item xs={12} style={{ paddingBottom: "10px" }}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {createError}
            </Alert>
          </Grid>
        )}
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <SelectContainer
              type={"Engine parameter set"}
              title={"Engine parameter set"}
              style={{ paddingBottom: "15px" }}
              value={selectedParameterSet || ""}
              optionLabel={"name"}
              valueLabel={"id"}
              options={parameterSet}
              handleChange={(e) => this.handleParameterSetChange(e)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Key Name"
              type="text"
              fullWidth
              onChange={this.onKeyNameChange}
              value={keyName || ""}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Schema Name"
              type="text"
              fullWidth
              onChange={this.onSchemaNameChange}
              value={schemaName || ""}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={5} style={{ border: "1px solid #000000" }}>
            <Editor
              height="60vh"
              theme={theme}
              language={language}
              editorDidMount={this.handleValueEditorDidMount.bind(this)}
              options={options}
              value={valueEditor}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="default"
              style={{
                marginTop: "50%",
                marginLeft: "15%",
              }}
              startIcon={<NavigateNextIcon />}
              onClick={this.convertValuesToJson}
            >
              convert
            </Button>
          </Grid>
          <Grid item xs={5} style={{ border: "1px solid #000000" }}>
            <Editor
              height="60vh"
              language={language}
              editorDidMount={this.handleSchemaEditorDidMount.bind(this)}
              options={options}
              value={schemaEditor}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "end" }}>
          <Button
            variant="contained"
            style={{
              background: "#47434f",
              height: "32px",
              color: "#ffffff",
              marginTop: "15px",
            }}
            type="button"
            onClick={this.createSchemaEntities}
            disabled={
              schemaName.length > 0 && keyName.length > 0 ? false : true
            }
          >
            Submit
          </Button>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.parameterView;
};
const mapDispatchToProps = (dispatch) => ({
  getParameterSet: () => dispatch(GetParameterSet()),
  reset: () => dispatch(ReSetParameterView()),
  setSelectedParameterSet: (paramSetId) =>
    dispatch(SetParameterSet(paramSetId)),
});
export default withStyles(useStyles)(
  connect(mapStateToProps, mapDispatchToProps)(CreateSchema)
);

const options = {
  acceptSuggestionOnCommitCharacter: true,
  acceptSuggestionOnEnter: "on",
  accessibilitySupport: "auto",
  autoIndent: true,
  automaticLayout: true,
  codeLens: false,
  colorDecorators: true,
  contextmenu: true,
  cursorBlinking: "blink",
  cursorSmoothCaretAnimation: false,
  cursorStyle: "line",
  disableLayerHinting: false,
  disableMonospaceOptimizations: false,
  dragAndDrop: false,
  fixedOverflowWidgets: false,
  folding: true,
  foldingStrategy: "auto",
  fontLigatures: false,
  formatOnPaste: true,
  formatOnType: true,
  hideCursorInOverviewRuler: false,
  highlightActiveIndentGuide: false,
  links: true,
  mouseWheelZoom: false,
  multiCursorMergeOverlapping: false,
  multiCursorModifier: "alt",
  overviewRulerBorder: false,
  overviewRulerLanes: 2,
  quickSuggestions: true,
  quickSuggestionsDelay: 100,
  readOnly: false,
  renderControlCharacters: false,
  renderFinalNewline: true,
  renderIndentGuides: true,
  renderLineHighlight: "all",
  renderWhitespace: "none",
  revealHorizontalRightPadding: 30,
  roundedSelection: true,
  rulers: [],
  scrollBeyondLastColumn: 5,
  scrollBeyondLastLine: true,
  selectOnLineNumbers: true,
  selectionClipboard: true,
  selectionHighlight: true,
  showFoldingControls: "mouseover",
  smoothScrolling: true,
  suggestOnTriggerCharacters: true,
  wordBasedSuggestions: true,
  wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
  wordWrap: "off",
  wordWrapBreakAfterCharacters: "\t})]?|&,;",
  wordWrapBreakBeforeCharacters: "{([+",
  wordWrapBreakObtrusiveCharacters: ".",
  wordWrapColumn: 80,
  wordWrapMinified: false,
  wrappingIndent: "none",
};
