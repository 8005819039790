import React from "react";
import { Button, Avatar } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import ExitIcon from "@material-ui/icons/ExitToApp";
import MoreIcon from "@material-ui/icons/MoreVert";
import { withAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cookies from "../utils/cookies";
import draftStore from "../utils/draftStore";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },

  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  paperStyle: {
    backgroundColor: "#ffffff",
    boxShadow: "none",
    minHeight: "calc(100vh - 58px)",
  },
  navButton: {
    color: "#fff",
    cursor: "pointer",
    border: "1px #312e3a",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#47434f",
    },
    "& > *": {
      padding: "10px 25px",
    },
    borderRadius: "0px",
  },
  runTypeButton: {
    color: "#000",
    backgroundColor: "#fff",
    top: "10px",
    borderRadius: 20,
  },
  sidedrawer: {
    width: "35vw",
  },
  barColorPrimary: {
    backgroundColor: "#000000",
  },
  colorPrimary: {
    backgroundColor: "#d1d1d1",
  },
  navButtonSelected: {
    color: "#fff",
    cursor: "pointer",
    border: "1px #312E3A",
    textAlign: "center",
    backgroundColor: "#231f29",
    "& > *": {
      padding: "10px 25px",
    },
    "&:hover": {
      backgroundColor: "#47434f",
    },
    borderRadius: "0px",
  },
  mobileMenu: {
    backgroundColor: "#332d3c",
  },
  mobileMenuItem: {
    backgroundColor: "#332d3c",
    "&:hover": {
      backgroundColor: "#47434f",
    },
  },
  navBar: {
    backgroundColor: "#332d3c",
    minHeight: "56px",
    height: "56px",
    paddingRight: "45px",
  },
  appLogo: { height: "25px", paddingLeft: "30px" },
});

class DefaultDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      token: null,
      mobileMoreAnchorEl: null,
      fileErrorLogs: [],
      reportRuns: [],
      showSidebar: null,
      notificationsAnchorEl: null,
      pathname:
        window.location.pathname === "/"
          ? "/reports"
          : window.location.pathname,
    };
    this.logoutAuth = this.logoutAuth.bind(this);
  }

  componentWillMount() {
    this.unlisten = this.props.children.props.history.listen((location) => {
      this.setState({ pathname: location.pathname });
      if (!location.pathname.includes("login"))
        localStorage.setItem("path", location.pathname);
    });
  }

  componentDidMount() {
    const { token } = this.state;
    !token && this.getToken(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { token } = this.state;
    !token && this.getToken(nextProps);
  }

  async getToken(props) {
    const {
      history,
      auth0: { isAuthenticated, isLoading, getAccessTokenSilently },
    } = props;
    if (isLoading) return;
    if (!isAuthenticated) history.push(`/sign-in`);
    const token = await getAccessTokenSilently();
    cookies.set("token", token);
    this.setState({ token });
  }

  logoutAuth() {
    const {
      auth0: { logout },
    } = this.props;
    logout({ returnTo: window.location.origin });
    draftStore.remove("forecasting-run-filter");
    draftStore.remove("engine-run-filter");
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleNotificationsClick = (event) => {
    if (this.state.fileErrorLogs.length > 0) {
      this.setState({ notificationsAnchorEl: event.currentTarget });
    }
  };

  handleNotificationsClose = () => {
    this.setState({ notificationsAnchorEl: null });
  };

  renderMobileMenu(mobileMoreAnchorEl, isMobileMenuOpen, classes) {
    return (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
        className={classes.root}
        PaperProps={{
          style: {
            backgroundColor: "#312e3a",
          },
        }}
      >
        <MenuItem
          onClick={this.handleMobileMenuClose}
          className={classes.mobileMenuItem}
        >
          <Button
            className={classes.navButton}
            component={Link}
            to="/parameterView"
          >
            Parametere View
          </Button>
        </MenuItem>
        <MenuItem
          onClick={this.handleProfileMenuOpen}
          className={classes.mobileMenuItem}
        >
          <Button className={classes.navButton} component={Link} to="/schema">
            Schema View
          </Button>
        </MenuItem>
        <MenuItem
          onClick={this.handleProfileMenuOpen}
          className={classes.mobileMenuItem}
        >
          <Button className={classes.navButton} component={Link} to="/settings">
            Settings
          </Button>
        </MenuItem>
      </Menu>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      mobileMoreAnchorEl,
      fileErrorLogs,
      notificationsAnchorEl,
      pathname,
      token,
    } = this.state;
    const { handleNotificationsClose } = this;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    return (
      <div className={classes.root}>
        <AppBar position="relative" color="default">
          <Toolbar className={classes.navBar}>
            <div>
              <Link to="/">
                <img
                  src={"/assets/images/ODLogo_White.svg"}
                  alt="Optimal Dynamics logo"
                  className={classes.appLogo}
                />
              </Link>
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Tooltip title="Parameter View">
                <Button
                  className={
                    pathname.includes("/parameterView") &&
                    pathname.indexOf("/parameterView") === 0
                      ? classes.navButtonSelected
                      : classes.navButton
                  }
                  component={Link}
                  to="/parameterView"
                >
                  Parameter View
                </Button>
              </Tooltip>

              <Tooltip title="Schema">
                <Button
                  className={
                    pathname.includes("/schema") &&
                    pathname.indexOf("/schema") === 0
                      ? classes.navButtonSelected
                      : classes.navButton
                  }
                  component={Link}
                  to="/schema"
                >
                  Schema
                </Button>
              </Tooltip>

              <Tooltip title="Extra Files">
                <Button
                  className={
                    pathname.includes("/ExtraFiles") &&
                    pathname.indexOf("/ExtraFiles") === 0
                      ? classes.navButtonSelected
                      : classes.navButton
                  }
                  component={Link}
                  to="/ExtraFiles"
                >
                  Extra Files
                </Button>
              </Tooltip>

              <Tooltip title="SMART-TL Engine Runs">
                <Button
                  className={
                    pathname.includes("/engine-runs") &&
                    pathname.indexOf("/engine-runs") === 0
                      ? classes.navButtonSelected
                      : classes.navButton
                  }
                  component={Link}
                  to="/engine-runs"
                >
                  SMART-TL Engine Runs
                </Button>
              </Tooltip>

              <Tooltip title="Forecasting Runs">
                <Button
                  className={
                    pathname.includes("/forecasting-runs") &&
                    pathname.indexOf("/forecasting-runs") === 0
                      ? classes.navButtonSelected
                      : classes.navButton
                  }
                  component={Link}
                  to="/forecasting-runs"
                >
                  Forecasting Runs
                </Button>
              </Tooltip>

              <Tooltip title="Mapping Runs">
                <Button
                  className={
                    pathname.includes("/im-runs") &&
                    pathname.indexOf("/im-runs") === 0
                      ? classes.navButtonSelected
                      : classes.navButton
                  }
                  component={Link}
                  to="/im-runs"
                >
                  Mapping Runs
                </Button>
              </Tooltip>

              <Tooltip title="KPI Runs">
                <Button
                  className={
                    pathname.includes("/kpi-runs") &&
                    pathname.indexOf("/kpi-runs") === 0
                      ? classes.navButtonSelected
                      : classes.navButton
                  }
                  component={Link}
                  to="/kpi-runs"
                >
                  KPI Runs
                </Button>
              </Tooltip>

              <Tooltip title="Historical Analytics">
                <Button
                  className={
                    pathname.includes("/historical-analysis") &&
                    pathname.indexOf("/historical-analysis") === 0
                      ? classes.navButtonSelected
                      : classes.navButton
                  }
                  component={Link}
                  to="/historical-analysis"
                >
                  Historical Analytics
                </Button>
              </Tooltip>

              <Tooltip title="Settings">
                <Button
                  className={
                    pathname.includes("/settings") &&
                    pathname.indexOf("/settings") === 0
                      ? classes.navButtonSelected
                      : classes.navButton
                  }
                  component={Link}
                  to="/settings"
                >
                  Settings
                </Button>
              </Tooltip>

              <Tooltip title="Logout">
                <Button
                  component={Link}
                  to={"#"}
                  onClick={this.logoutAuth}
                  className={`logout-btn`}
                >
                  <Avatar style={{ backgroundColor: "#231f29" }}>
                    <ExitIcon />
                  </Avatar>
                </Button>
              </Tooltip>
            </div>

            <div className={classes.sectionMobile}>
              <Button
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                className={classes.navButton}
              >
                <MoreIcon />
              </Button>
            </div>
          </Toolbar>
          <Popover
            style={{ backgroundColor: "#312e3a" }}
            id="popper-notification"
            open={Boolean(notificationsAnchorEl)}
            anchorEl={notificationsAnchorEl}
            onClose={handleNotificationsClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuList>
              {fileErrorLogs.length > 0 && (
                <MenuItem component={Link} to={"/files/errors/"}>
                  {fileErrorLogs.length} File Errors
                </MenuItem>
              )}
            </MenuList>
          </Popover>
        </AppBar>
        {this.renderMobileMenu(mobileMoreAnchorEl, isMobileMenuOpen, classes)}
        <Paper square={true} className={classes.paperStyle}>
          {token && this.props.children}
        </Paper>
      </div>
    );
  }
}

DefaultDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  withAuth0(DefaultDashboard)
);
