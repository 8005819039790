import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import axios from "../../utils/axios_instance";
import EditIcon from "@material-ui/icons/Edit";
import EditOrgSettings from "./editOrgaSettings";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationDialog from "./confirmationDialog";
import { useSelector } from "react-redux";

const columns = [
  { id: "application", label: "Application", minWidth: 120, align: "left" },
  { id: "organization", label: "Organization", minWidth: 120, align: "left" },
  {
    id: "application_setting_name",
    label: "Name",
    minWidth: 120,
    align: "left",
  },
  {
    id: "application_value",
    label: "Application Value",
    minWidth: 120,
    align: "left",
  },
  {
    id: "created_at",
    label: "Created At",
    minWidth: 120,
    align: "left",
  },
  {
    id: "updated_at",
    label: "Updated At",
    minWidth: 120,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 120,
    align: "left",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: 0,
    margin: 0,
  },
  container: {
    maxHeight: "calc(100vh - 305px)",
  },
  tableHead: {
    fontWeight: 700,
    fontSize: "14px",
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [isEditRow, setEditRow] = useState(false);
  const [editedItem, setEditItem] = useState(null);
  const [showConfirmationDialog, setshowConfirmationDialog] = useState(false);
  const [settingsOptions, setSettingsOptions] = useState([]);
  const organizationFilter = useSelector((state) => state.parameterView.organizationFilter)

  useEffect(() => {
    getAppSetting();
    getSettingName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (organizationFilter) {
      const filteredRows = rows.filter(row => row.organization.subdomain === organizationFilter);
      setFilteredRows(filteredRows)
    } else {
      setFilteredRows(rows)
    }
  }, [organizationFilter, rows])

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAppSetting = async () => {
    const response = await axios.get("/application-setting/all/?limit=1000");
    if (response && response.status === 200) {
      setRows(response.data.results);
      setFilteredRows(response.data.results);
    } else {
      setRows([]);
    }
  };

  const editRow = (data) => {
    setEditItem(data);
    setEditRow(true);
  };

  const deleteRow = async () => {
    try {
      const response = await axios.delete(
        `/application-setting/operational/${editedItem.id}/`
      );
      if (response) {
        getAppSetting();
        setshowConfirmationDialog(false);
        setEditItem(null);
        setEditRow(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTableCell = (row, column) => {
    if (
      column.id !== "action" &&
      column.id !== "application" &&
      column.id !== "organization"
    ) {
      const value = row[column.id];
      return (
        <TableCell
          key={column.id}
          align={column.align}
          style={{ padding: "8px" }}
        >
          {typeof value === "boolean" ? (value ? "on" : "off") : value}
        </TableCell>
      );
    } else if (column.id === "organization") {
      return (
        <TableCell
          key={column.id}
          align={column.align}
          style={{ padding: "8px" }}
        >
          {row.organization.subdomain}
        </TableCell>
      );
    } else if (column.id === "application") {
      return (
        <TableCell
          key={column.id}
          align={column.align}
          style={{ padding: "8px" }}
        >
          {row.application_setting.application}
        </TableCell>
      );
    } else {
      return (
        <TableCell
          key={column.id}
          align={column.align}
          style={{ padding: "8px" }}
        >
          <EditIcon
            style={{ color: "#47434f", cursor: "pointer", marginRight: "15px" }}
            onClick={() => editRow(row)}
          />

          <DeleteIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => onConfirmDelete(row)}
          />
        </TableCell>
      );
    }
  };

  const onConfirmDelete = (row) => {
    setshowConfirmationDialog(true);
    setEditItem(row);
  };

  const getSettingName = async () => {
    if (settingsOptions.length > 0) return;
    const response = await axios.get("/application-setting/");
    if (response && response.status === 200) {
      let settingsOption = [];
      response.data.results.forEach((element) => {
        settingsOption.push({
          id: element.name,
          name: element.name,
          application: element.application,
        });
      });
      setSettingsOptions(settingsOption);
    }
  };

  return (
    <>
      {isEditRow && (
        <EditOrgSettings
          settings={editedItem}
          settingsOptions={settingsOptions}
          onSaveSuccess={() => {
            setEditRow(false);
            getAppSetting();
          }}
        />
      )}
      {!isEditRow && (
        <EditOrgSettings
          settingsOptions={settingsOptions}
          onSaveSuccess={() => getAppSetting()}
        />
      )}
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#ffffff" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, padding: "8px" }}
                    className={classes.tableHead}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => getTableCell(row, column))}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {showConfirmationDialog && (
        <ConfirmationDialog
          showDialog={showConfirmationDialog}
          msg={`Please confirm the deletion of ${editedItem.application_setting_name}`}
          title="Delete  Settings?"
          handleAgree={() => deleteRow()}
          handleClose={() => setshowConfirmationDialog(false)}
        />
      )}
    </>
  );
}
