/* eslint-disable react/jsx-no-target-blank */
import { Avatar, Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import axios from '../../utils/axios_instance';
import { makeStyles } from '@material-ui/core/styles';
import Editor from '@monaco-editor/react';
import { KeyboardArrowLeft } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '20px',
  },
  btnStyle: {
    marginTop: theme.spacing(2),
    backgroundColor: '#312e3a',
    color: '#fff',
    justifyContent: 'inherit',
    '&:hover': {
      backgroundColor: '#47434f',
    },
    '& .Mui-disabled': {
      backgroundColor: '#808080',
      color: '#B3B3B3'
    }
  },
  capitalize: {
    textTransform: 'capitalize',
  },

  paperStyle: {
    paddingTop: '10%',
    textAlign: 'center',
  },
  header: {
    fontWeight: '600',
  },
  smallMapIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: '#332d3c',
  },
  actionLink: {
    fontSize: '1.6rem',
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      color: 'blue',
      textDecoration: 'underline',
    },
  },
}));

const EngineRun = (props) => {
  const [engineRunDetail, setEngineRunDetail] = useState(null);
  const [fullPayload, setFullPayload] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  let editorRef = useRef();
  let editorFullpayLoadRef = useRef();
  let editorChildRunsRef = useRef();
  let editorReceivedPayloadRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    getEngineRunDetail();
    return () => {
      //cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleValueEditorDidMount(_, editorReference) {
    editorRef.current = editorReference;
    setTimeout(() => {
      editorRef.current.getAction('editor.action.formatDocument').run();
    }, 1000);
  }

  function handleFullpayLoadEditorDidMount(_, editorReference) {
    editorFullpayLoadRef.current = editorReference;
    setTimeout(() => {
      editorFullpayLoadRef.current.getAction('editor.action.formatDocument').run();
    }, 1000);
  }

  function handleChildRunsEditorDidMount(_, editorReference) {
    editorChildRunsRef.current = editorReference;
    setTimeout(() => {
      editorChildRunsRef.current.getAction('editor.action.formatDocument').run();
    }, 1000);
  }

  function handleReceivedPayloadEditorDidMount(_, editorReference) {
    editorReceivedPayloadRef.current = editorReference;
    setTimeout(() => {
      editorReceivedPayloadRef.current.getAction('editor.action.formatDocument').run();
    }, 1000);
  }

  const getEngineRunDetail = async () => {
    try {
      const response = await axios.get(
        `/parameters/engine-runs-api/${props.match.params.id}/`
      );
      if (response && response.status === 200) {
        setEngineRunDetail(response.data);
        
        var fullData = {...response.data};
        delete fullData['received_payload'];
        setFullPayload(JSON.stringify(fullData));
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const getRunStatus = (payLoad) => {
    return JSON.stringify(payLoad.status_payload);
  };

  const getReceivedPayload = (payLoad) => {
    if (payLoad == null){
      return ""
    }

    return JSON.stringify(payLoad.received_payload);
  };

  const successfulChildRuns = (childRuns) => {
    return childRuns.reduce((count, obj) => (obj.is_successful === true ? count + 1 : count), 0);
  };

  const onBack = () => {
    props.history.goBack();
  };

  const handleReRun = (runId) => {
    try {
      axios
        .get(
          `parameters/engine-runs-api/run-engine/${engineRunDetail.organization.subdomain}/${runId}/`
        )
        .then((res) => {
          if (res && Object.keys(res.data).includes('error')) {
            setError(res.data.error);
          } else {
            setSuccessMessage(res.data.message);
          }
        })
        .catch((err) => {
          setError(err);
        });
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.header}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container item xs={11}>
          {error && (
            <Grid item xs={12} style={{ color: 'red', paddingBottom: '10px' }}>
              {`Error : ${error}`}
            </Grid>
          )}
          {successMessage && (
            <Grid item xs={12} style={{ color: 'green', paddingBottom: '10px' }}>
              {successMessage}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            onClick={() => onBack()}
            style={{
              display: 'flex',
              cursor: 'pointer',
              paddingBottom: '20px',
            }}
          >
            <Grid>
              <Avatar className={classes.smallMapIcon}>
                <KeyboardArrowLeft style={{ color: 'white' }} />
              </Avatar>
            </Grid>
            <Grid
              style={{
                paddingTop: '5px',
                paddingLeft: '5px',
              }}
            >
              Back to SMART-TL Engine Runs
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ display: 'flex', paddingBottom: '10px' }}>
            <Grid item xs={3}>
              <Typography
                variant="h4"
                style={{ fontSize: '1.6rem', paddingBottom: '10px' }}
              >
                Engine Run
              </Typography>
            </Grid>
            <Grid item xs={4} className={classes.header}>
              {`Run Action : ${
                engineRunDetail ? engineRunDetail.run_action : ''
              }`}
            </Grid>
            <Grid item xs={5} className={classes.header} data-testid='smart-tl-creation-date'>
              {`Creation  Date : ${
                engineRunDetail ? engineRunDetail.created_at : ''
              }`}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', paddingBottom: '15px' }}>
            <Grid item xs={3} data-testid='smart-tl-run-id'>
              {`Run ID : ${
                engineRunDetail && engineRunDetail.received_payload != null
                  ? engineRunDetail.received_payload.runs[0]["run_id"]
                  : ''
              }`}
            </Grid>
            <Grid item xs={3} data-testid='smart-tl-run-id'>
              {`Task ID : ${
                engineRunDetail && engineRunDetail.received_payload != null
                  ? engineRunDetail.received_payload.runs[0]["task_id"]
                  : ''
              }`}
            </Grid>
            <Grid item xs={4} className={classes.header}>
              {`Run Type : ${
                engineRunDetail && engineRunDetail.received_payload != null
                  ? engineRunDetail.received_payload.runs[0]["type"]
                  : ''
              }`}
            </Grid>
            <Grid item xs={5} className={classes.header}>
              {`Status : ${
                  engineRunDetail
                    ? engineRunDetail.status
                    : ''
                }`}
            </Grid>
          </Grid>
          {engineRunDetail && engineRunDetail.status == 'completed' && engineRunDetail.payload != null && engineRunDetail.payload.length > 0 && engineRunDetail.payload.map((payLoad, index) => (
              <Grid container item xs={12} key={`${payLoad.run_id}-${index}`}>
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', paddingBottom: '10px' }}
                >
                  <Grid item xs={3} style={{ position: 'relative' }}>
                    {(engineRunDetail?.payload[0]?.type === 'OPERATIONAL_RUN' ||
                      engineRunDetail?.payload[0]?.type ===
                        'LOAD_ACCEPTANCE_RUN') && (
                      <Button
                        style={{
                          position: 'absolute',
                          top: '-22px',
                        }}
                        variant="contained"
                        color="default"
                        className="primaryBtnSmll"
                        disabled={!!successMessage}
                        onClick={() =>
                          handleReRun(engineRunDetail.payload[0].run_id)
                        }
                      >{`initiate re-run of ${
                        engineRunDetail && engineRunDetail.payload.length > 0
                          ? engineRunDetail.payload[0].run_id
                          : ''
                      }`}</Button>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <a
                      target="_blank"
                      className={classes.actionLink}
                      href={`https://s3.console.aws.amazon.com/s3/object/${payLoad.s3_bucket_name}?region=us-east-2&prefix=${payLoad.s3_input_file_path}`}
                    >
                      <Typography variant="h4" className={classes.actionLink}>
                        Input Folder
                      </Typography>
                    </a>
                  </Grid>
                  <Grid item xs={3}>
                    <a
                      className={classes.actionLink}
                      target="_blank"
                      href={`https://s3.console.aws.amazon.com/s3/object/${payLoad.s3_bucket_name}?region=us-east-2&prefix=${payLoad.s3_output_file_path}`}
                    >
                      <Typography variant="h4" className={classes.actionLink}>
                        Output Folder
                      </Typography>
                    </a>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    border: '1px solid #000000',
                    marginBottom: '30px',
                  }}
                >
                  <Grid item xs={12} style={{ paddingBottom: '10px' }}>
                    Run Status :
                  </Grid>
                  <Grid item xs={12}>
                    <Editor
                      height="45vh"
                      theme={'light'}
                      language={'json'}
                      editorDidMount={handleValueEditorDidMount.bind(this)}
                      options={options}
                      value={getRunStatus(payLoad)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          {engineRunDetail && engineRunDetail.status == 'completed' && (
            <>
              <Grid item xs={12} style={{ border: '1px solid #000000', marginBottom: '20px'}}>
                <Grid item xs={12} style={{ paddingBottom: '10px' }}>
                  Full Payload :
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    height="55vh"
                    theme={'light'}
                    language={'json'}
                    editorDidMount={handleFullpayLoadEditorDidMount.bind(this)}
                    options={options}
                    value={fullPayload}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {engineRunDetail && engineRunDetail.status == 'completed' && engineRunDetail.source == 'maestro' && (
            <>
              <div>Successful child runs: {successfulChildRuns(engineRunDetail?.child_runs || [])} / {engineRunDetail?.child_runs?.length}</div>
              <Grid item xs={12} style={{ border: '1px solid #000000', marginBottom: '20px'}}>
                <Grid item xs={12} style={{ paddingBottom: '10px' }}>
                  Individual Smart TL Runs :
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    height="55vh"
                    theme={'light'}
                    language={'json'}
                    editorDidMount={handleChildRunsEditorDidMount.bind(this)}
                    options={options}
                    value={JSON.stringify(engineRunDetail?.child_runs || [])}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} style={{ border: '1px solid #000000' }}>
            <Grid item xs={12} style={{ paddingBottom: '10px' }}>
              Received Payload :
            </Grid>
            <Grid item xs={12}>
              <Editor
                height="55vh"
                theme={'light'}
                language={'json'}
                editorDidMount={handleReceivedPayloadEditorDidMount.bind(this)}
                options={options}
                value={getReceivedPayload(engineRunDetail)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default EngineRun;

const options = {
  acceptSuggestionOnCommitCharacter: true,
  acceptSuggestionOnEnter: 'on',
  accessibilitySupport: 'auto',
  autoIndent: true,
  automaticLayout: true,
  codeLens: false,
  colorDecorators: true,
  contextmenu: true,
  cursorBlinking: 'blink',
  cursorSmoothCaretAnimation: false,
  cursorStyle: 'line',
  disableLayerHinting: false,
  disableMonospaceOptimizations: false,
  dragAndDrop: false,
  fixedOverflowWidgets: false,
  folding: true,
  foldingStrategy: 'auto',
  fontLigatures: false,
  formatOnPaste: true,
  formatOnType: true,
  hideCursorInOverviewRuler: false,
  highlightActiveIndentGuide: false,
  links: true,
  mouseWheelZoom: false,
  multiCursorMergeOverlapping: false,
  multiCursorModifier: 'alt',
  overviewRulerBorder: false,
  overviewRulerLanes: 2,
  quickSuggestions: true,
  quickSuggestionsDelay: 100,
  readOnly: false,
  renderControlCharacters: false,
  renderFinalNewline: true,
  renderIndentGuides: true,
  renderLineHighlight: 'all',
  renderWhitespace: 'none',
  revealHorizontalRightPadding: 30,
  roundedSelection: true,
  rulers: [],
  scrollBeyondLastColumn: 5,
  scrollBeyondLastLine: true,
  selectOnLineNumbers: true,
  selectionClipboard: true,
  selectionHighlight: true,
  showFoldingControls: 'mouseover',
  smoothScrolling: true,
  suggestOnTriggerCharacters: true,
  wordBasedSuggestions: true,
  wordSeparators: '~!@#$%^&*()-=+[{]}|;:\'",.<>/?',
  wordWrap: 'off',
  wordWrapBreakAfterCharacters: '\t})]?|&,;',
  wordWrapBreakBeforeCharacters: '{([+',
  wordWrapBreakObtrusiveCharacters: '.',
  wordWrapColumn: 80,
  wordWrapMinified: false,
  wrappingIndent: 'none',
};
