import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core/";
import { BrokenImage } from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    width: "100%",
  },

  paperStyle: {
    paddingTop: "10%",
    textAlign: "center",
  },
});

class PageNotFound extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar color="default">
          <Toolbar style={{ backgroundColor: "#312e3a" }}>
            <div>
              <img
                src={"/assets/images/logo.svg"}
                alt="Optimal Dynamics logo"
                style={{ height: "25px", paddingLeft: `60px` }}
              />
            </div>
          </Toolbar>
        </AppBar>
        <Typography className={classes.paperStyle}>
          <BrokenImage style={{ fontSize: "70px" }} />
          <Typography style={{ fontSize: "20px" }}>
            This item cannot be found
          </Typography>
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PageNotFound);
