/* eslint-disable react/jsx-no-target-blank */
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "../../utils/axios_instance";
import { SelectContainer } from "../../common/index";
import { DateTimeContainer } from "../../common/DateTimeContainer";
import { Button, Box } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ANALYSIS_TYPES, ANALYSIS_TYPES_ENDPOINT_MAPPING, HISTORICAL_PERFORMANCE, ADOPTION_ADHERENCE } from './constants'
import MetricData from './MetricData';
import CollapsibleEditor from "./CollapsibleEditor";



const useStyles = makeStyles(() => ({
  rootGrid: {
    padding: "20px 68px 0px 68px",
  },
  gridSection: {
    paddingBottom: "10px;",
  },
  loadingModal: {
    marginTop: '15%',
    marginLeft: '5%',
    padding: "32px !important;",
    display: "flex;",
    alignItems: "center;",
    justifyContent: "space-between;",
    height: "48px;",
    fontSize: "18px;"
  },
  error: {
    whiteSpace: "initial;",
    marginTop: '15%;',
    marginLeft: '5%',
    width: "600px;"
  },
  analysisResults: {
    maxHeight: "400px",
    overflowY: "scroll"
  },
  analysisResultsOuterBox: {
    width: "400px"
  },
  metricResults: {
    display: "flex"
  },
  report: {
    marginLeft: "25%",
    paddingTop: "20px"
  }
}));

const HistoricalAnalysisView = () => {
  const [myRef, setRef] = useState(React.createRef())
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [reportLink, setReportLink] = useState(null)
  const [actionInProgress, setActionInProgress] = useState(false);
  const [analysisType, setAnalysisType] = useState(HISTORICAL_PERFORMANCE);
  const [error, setError] = useState(null)
  const classes = useStyles()


  useEffect(() => {
    getOrganizations()
  }, []);

  const handleEditorDidMount = (_, editorReference) => {
    myRef.current = editorReference;
    setRef(myRef)
    setTimeout(() => {
      myRef.current.getAction("editor.action.formatDocument").run();
    }, 200);
  }

  const performHistoricalAnalysis = async () => {
    const payload = buildPayload()
    analysisInProgress()
    const response = await axios.post(
      `/${ANALYSIS_TYPES_ENDPOINT_MAPPING[analysisType]}/analysis/?org=${selectedOrganization}`,
      payload
    ).catch((e) => {
      setActionInProgress(false)
      if (e?.response?.data?.error) {
        setError(e?.response?.data["message"])
      } else {
        setError(e?.message)
      }
    });
    if (response) {
      setActionInProgress(false)
      if (response.status === 201) {
        setError(null)
        switch (analysisType) {
          case HISTORICAL_PERFORMANCE:
            setAnalysisResults(response.data.historical_kpis_results)
            setReportLink(response.data.historical_kpis_report_link)
            break;
          case ADOPTION_ADHERENCE:
            setAnalysisResults(response.data)
            setReportLink(response.data.historical_adoption_kpis_report_path)
            break;
        }
      }
    }
  };

  const buildPayload = () => {
    const basePayload = {
      'start_date': startDate,
      'end_date': endDate,
    }
    switch (analysisType) {
      case HISTORICAL_PERFORMANCE:
        return {
          ...basePayload,
          loads_filter_configuration: JSON.parse(myRef.current.getValue()),
        }
      case ADOPTION_ADHERENCE:
        return basePayload
      default:
        return basePayload
    }
  }

  const analysisInProgress = () => {
    setActionInProgress(true)
    setAnalysisResults(null)
    setReportLink(null)
    setError(null)
  }

  const resetState = () => {
    setAnalysisResults(null)
    setReportLink(null)
    setError(null)
  }


  const getOrganizations = async () => {
    const res = await axios.get(
      "/parameters/parameter-values/editor/get-organizations/"
    );
    setOrganizations(res.data.organizations)
  };


  return (
    <div className={classes.rootGrid}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Grid item xs={12} className={classes.gridSection}>
            <SelectContainer
              type={"Organization"}
              title={"Organization"}
              style={{ paddingBottom: "15px" }}
              value={selectedOrganization || ""}
              optionLabel={"name"}
              valueLabel={"subdomain"}
              options={organizations}
              handleChange={(e) => {
                setSelectedOrganization(e.target.value)
                resetState()
              }}
            />
          </Grid>
          {selectedOrganization && (
            <>
              <Grid item xs={6}>
                <DateTimeContainer
                  type={"StartDate"}
                  title={"Start Date"}
                  classes={classes}
                  disableUnderline={true}
                  handleChange={setStartDate}
                  value={startDate}
                  style={{ paddingBottom: "10px" }}
                  onlyDatePicker={true}
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimeContainer
                  type={"End Date"}
                  title={"End Date"}
                  classes={classes}
                  disableUnderline={true}
                  handleChange={setEndDate}
                  value={endDate}
                  style={{ paddingBottom: "10px" }}
                  onlyDatePicker={true}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectContainer
                  type={"Action"}
                  title={"Action"}
                  style={{ paddingBottom: "15px" }}
                  value={analysisType}
                  optionLabel={"name"}
                  valueLabel={"name"}
                  options={ANALYSIS_TYPES}
                  handleChange={(e) => {
                    setAnalysisType(e.target.value)
                    setAnalysisResults(null)
                    setReportLink(null)
                  }}
                />
              </Grid>
              <Button
                variant="contained"
                style={{
                  background: "#47434f",
                  height: "32px",
                  color: "#ffffff",
                  marginTop: "15px",
                }}
                type="button"
                disabled={actionInProgress}
                onClick={performHistoricalAnalysis}
              >
                Submit
              </Button>
              {analysisType === HISTORICAL_PERFORMANCE && (
                <Grid item xs={12}>
                  <CollapsibleEditor options={options} editorDidMount={handleEditorDidMount.bind(this)} classes={classes}/>
                </Grid>
              )}
            </>
          )}
        </Grid>
        {actionInProgress && (
          <Box className={classes.loadingModal}>
            {<span>Please wait, this may take a bit of time to process...</span>}
            <CircularProgress
              style={{ height: '24px', width: '24px', color: '#34CB81' }}
              thickness={5}
            />
          </Box>
        )}
        <div>
          {analysisResults && analysisType == HISTORICAL_PERFORMANCE && !error && (
            <div className={classes.metricResults}>
              <div className={classes.analysisResultsOuterBox}>
                <div className={classes.report}><b>Historical Performance</b></div>
                <div className={classes.analysisResults}>
                  <MetricData data={analysisResults} />
                </div>
              </div>
            </div>
          )
          }
          {analysisResults && analysisType == ADOPTION_ADHERENCE && !error && (
            <div className={classes.metricResults}>
              <div className={classes.analysisResultsOuterBox}>
                <div><b>Dispatching - Adoption</b></div>
                <div className={classes.analysisResults}>
                  <MetricData data={analysisResults?.metrics?.dispatching} />
                </div>
              </div>
              <div className={classes.analysisResultsOuterBox}>
                <div><b>Load Acceptance - Adoption</b></div>
                <div className={classes.analysisResults}>
                  <MetricData data={analysisResults?.metrics?.load_acceptance} />
                </div>
              </div>
            </div>
          )}
          {reportLink && (
            <div className={classes.report}>To view your reports' files, click <a style={{ textDecoration: 'underline' }} href={reportLink}>here</a></div>
          )
          }
        </div>
        {error && (
          <div className={classes.error}><b>Could not perform {analysisType} analysis.</b> Error: {error}</div>
        )}
      </Grid>
    </div>
  )
};

export default withStyles(useStyles)(HistoricalAnalysisView);

const options = {
  acceptSuggestionOnCommitCharacter: true,
  acceptSuggestionOnEnter: "on",
  accessibilitySupport: "auto",
  autoIndent: true,
  automaticLayout: true,
  codeLens: false,
  colorDecorators: true,
  contextmenu: true,
  cursorBlinking: "blink",
  cursorSmoothCaretAnimation: false,
  cursorStyle: "line",
  disableLayerHinting: false,
  disableMonospaceOptimizations: false,
  dragAndDrop: false,
  fixedOverflowWidgets: false,
  folding: true,
  foldingStrategy: "auto",
  fontLigatures: false,
  formatOnPaste: true,
  formatOnType: true,
  hideCursorInOverviewRuler: false,
  highlightActiveIndentGuide: false,
  links: true,
  mouseWheelZoom: false,
  multiCursorMergeOverlapping: false,
  multiCursorModifier: "alt",
  overviewRulerBorder: false,
  overviewRulerLanes: 2,
  quickSuggestions: true,
  quickSuggestionsDelay: 100,
  readOnly: false,
  renderControlCharacters: false,
  renderFinalNewline: true,
  renderIndentGuides: true,
  renderLineHighlight: "all",
  renderWhitespace: "none",
  revealHorizontalRightPadding: 30,
  roundedSelection: true,
  rulers: [],
  scrollBeyondLastColumn: 5,
  scrollBeyondLastLine: true,
  selectOnLineNumbers: true,
  selectionClipboard: true,
  selectionHighlight: true,
  showFoldingControls: "mouseover",
  smoothScrolling: true,
  suggestOnTriggerCharacters: true,
  wordBasedSuggestions: true,
  wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
  wordWrap: "off",
  wordWrapBreakAfterCharacters: "\t})]?|&,;",
  wordWrapBreakBeforeCharacters: "{([+",
  wordWrapBreakObtrusiveCharacters: ".",
  wordWrapColumn: 80,
  wordWrapMinified: false,
  wrappingIndent: "none",
};