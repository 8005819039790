import React, { useState } from "react";
import clsx from "clsx";
import { Drawer, Grid, Avatar, Typography, Button } from "@material-ui/core/";
import { Close, ArrowForward, Sync } from "@material-ui/icons";
import { FilterIcon } from "../../common/icons";
import SelectContainer from "../../common/SelectContainer";
import {
  SetOrganizationFilter,
} from "../../store/actions/parameterViewActions";
import { useDispatch, useSelector } from "react-redux";

export const FilterSettingsPanel = (props) => {
  const { classes, onClose, showFilter, onSave } = props;
  const state = useSelector((state) => state.parameterView);
  const [selectedOrg, setSelectedOrg] = useState("");
  const dispatch = useDispatch();

  const handleOrganizationFilterChange = (e) => {
    setSelectedOrg(e.target.value)
  }

  const onReset = () => {
    setSelectedOrg('');
  };

  const applyFilter = () => {
    dispatch(SetOrganizationFilter(selectedOrg))
    onSave();
  };

  return (
    <Drawer anchor={"right"} open={showFilter} onClose={(e) => onClose(e)}>
      <div className={classes.sidedrawer}>
        <Grid
          container
          style={{
            height: "56px",
            padding: "5px",
            borderBottom: "1px solid #312e3a",
          }}
          justify="flex-end"
        >
          <Grid item xs={1}>
            <Avatar className={clsx(classes.icon, classes.disableIcon)}>
              <FilterIcon style={{ fontSize: "1.2rem" }} />
            </Avatar>
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              padding: "10px 20px",
              fontWeight: 600,
              marginRight: "15px",
            }}
          >
            Filters
          </Grid>

          <Grid item xs={2}>
            <Avatar
              className={clsx(classes.icon)}
              style={{ marginLeft: "16px" }}
              onClick={(e) => onClose(e)}
            >
              <Close />
            </Avatar>
          </Grid>
        </Grid>
        <div style={{ padding: "15px 40px" }}>
          <Typography
            variant="h4"
            style={{ fontSize: "1.5rem", paddingBottom: "15px" }}
          >
            <div style={{ fontWeight: "600" }}>Settings / </div>
            <div>Filters</div>
          </Typography>
          <div style={{ paddingBottom: "15px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SelectContainer
                  style={{ width: "300px", marginRight: "25px" }}
                  type={"Organization"}
                  title={"Organization"}
                  value={selectedOrg || ""}
                  optionLabel={"name"}
                  valueLabel={"subdomain"}
                  options={state.organization}
                  handleChange={(e) => handleOrganizationFilterChange(e)}
                />
              </Grid>
            </Grid>
          </div>
          <Button
            variant="contained"
            color="default"
            onClick={applyFilter}
            className="primaryBtn"
            endIcon={<ArrowForward />}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={onReset}
            className="secondaryBtn"
            endIcon={<Sync />}
          >
            Reset
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
