import {
  CUSTOMER_FILE_TYPES_SUCCESS,
  CUSTOMER_FILE_TYPES_FAILURE,
} from "../constants/actionTypes";

import API from "../../utils/axios_instance";

//Get customer file mapping
const onCustomerFileTypeSucces = (mappings) => {
  return {
    type: CUSTOMER_FILE_TYPES_SUCCESS,
    mappings,
  };
};
const onCustomerFileTypeFailure = (error) => {
  return {
    type: CUSTOMER_FILE_TYPES_FAILURE,
    error,
  };
};
export const GetCustomerFileType = () => {
  return async (dispatch) => {
    try {
      const url = `/extra-params/extra-input-options/`;
      const response = await API.get(url);
      dispatch(onCustomerFileTypeSucces(response.data));
    } catch (err) {
      if (err && err.response) {
        return dispatch(
          onCustomerFileTypeFailure({
            error: err,
          })
        );
      }
      dispatch(onCustomerFileTypeFailure({ error: err }));
    }
  };
};
