import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import {
  withStyles,
  Typography,
  Accordion,
  AccordionSummary,
  Button,
  Paper,
  Divider,
  Box,
} from "@material-ui/core";
import { SelectContainer } from "../../common/index";
import { connect } from "react-redux";
import {
  GetOrganization,
  GetParameterSetByApplication,
  GetParameterVersion,
  GetParameterSchema,
  GetParameterUISchemaEditor,
  SetOrganization,
  SetParameterSet,
  SetParameterVersion,
  SetParameterReadOnlyStatus,
  ReSetParameterVersion,
  ReSetParameterView,
  SaveParameterSchema,
  CopyParameterVersion,
  ReSetParameterVersiononCopy,
  UpdateInculdedVersion,
  GetApplicationTypes,
  DeleteParameterVersion,
} from "../../store/actions";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ParameterEditor from "./parameterEditor";
import CopyParameterDialog from "./dialogCopyParam";
import {
  GetUserFavourites,
  HideErrorMessage,
  SaveUserFavourites,
  SetFavouritItem,
  SetSelectedApplication,
  ShowSelectedFavourites,
} from "../../store/actions/parameterViewActions";
import Link from "@material-ui/core/Link";
import StarIcon from "@material-ui/icons/Star";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import API from "../../utils/axios_instance";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert, AlertTitle } from "@material-ui/lab";
import DeleteParameterDialog from "./dialogDeleteParameter";
import DeleteParameterOverrideDialog from "./dialogDeleteParameterOverride";
import EnvConfig from "../../config/config";
const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: "20px 68px 0px 68px",
  },
  rootAccordian: {
    width: "100%",
    paddingBottom: "15px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridSection: {
    paddingBottom: "10px;",
  },
  gridSectionText: {
    wordBreak: "break-word;",
  },
  btnParamAction: {
    "& > *": {
      margin: theme.spacing(1),
    },
    textAlign: "end",
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    width: 310,
    textAlign: "left",
    padding: 5,
    margin: 5,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    backgroundColor: "#fff",
    padding: "10px",
    minWidth: "30%",
    minHeight: "30%",
    "&:focus": {
      outline: "none",
    },
  },
  secondary: {
    color: "red",
  },
  rootTab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    backgroundColor: "white",
  },
});

class ParameterView extends Component {
  constructor() {
    super();
    this.state = {
      selectedParameterVersionName: "",
      showCopyParamVersionDialog: false,
      newParamVersionName: "",
      activeTab: 0,
      updateFormData: false,
      latestVersion: null,
      newOrganizationName: null,
      newApplicationType: null,
      userParameterOverrides: [],
      showDeleteParameterDialog: false,
      showDeleteParameterOverrideDialog: false,
      selectedParameterOverride: null,
    };
  }

  async componentDidMount() {
    this.props.reset();
    await this.props.setParmeterReadOnlyStatus(false);
    await this.props.getOrganization();
    await this.props.getApplicationTypes();
  }

  GetParameterSchema = async (parameterVersion) => {
    await this.props.getParmeterSchema(parameterVersion);
  };

  expandAccordian = () => {
    this.setState({ defaultExpanded: true });
  };

  collapseAccordian = () => {
    this.setState({ defaultExpanded: false });
  };

  handleOrganizationChange = (e) => {
    this.props.setSelectedOrganization(e.target.value);
    this.setState({ newOrganizationName: e.target.value });
    this.props.setParmeterReadOnlyStatus(false);
  };

  handleApplicationChange = (e) => {
    this.props.setSelectedApplication(e.target.value);
    this.setState({ newApplicationType: e.target.value });
    this.props.getParameterSet(e.target.value);
    this.props.setParmeterReadOnlyStatus(false);
  };

  handleParameterSetChange = async (e) => {
    this.props.setSelectedParameterSet(e.target.value);
    const organization = this.props.organization.find(
      (o) => o.subdomain === this.props.selectedOrganization
    );
    const parameterSet = this.props.parameterSet.find(
      (p) => p.id === Number(e.target.value)
    );
    await this.props.getParameterVersion({ organization, parameterSet });
    this.props.getUserFavourites(e.target.value);
    this.getActiveVersion(e.target.value).then(() => {
        let activeLatestVersion = this.state.latestVersion
        let activeParameterVersion = activeLatestVersion.parameter_version
        this.props.setSelectedParameterVersion(activeParameterVersion);
        this.GetParameterSchema(activeParameterVersion);
        let activeParameterVersionDetails = this.props.parameterVersion.find(
          (param) => param.id === Number(activeParameterVersion)
        );
        this.setState({ selectedParameterVersionName: activeParameterVersionDetails.name })  
     });
    this.getUserOverrides(this.props.selectedApplication, parameterSet.engine);
  };

  handleParameterVersionChange = (e) => {
    const parameterVersion = this.props.parameterVersion.find(
      (item) => item.id === Number(e.target.value)
    );
    this.props.setSelectedParameterVersion(e.target.value);
    this.GetParameterSchema(e.target.value);
    this.setState({ selectedParameterVersionName: parameterVersion.name });
  };

  onAccordianToggle = (item) => {
    this.getSchemaEditorValue(item);
  };

  getSchemaEditorValue = (item) => {
    let param = {
      schemaId: item.id,
      parameterVersion: this.props.selectedParameterVersion,
      isSchemaUpdate: false,
    };
    this.props.getSchemaEditorValues(param);
  };

  saveParameterSchema = (formData) => {
    if (!this.props.read_only)
      this.props.saveParameterSchema(formData);
  };

  onCopyParameterVersion = () => {
    this.setState({ showCopyParamVersionDialog: true });
  };

  copyParameterVersion = () => {
    let data = {
      original_version_id: this.props.selectedParameterVersion,
      new_version_name: this.state.newParamVersionName,
      org_id: this.state.newOrganizationName,
      parameter_set: this.props.selectedParameterSet,
      application: this.state.newApplicationType,
    };
    if (this.state.newParamVersionName) {
      this.props.copyParameterVersion(data);
    }
  };

  closeCopyParamVersionDialog = () => {
    this.setState({ showCopyParamVersionDialog: false });
  };

  newParamVersionNameChange = (event) => {
    this.setState({
      newParamVersionName: event.target.value,
    });
  };

  newOrganizationNameChange = (event) => {
    this.setState({
      newOrganizationName: event.target.value,
    });
  };

  newApplicationTypeChange = (event) => {
    this.setState({
      newApplicationType: event.target.value,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.isCopySuccess) {
      this.setState({
        selectedParameterVersionName: nextProps.newParameterVersion.name,
        showCopyParamVersionDialog: false,
      });
      this.props.reSetParameterVersiononCopy();
      this.GetParameterSchema(nextProps.selectedParameterVersion);
    }
  };

  onFavouriteClick = (item) => {
    let myFavItem = {
      schema_id: item.schemaId,
      set_id: this.props.selectedParameterSet,
      property_name: item.favItem.label,
      is_active: item.isActive,
    };
    this.props.saveUserFavourits(myFavItem);
    this.props.getUserFavourites(this.props.selectedParameterSet);
  };

  onFavouriteItemClick = async (item) => {
    let param = {
      schemaId: item.parameter_schema,
      parameterVersion: this.props.selectedParameterVersion,
      isSchemaUpdate: false,
    };
    if (this.props.parameterSchema) {
      await this.props.getSchemaEditorValues(param);
      this.props.showSelectedFavourites(item);
    }
  };

  handleTabChange = (_event, newValue) => {
    this.setState({
      activeTab: newValue,
    });
    this.setState({ updateFormData: newValue === 1 ? true : false }, () =>
      this.setState({ updateFormData: false })
    );
  };

  getActiveVersion = async (paramset) => {
    const response = await API.get(
      `/parameters/get-latest-version/${this.props.selectedOrganization}/${this.props.selectedApplication}/${paramset}/`
    );
    if (response && response.status === 200) {
      this.setState({ latestVersion: response.data.latest_version });
    }
  };

  setActive = async () => {
    const paramData = {
      org_id: this.props.selectedOrganization,
      parameter_set: this.props.selectedParameterSet,
      parameter_version: this.props.selectedParameterVersion,
      application: this.props.selectedApplication,
    };
    await API.post("/parameters/set-active-version/", paramData);
    this.getActiveVersion(this.props.selectedParameterSet);
  };

  downloadSchem = async () => {
    const fileName = this.state.selectedParameterVersionName || "parameter_files"
    const response = await API.get(
      `/parameters/get-version-values/${this.props.selectedParameterVersion}/`,
      { responseType: "blob" }
    );
    if (response && response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log(`Successfully got response for version values with URL being ${url}`)
      console.log(`API Response: ${JSON.stringify(response.data)}`)
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName + ".zip");
      console.log(`The link is ${link} with filename ${fileName}`)
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  handleIncludeVersion = (item) => {
    const param = {
      id: item.parameter_values.id,
      include_in_version: !item.parameter_values.include_in_version,
    };
    this.props.updateInculdeVersion(param);
  };

  getUserOverrides = async (appid, setid) => {
    const app = appid ? appid : this.props.selectedApplication;
    let engine = setid;
    const parameterSet = this.props.parameterSet.find(
      (p) => p.id === Number(this.props.selectedParameterSet)
    );
    engine = engine ? engine : parameterSet.engine;
    const url = `/parameters/get-user-overrides/${app}/${engine}/`;
    const response = await API.get(url);
    if (response && response.status === 200) {
      if (response.data.results.length > 0) {
      }
      this.setState({ userParameterOverrides: response.data.results });
    }
  };

  onDeleteParameterVersion = () => {
    this.setState({
      showDeleteParameterDialog: !this.state.showDeleteParameterDialog,
    });
  };

  deleteParameterVersion = () => {
    this.props.deleteParameterVersion(this.props.selectedParameterVersion);
    this.setState({
      showDeleteParameterDialog: false,
    });
  };

  hideParamDeleteError = () => {
    this.props.hideError();
  };

  deleteParmeterOverrides = async () => {
    const url = `/parameters/user-overrides/${this.state.selectedParameterOverride.id}/`;
    const response = await API.delete(url);
    if (response) {
      this.setState({
        showDeleteParameterOverrideDialog: false,
        selectedParameterOverride: null,
      });
      this.getUserOverrides();
    }
  };

  onDeleteParmeterOverrides = (item) => {
    this.setState({
      showDeleteParameterOverrideDialog:
        !this.state.showDeleteParameterOverrideDialog,
      selectedParameterOverride: item,
    });
  };

  render() {
    const {
      classes,
      organization,
      parameterSet,
      parameterVersion,
      parameterSchema,
      read_only,
      selectedOrganization,
      selectedParameterSet,
      selectedParameterVersion,
      userFavourites,
      selectedApplication,
      applicationTypes,
      copyError,
      paramDeleteError,
    } = this.props;

    const {
      selectedParameterVersionName,
      showCopyParamVersionDialog,
      activeTab,
      updateFormData,
      latestVersion,
      newApplicationType,
      newOrganizationName,
      userParameterOverrides,
      showDeleteParameterDialog,
      showDeleteParameterOverrideDialog,
      selectedParameterOverride,
    } = this.state;

    const textClasses = `${classes.gridSection} ${classes.gridSectionText}`
    return (
      <div className={classes.root}>
        {read_only &&
         <Grid item xs={12} style={{ paddingBottom: "10px" }}>
            <Alert severity="error">
              <AlertTitle>This org's parameters are controlled by config store.
                The parameters here are read-only. 
                Please visit Config Store <a href={EnvConfig.getConfig().missionControlBaseUrl + "/config-store/"} target="_blank">here</a> </AlertTitle>
            </Alert>
          </Grid>
          }
        
        {paramDeleteError && (
          <Grid item xs={12} style={{ paddingBottom: "10px" }}>
            <Alert severity="error" onClose={this.hideParamDeleteError}>
              <AlertTitle>Error</AlertTitle>
              Error while deleting {selectedParameterVersionName}
            </Alert>
          </Grid>
        )}
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Grid item xs={12} className={classes.gridSection}>
              <SelectContainer
                type={"Organization"}
                title={"Organization"}
                style={{ paddingBottom: "15px" }}
                value={selectedOrganization || ""}
                optionLabel={"name"}
                valueLabel={"subdomain"}
                options={organization}
                handleChange={(e) => this.handleOrganizationChange(e)}
              />
            </Grid>
            {selectedOrganization && (
              <Grid item xs={12} className={classes.gridSection}>
                <SelectContainer
                  type={"Application Select"}
                  title={"Application"}
                  style={{ paddingBottom: "15px" }}
                  value={selectedApplication || ""}
                  optionLabel={"name"}
                  valueLabel={"id"}
                  options={applicationTypes}
                  handleChange={(e) => this.handleApplicationChange(e)}
                />
              </Grid>
            )}
            {selectedApplication && (
              <Grid item xs={12} className={classes.gridSection}>
                <SelectContainer
                  type={"Engine parameter set"}
                  title={"Engine parameter set"}
                  style={{ paddingBottom: "15px" }}
                  value={selectedParameterSet || ""}
                  optionLabel={"name"}
                  valueLabel={"id"}
                  options={parameterSet}
                  handleChange={(e) => this.handleParameterSetChange(e)}
                />
              </Grid>
            )}
            {["operational", "strategic"].includes(selectedApplication) && selectedParameterSet && (
              <Grid item xs={12} className={textClasses}>
                <div>Name any new parameter set with the following formatting: <b>[YYYYMMDD]_[YourLastName]_cp[idOfTheDuplicatedParams]_[BriefDescriptionOfChanges]</b> and set active if necessary</div>
              </Grid>
            )}
            {selectedApplication && selectedParameterSet && (
              <Grid item xs={12} className={classes.gridSection}>
                <SelectContainer
                  type={"ParameterVersion"}
                  title={"Parameter Version"}
                  style={{ paddingBottom: "15px" }}
                  value={selectedParameterVersion || ""}
                  optionLabel={"name"}
                  valueLabel={"id"}
                  options={parameterVersion}
                  handleChange={(e) => this.handleParameterVersionChange(e)}
                />
              </Grid>
            )}
            {userFavourites && userFavourites.length > 0 && (
              <Grid item xs={12} className={classes.gridSection}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Favourites
                    </Typography>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails>
                    <Grid item xs={12} style={{ display: "row" }}>
                      {userFavourites.map(
                        (item) =>
                          item.is_active &&
                          item.parameter_set === selectedParameterSet && (
                            <Grid style={{ paddingBottom: "3px" }}>
                              <StarIcon
                                style={{
                                  color: "blue",
                                  paddingRight: "30px",
                                  cursor: "pointer",
                                }}
                              ></StarIcon>
                              <Link
                                component="button"
                                variant="body2"
                                onClick={() => {
                                  this.onFavouriteItemClick(item);
                                }}
                              >
                                <Typography style={{ marginTop: "-16px" }}>
                                  {item.property_name}
                                </Typography>
                              </Link>
                            </Grid>
                          )
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
            {userParameterOverrides.length > 0 && (
              <Grid item xs={12} className={classes.gridSection}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Parameter Overrides
                    </Typography>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails>
                    <Grid item xs={12}>
                      {userParameterOverrides.map((item) => (
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", paddingTop: "5px" }}
                        >
                          <Grid item xs={7}>
                            <Typography>{item.property_key}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography style={{ paddingLeft: "3px" }}>
                              {item.user_property_value}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography style={{ paddingLeft: "3px" }}>
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.onDeleteParmeterOverrides(item)
                                }
                              ></DeleteIcon>
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
          </Grid>
          <Grid item xs={8}>
            {parameterSchema.length > 0 && (
              <Paper
                className={classes.paper}
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  color: "black",
                }}
              >
                <Grid item xs={12} style={{ display: "flex" }}>
                  <Grid item xs={4} style={{ textAlign: "start" }}>
                    <Typography>{selectedParameterVersionName}</Typography>
                    <Typography>id: {selectedParameterVersion}</Typography>
                  </Grid>
                  <Grid item xs={8} style={{ textAlign: "end" }}>
                    {!(
                      latestVersion &&
                      latestVersion.parameter_set === selectedParameterSet &&
                      latestVersion.parameter_version ===
                        selectedParameterVersion
                    ) && (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          style={{
                            background: "#47434f",
                            height: "32px",
                            color: "#ffffff",
                            marginRight: "5px",
                          }}
                          type="button"
                          onClick={this.onDeleteParameterVersion}
                          startIcon={<DeleteIcon />}
                          disabled={read_only}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            background: "#47434f",
                            height: "32px",
                            color: "#ffffff",
                            marginRight: "5px",
                          }}
                          type="button"
                          onClick={this.setActive}
                          disabled={read_only}
                        >
                          Set Active
                        </Button>
                      </React.Fragment>
                    )}
                    {latestVersion &&
                      latestVersion.parameter_set === selectedParameterSet &&
                      latestVersion.parameter_version ===
                        selectedParameterVersion && (
                        <Button
                          variant="contained"
                          style={{
                            background: "#47434f",
                            height: "32px",
                            color: "#ffffff",
                            marginRight: "5px",
                          }}
                          type="button"
                          onClick={this.setActive}
                          disabled={true}
                        >
                          Active
                        </Button>
                      )}
                    <Button
                      variant="contained"
                      style={{
                        background: "#47434f",
                        height: "32px",
                        color: "#ffffff",
                        marginRight: "5px",
                      }}
                      type="button"
                      onClick={this.downloadSchem}
                    >
                      <GetAppIcon></GetAppIcon>
                      Download
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        background: "#47434f",
                        height: "32px",
                        color: "#ffffff",
                      }}
                      type="button"
                      onClick={this.onCopyParameterVersion}
                    >
                      Copy
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            )}
            {parameterSchema.map((item) => (
              <div className={classes.rootAccordian}>
                <Accordion
                  onChange={() => this.onAccordianToggle(item)}
                  expanded={item.isExpanded}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                  >
                    {item.name}
               
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails>
                    {item.schemaData && (
                      <div className={classes.rootTab}>
                        <AppBar
                          position="static"
                          style={{
                            height: "48px",
                            background: "rgb(71, 67, 79)",
                          }}
                        >
                          <Tabs
                            value={activeTab}
                            aria-label="simple tabs example"
                            classes={{
                              indicator: classes.indicator,
                            }}
                            onChange={this.handleTabChange}
                          >
                            <Tab label="Form" {...a11yProps(0)} />
                            <Tab label="Json" {...a11yProps(1)} />
                          </Tabs>
                        </AppBar>
                        <TabPanel value={activeTab} index={0}>
                          <ParameterEditor
                            schema={item.schemaData}
                            formData={item.schemaFormData}
                            schemaId={item.id}
                            parameterVersion={selectedParameterVersion}
                            onSave={this.saveParameterSchema}
                            isSaveDisabled={read_only}
                            rowData={item}
                            showForm={true}
                            showSchema={false}
                            showFormSaveBtn={true}
                            favouriteClick={this.onFavouriteClick}
                            userFavourites={userFavourites}
                            uiSchema={item.schemaUIData}
                            selectedOrganization={selectedOrganization}
                            selectedApplication={selectedApplication}
                            getParameterOverride={this.getUserOverrides}
                          ></ParameterEditor>
                        </TabPanel>
                        <TabPanel value={activeTab} index={1}>
                          <ParameterEditor
                            schema={item.schemaData}
                            formData={item.schemaFormData}
                            schemaId={item.id}
                            parameterVersion={selectedParameterVersion}
                            onSave={this.saveParameterSchema}
                            rowData={item}
                            showForm={false}
                            showSchema={true}
                            showFormSaveBtn={false}
                            favouriteClick={this.onFavouriteClick}
                            userFavourites={userFavourites}
                            isReadOnly={true}
                            updateFormData={updateFormData}
                          ></ParameterEditor>
                        </TabPanel>
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </Grid>
        </Grid>
        <CopyParameterDialog
          paramVersionName={selectedParameterVersionName}
          show={showCopyParamVersionDialog}
          handleClose={this.closeCopyParamVersionDialog}
          handleSave={() => this.copyParameterVersion()}
          onParamNameChange={this.newParamVersionNameChange}
          applicationTypes={applicationTypes}
          organization={organization}
          handleOrganizationChange={this.newOrganizationNameChange}
          handleApplicationChange={this.newApplicationTypeChange}
          selectedOrganization={newOrganizationName}
          selectedApplication={newApplicationType}
          errorMsg={copyError}
        ></CopyParameterDialog>
        <DeleteParameterDialog
          showDeleteParameterDialog={showDeleteParameterDialog}
          handleClose={this.onDeleteParameterVersion}
          handleAgree={this.deleteParameterVersion}
          selectedParameterVersionName={selectedParameterVersionName}
        ></DeleteParameterDialog>
        <DeleteParameterOverrideDialog
          showDeleteParameterOverrideDialog={showDeleteParameterOverrideDialog}
          handleClose={this.onDeleteParmeterOverrides}
          handleAgree={this.deleteParmeterOverrides}
          selectedParameterOverrideName={
            selectedParameterOverride
              ? selectedParameterOverride.property_key
              : ""
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.parameterView;
};

const mapDispatchToProps = (dispatch) => ({
  getOrganization: () => dispatch(GetOrganization()),
  getParameterSet: (appName) => dispatch(GetParameterSetByApplication(appName)),
  getParameterVersion: (filter) => dispatch(GetParameterVersion(filter)),
  getParmeterSchema: (paramVersion) =>
    dispatch(GetParameterSchema(paramVersion)),
  setParmeterReadOnlyStatus: (read_only) =>
    dispatch(SetParameterReadOnlyStatus(read_only)),
  getSchemaEditorValues: (param) => dispatch(GetParameterUISchemaEditor(param)),
  setSelectedOrganization: (orgId) => dispatch(SetOrganization(orgId)),
  setSelectedParameterSet: (paramSetId) =>
    dispatch(SetParameterSet(paramSetId)),
  setSelectedParameterVersion: (paramVerId) =>
    dispatch(SetParameterVersion(paramVerId)),
  resetParameterVersion: () => dispatch(ReSetParameterVersion()),
  reset: () => dispatch(ReSetParameterView()),
  saveParameterSchema: (data) => dispatch(SaveParameterSchema(data)),
  copyParameterVersion: (data) => dispatch(CopyParameterVersion(data)),
  reSetParameterVersiononCopy: () => dispatch(ReSetParameterVersiononCopy()),
  saveUserFavourits: (favParam) => dispatch(SaveUserFavourites(favParam)),
  getUserFavourites: (parameterVersion) =>
    dispatch(GetUserFavourites(parameterVersion)),
  setFavouriteItem: (favItem) => dispatch(SetFavouritItem(favItem)),
  showSelectedFavourites: (favItem) =>
    dispatch(ShowSelectedFavourites(favItem)),
  setSelectedApplication: (appid) => dispatch(SetSelectedApplication(appid)),
  updateInculdeVersion: (param) => dispatch(UpdateInculdedVersion(param)),
  getApplicationTypes: () => dispatch(GetApplicationTypes()),
  deleteParameterVersion: (param) => dispatch(DeleteParameterVersion(param)),
  hideError: () => dispatch(HideErrorMessage()),
});

export default withStyles(useStyles)(
  connect(mapStateToProps, mapDispatchToProps)(ParameterView)
);

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
