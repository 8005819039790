import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../utils/axios_instance';
import s3axios from '../../utils/s3_axios_instance';
import {Button, TextField, Grid, MenuItem, Select, InputLabel} from "@material-ui/core/";


toast.configure()

const warnOptions = {
  autoClose: false
}

const FileTypes = {
  DriversFile: 'driver',
  LoadsFile: 'load',
  LocationsFile: 'location',
  UncoveredFile: 'uncovered',
  MovedFile: 'moved',
  MargFile: 'marg',
  OptionalInputFile: '.tar.gz'
}


const styles = (theme) => ({
  root: {
    paddingTop: "20px",
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  icon: {
    backgroundColor: "#f0f0f0",
    color: "#000000",
    fontWeight: "500",
    margin: theme.spacing(1),
    width: "32px",
    cursor: "pointer",
    height: "32px",
    "& > *": {
      fontSize: "1.3rem",
    },
    "&:hover": {
      backgroundColor: "#e8e8e8",
    },
    "&:disabled": {
      backgroundColor: "#b1b3b3",
    },
  },
  smallMapIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: '#332d3c',
  },
});


class UploadRuns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      customer: "",
      inputFields : [{
        runName:"", 
        runIterationNumber: "",
        parameterVersion: "",
        driversFile: "",
        driversFileS3Path : "",
        loadsFile: "",
        loadsFileS3Path: "",
        locationsFile: "",
        locationsFileS3Path: "",
        uncoveredLoadsFile: "",
        uncoveredLoadsFileS3Path: "", 
        movedLoadsAndDriverDecisionsFile:"",
        movedLoadsAndDriverDecisionsFileS3Path:"",
        loadMargValues:"",
        loadMargValuesS3Path: "",
        optionalInputFile:"",
        optionalInputFileS3Path: "",
        readOnly: "true"
      }],
      uploadedFilesCount: 0,
      prunedFilesCount: 0,
      addedCount: 0,
      groupName: ""
    };

    // Three main actions
    this.handleUploadFiles = this.handleUploadFiles.bind(this);
    this.handlePrune = this.handlePrune.bind(this);
    this.handleAddToStrategic = this.handleAddToStrategic.bind(this);

    // Populate customer name field
    this.getCustomers()
  }

  componentDidMount() {

  }
  
  getCustomers = () => {
    let customerList = []
    axios.get(`/parameters/parameter-values/editor/get-organizations`).then((response)=>{
      let data = response.data
      let organizations = data.organizations
      organizations.forEach((org)=>{
        customerList.push(org.subdomain)
      })
      customerList.sort()
      this.setState({customers: customerList})
    })   
  }

  handleGroupNameChange = (event) => {
    this.setState({groupName: (event.target.value)});
  }

  handleCustChange = (event) => {
    this.setState({customer: (event.target.value)});
  }

  handleRunNameChange = (event) => {
    this.setState({runName: (event.target.value)});
  }

  handleRunItemChange = (index, event) => {
      let data = [...this.state.inputFields];
      data[index][event.target.name] = event.target.value;
      this.setState({inputFields: (data) });
  }

  handleRunFileChange = (index, event, substringCheck) => {
      let data = [...this.state.inputFields];
      let fileInfo = {}
      fileInfo['fileName'] = event.target.value
      fileInfo['fileData'] = event.target.files.item(0)
      data[index][event.target.name] = fileInfo;
      this.setState({inputFields: (data) });
      if(!event.target.value.toLowerCase().includes(substringCheck)){
        toast.warn(`You uploaded a file which may not be the correct file type of: ${substringCheck}`, warnOptions)
      }
  }

  handleAddRuns = (event) => {
    event.preventDefault();
    let newRun = {
      runName:"", 
      runIterationNumber: "",
      parameterVersion: "",
      driversFile: "",
      driversFileS3Path:"",
      loadsFile: "",
      loadsFileS3Path: "",
      locationsFile: "",
      locationsFileS3Path:"",
      uncoveredLoadsFile: "",
      uncoveredLoadsFileS3Path: "",
      movedLoadsAndDriverDecisionsFile:"",
      movedLoadsAndDriverDecisionsFileS3Path: "",
      loadMargValues:"",
      loadMargValuesS3Path:"",
      optionalInputFile:"",
      optionalInputFileS3Path: "",
      readOnly: "true"
    }
    let data = [...this.state.inputFields, newRun];
    this.setState({inputFields: (data) });
  }

  handleRemoveRun = (index, event) => {
    event.preventDefault();
    let data = [...this.state.inputFields];
    data.splice(index, 1)
    this.setState({inputFields: (data) });
  }

  handleUploadFiles() {
    if (!this.state.customer) {
      toast.error("You must select a customer before uploading files")
      return
    }
    toast.success("Uploading files...")
    this.setState({uploadedFilesCount:0})
    let formData = [...this.state.inputFields];
    for(var item in formData){
      var runName = formData[item].runName
      var custNme = this.state.customer
      var objectNames = `${custNme}_${runName}_drivers.txt,${custNme}_${runName}_loadMargValues.txt,${custNme}_${runName}_loads.txt,${custNme}_${runName}_locations.txt,${custNme}_${runName}_movedLoadsAndDriverDecisionsFile.txt,${custNme}_${runName}_uncoveredLoadsFile.txt`
      if (formData[item]?.optionalInputFile?.fileName) {
        objectNames += `,${custNme}_${runName}_optionalInputFile.tar.gz`
      }
      this.getPreSignedUrls(objectNames, item, custNme)
    }
  }

  handlePrune(){
    if (!this.state.customer) {
      toast.error("You must select a customer before pruning files")
      return
    }
    toast.info("Pruning data...")
    let formData = [...this.state.inputFields];
    let custNme = this.state.customer
    let prunePayload = []
    for(var item in formData){
      var run = formData[item]
      let runName = run.runName
      let iteration = run.runIterationNumber
      let prunedFile = `${custNme}_${runName}_movedLoadsAndDriverDecisionsFile.txt`
      let payload = {
        orgId: custNme,
        fileName: prunedFile,
        iteration: iteration
      }
      prunePayload.push(payload)
    } 
    this.postPruneFileInAws(prunePayload)
  }

  handleAddToStrategic(){
    if (!this.state.customer) {
      toast.error("You must select a customer before sending the data to strategic")
      return
    }
    toast.success("Adding to strategic...")
    let payload = this.createStrategicPayload()
    this.setState({addedCount:"adding to strategic..."})
    try {
        axios.post(`/parameters/engine-runs-api/individual-smart-tl-run-upload-to-strategic/`, payload).then((response)=>{
        if(response.data.success===true){
          this.setState({addedCount:"success"})
        }else{
          toast.error(`Error sending data to strategic: ${JSON.stringify(response.data.errors)}`)
          this.setState({addedCount:"error"})
        }
        return response
      })
      
    } catch (e) {
      console.error(e)
      }
  }
  

  postPruneFileInAws = (prunePayload) => {
    this.setState({prunedFilesCount:"pruning..."})
    axios.post(`/parameters/engine-runs-api/individual-smart-tl-run-upload/`, prunePayload)
      .then((response)=>{
        this.setState({
          prunedFilesCount:"success"
        })
          return response
      }).catch((e) => {
        toast.dismiss()
        console.error(e)
        toast.error("Error pruning data")
        this.setState({
          prunedFilesCount:"error"
        })
      })
  };

  createStrategicPayload(){
    let payload = {}
    payload["name"] = this.state.groupName
    payload["organization_subdomain"] = this.state.customer
    payload["runs"] = []
    let formData = [...this.state.inputFields];
    for(var item in formData){
      var run = formData[item]
      let runName = run.runName
      let runPayload = {}
      runPayload["name"] = runName
      if(run.parameterVersion) {
        runPayload["parameter_version"] = run.parameterVersion
      }
      runPayload["driver_file_path"] = formData[item].driversFileS3Path
      runPayload["load_file_path"] = formData[item].loadsFileS3Path
      runPayload["locations_file_path"] = formData[item].locationsFileS3Path
      runPayload["uncovered_loads_file_path"] = formData[item].uncoveredLoadsFileS3Path
      runPayload["moved_loads_file_path"] = formData[item].movedLoadsAndDriverDecisionsFileS3Path
      runPayload["load_marg_values_file_path"] = formData[item].loadMargValuesS3Path
      if(formData[item].optionalInputFileS3Path) {
        runPayload["optional_input_file_path"] = formData[item].optionalInputFileS3Path
      }
      runPayload["read_only"] = formData[item].readOnly.toLowerCase() === 'true'; 
      payload["runs"].push(runPayload)
    }
    return payload
  }

  uploadToAws = (fileToUploadPath, url) => {
    try {
      let file = fileToUploadPath.fileData
      var options = {
        headers: {
          'Content-Type': file.type
        }
      };
      s3axios.put(url, file, options).then((response)=>{
        this.setState({uploadedFilesCount:this.state.uploadedFilesCount+1})
        return response
      })     
    } catch (e) {
      console.error(e)
      }
  };

  getPreSignedUrls = async (objectNames, item, custName) => {
    try {
      try {
        return await (axios
          .get(
            `parameters/engine-runs-api/individual-smart-tl-run-upload/?objectNames=${objectNames}&orgId=${custName}`
          )
          .then((res) => {
            let formData=[...this.state.inputFields];
            let organization_subdomain = custName
            for (var key in res.data.values) {
              let result=res.data.values[key];
              let url_2=result;
              let pocReportsUrl=`poc-reports/${organization_subdomain}/uploads/${key}`;
              if (key.endsWith("drivers.txt")) {
                let driversFile=formData[item].driversFile;
                formData[item].driversFileS3Path=pocReportsUrl;
                this.uploadToAws(driversFile,url_2);
              } else if (key.endsWith("loads.txt")) {
                let loadsFile=formData[item].loadsFile;
                formData[item].loadsFileS3Path=pocReportsUrl;
                this.uploadToAws(loadsFile,url_2);
              } else if (key.endsWith("locations.txt")) {
                let locationsFile=formData[item].locationsFile;
                formData[item].locationsFileS3Path=pocReportsUrl;
                this.uploadToAws(locationsFile,url_2);
              } else if (key.endsWith("movedLoadsAndDriverDecisionsFile.txt")) {
                let movedLoadsAndDriverDecisionsFile=formData[item].movedLoadsAndDriverDecisionsFile;
                formData[item].movedLoadsAndDriverDecisionsFileS3Path=pocReportsUrl;
                this.uploadToAws(movedLoadsAndDriverDecisionsFile,url_2);
              } else if (key.endsWith("uncoveredLoadsFile.txt")) {
                let uncoveredLoadsFile=formData[item].uncoveredLoadsFile;
                formData[item].uncoveredLoadsFileS3Path=pocReportsUrl;
                this.uploadToAws(uncoveredLoadsFile,url_2);
              } else if (key.endsWith("loadMargValues.txt")) {
                let loadMargValues=formData[item].loadMargValues;
                formData[item].loadMargValuesS3Path=pocReportsUrl;
                this.uploadToAws(loadMargValues,url_2);
              } else if (key.endsWith("optionalInputFile.tar.gz")) {
                let optionalInputFile=formData[item].optionalInputFile;
                formData[item].optionalInputFileS3Path=pocReportsUrl;
                this.uploadToAws(optionalInputFile,url_2);
              } else {
                let errorMessage=`Could not map ${key}`;
                console.error(errorMessage);
              }
            }
          }));
      } catch (err) {
        console.error(err);
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {   
    const { classes } = this.props;
    const { customer, inputFields, uploadedFilesCount, prunedFilesCount, groupName, addedCount, customers } = this.state;
    return (
      <div className={classes.root}>
        <ToastContainer />
           <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}style={{ width: "100%" }}>
            <Grid item xs={12}> 
              <TextField label="Group Name" value={groupName} onChange={this.handleGroupNameChange} style={{width:"100%", margin:"5px 5px 5px 5px"}}></TextField>
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="customer-select-label">Customer</InputLabel>
              <Select 
               label="Customer"
               labelId="customer-select-label"
               value={customer} 
               onChange={this.handleCustChange} 
               style={{width:"100%",
               margin:"5px 5px 5px 5px"}}>
                  {
                  customers.map((cust, i) => (
                              <MenuItem key={i} value={cust}>
                                {cust}
                              </MenuItem>
                            ))}
                
               </Select>
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" onClick={this.handleAddRuns} >Add more runs...</Button>
            </Grid>
            {
            inputFields.map((input, index) => {
              return(
              <div key={index} variant="outlined" style={{padding: "5px 5px 5px 5px"}}>
                <Grid item xs={12}>
                    <TextField name="runName" label="Run Name" value={input.runName}  
                        onChange={ (e) => this.handleRunItemChange(index,e)} style={{width:"100%", margin:"5px 5px 5px 5px"}}></TextField>
                </Grid>

                <Grid item xs={12}>
                    <TextField name="runIterationNumber" label="Run Iteration Number" value={input.runIterationNumber}
                       onChange={ (e) => this.handleRunItemChange(index,e)} style={{width:"100%", margin:"5px 5px 5px 5px"}}></TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField name="parameterVersion" label="Parameter Version" value={input.parameterVersion}
                       onChange={ (e) => this.handleRunItemChange(index,e)} style={{width:"100%", margin:"5px 5px 5px 5px"}}></TextField>
                </Grid>
                <Grid item xs={12}>
                     <Button variant="contained" component="label" color="primary" style={{width:"100%", margin:"5px 5px 5px 5px"}}>
                          Drivers File- 
                          <input type="file" name='driversFile' value={input.driversFile.fileName ?? ""}
                          onChange={ event => this.handleRunFileChange(index, event, FileTypes.DriversFile)} />
                      </Button>  
                </Grid>
                <Grid item xs={12}>
                     <Button variant="contained" component="label" color="primary" style={{width:"100%", margin:"5px 5px 5px 5px"}}>
                          Loads File- 
                          <input type="file" name='loadsFile' value={input.loadsFile.fileName ?? ""}
                          onChange={ event => this.handleRunFileChange(index, event, FileTypes.LoadsFile)} />
                      </Button>  
                </Grid>
                <Grid item xs={12}>
                     <Button variant="contained" component="label" color="primary" style={{width:"100%", margin:"5px 5px 5px 5px"}}>
                          Locations File- 
                          <input type="file" name='locationsFile' value={input.locationsFile.fileName ?? ""} 
                          onChange={ event => this.handleRunFileChange(index, event, FileTypes.LocationsFile)} />
                      </Button>  
                </Grid>
                <Grid item xs={12}>
                     <Button variant="contained" component="label" color="primary" style={{width:"100%", margin:"5px 5px 5px 5px"}}>
                          Uncovered Loads File- 
                          <input type="file" name='uncoveredLoadsFile' value={input.uncoveredLoadsFile.fileName ?? ""} 
                          onChange={ event => this.handleRunFileChange(index, event, FileTypes.UncoveredFile)} />
                      </Button>  
                </Grid>
                <Grid item xs={12}>
                     <Button variant="contained" component="label" color="primary" style={{width:"100%", margin:"5px 5px 5px 5px"}}>
                          Moved Loads And Driver Decisions File- 
                          <input type="file" name='movedLoadsAndDriverDecisionsFile' value={input.movedLoadsAndDriverDecisionsFile.fileName ?? ""} 
                          onChange={ event => this.handleRunFileChange(index, event, FileTypes.MovedFile)} />
                      </Button>  
                </Grid>
                 <Grid item xs={12}>
                     <Button variant="contained" component="label" color="primary" style={{width:"100%", margin:"5px 5px 5px 5px"}}>
                        Load Marg Values File- 
                          <input type="file" name='loadMargValues' value={input.loadMargValues.fileName ?? ""} 
                          onChange={ event => this.handleRunFileChange(index, event, FileTypes.MargFile)} />
                      </Button>  
                </Grid>
                <Grid item xs={12}>
                     <Button variant="contained" component="label" color="primary" style={{width:"100%", margin:"5px 5px 5px 5px"}}>
                        Optional Input File-
                          <input type="file" name='optionalInputFile' value={input.optionalInputFile.fileName ?? ""}
                          onChange={ event => this.handleRunFileChange(index, event, FileTypes.OptionalInputFile)} />
                      </Button>
                </Grid>
                 <Grid item xs={12}>
                     <TextField label="Read Only" name="readOnly" value={input.readOnly} onChange={(e) => this.handleRunItemChange(index,e)} style={{width:"100%", margin:"5px 5px 5px 5px"}}></TextField>    
                </Grid>
                 <Grid item xs={12}>
                    <Button   variant="contained" component="label" color="primary" style={{width:"100%", margin:"5px 5px 5px 5px"}} onClick={ (e) => this.handleRemoveRun(index,e)} >Remove Run</Button>
                </Grid>
              </div>
              )           
            })
            }
            <Grid item xs={12}>
                    <Button variant="contained"  component="label" color="primary" style={{width:"100%", margin:"5px 5px 5px 5px"}} onClick={this.handleUploadFiles} >Upload Files {uploadedFilesCount}</Button>        
            </Grid>
            <Grid item xs={12}>
                    <Button variant="contained"  component="label" color="primary" style={{width:"100%", margin:"5px 5px 5px 5px"}} onClick={this.handlePrune} >Prune Files {prunedFilesCount}</Button>        
            </Grid>
           <Grid item xs={12}>
                    <Button variant="contained"  component="label" color="primary" style={{width:"100%", margin:"5px 5px 5px 5px"}} onClick={this.handleAddToStrategic} >Add To Strategic {addedCount}</Button>        
            </Grid>          
          </Grid>        
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(UploadRuns);
