import {
  SIGNIN_START,
  SIGNIN_SUCCESS,
  SIGNIN_FAIL,
  SIGNOUT,
} from "../constants/actionTypes";

const initialState = {
  token: null,
  error: null,
  loading: false,
  type: null,
};

const signInStart = (state, action) => {
  return { ...state, error: null, loading: true, type: action.type };
};

const signInSuccess = (state, action) => {
  return {
    ...state,
    token: action.token,
    loading: false,
    error: null,
    type: action.type,
  };
};

const signInFail = (state, action) => {
  return {
    ...state,
    token: null,
    loading: false,
    error: action.error,
    type: action.type,
  };
};

const signInClear = (state, action) => {
  return { ...state, ...initialState };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN_START:
      return signInStart(state, action);
    case SIGNIN_SUCCESS:
      return signInSuccess(state, action);
    case SIGNIN_FAIL:
      return signInFail(state, action);
    case SIGNOUT:
      return signInClear(state, action);
    default:
      return state;
  }
};

export default reducer;
