import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { SelectContainer } from "../../common/index";
export default function FormDialog(props) {
  return (
    <div>
      <Dialog
        open={props.show}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit </DialogTitle>
        <DialogContent>
          <Grid item xs={12} style={{ color: "red", paddingBottom: "15px" }}>
            {props.errorMsg}
          </Grid>
          <Grid item xs={12}>
            <SelectContainer
              type={"Organization copy"}
              title={"Organization"}
              style={{ paddingBottom: "15px" }}
              value={props.selectedOrganization || ""}
              optionLabel={"name"}
              valueLabel={"subdomain"}
              options={props.organization}
              handleChange={(e) => props.handleOrganizationChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectContainer
              type={"Application Select copy"}
              title={"Application"}
              style={{ paddingBottom: "15px" }}
              value={props.selectedApplication || ""}
              optionLabel={"name"}
              valueLabel={"id"}
              options={props.applicationTypes}
              handleChange={(e) => props.handleApplicationChange(e)}
            />
          </Grid>
          <DialogContentText>
            Enter name for new parameter version to be copied from
            <Typography style={{ fontWeight: 600 }}>
              {props.paramVersionName}
            </Typography>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Parameter Version Name"
            type="text"
            fullWidth
            onChange={props.onParamNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={props.handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
