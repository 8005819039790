import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import {
  withStyles,
  Typography,
  Accordion,
  AccordionSummary,
  Divider,
} from "@material-ui/core";
import { SelectContainer } from "../../common/index";
import { connect } from "react-redux";
import {
  GetOrganization,
  GetParameterSet,
  GetParameterVersion,
  GetEditParameterSchema,
  GetParameterUISchemaEditor,
  SetOrganization,
  SetParameterSet,
  SetParameterVersion,
  ReSetParameterVersion,
  ReSetParameterView,
  SaveParameterSchema,
  UpdateParameterSchema,
} from "../../store/actions";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ParameterEditor from "./parameterEditor";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  rootAccordian: {
    width: "100%",
    paddingBottom: "15px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridSection: {
    paddingBottom: "10px;",
  },
  btnParamAction: {
    "& > *": {
      margin: theme.spacing(1),
    },
    textAlign: "end",
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    width: 310,
    textAlign: "left",
    padding: 5,
    margin: 5,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    backgroundColor: "#fff",
    padding: "10px",
    minWidth: "30%",
    minHeight: "30%",
    "&:focus": {
      outline: "none",
    },
  },
  secondary: {
    color: "red",
  },
});

class EditSchema extends Component {
  constructor() {
    super();
    this.state = {
      schemaStatus: [],
      selectedSchema: {},
      parameterSchema: [],
    };
  }

  async componentDidMount() {
    this.props.reset();
    await this.props.getParameterSet();
  }

  GetParameterSchema = async (set) => {
    await this.props.getParmeterSchema(set);
  };

  expandAccordian = () => {
    this.setState({ defaultExpanded: true });
  };
  collapseAccordian = () => {
    this.setState({ defaultExpanded: false });
  };

  handleParameterSetChange = (e) => {
    this.props.setSelectedParameterSet(e.target.value);
    this.props.resetParameterVersion();
    this.resetAccordianStatus();
    this.GetParameterSchema(e.target.value);
  };
  handleParameterVersionChange = (e) => {
    this.props.setSelectedParameterVersion(e.target.value);
    this.GetParameterSchema();
  };

  onAccordianToggle = (item) => {
    this.setState({ selectedSchema: item });
    this.getSchemaEditorValue(item);
  };

  resetAccordianStatus = () => {
    this.setState({ schemaStatus: [] });
  };

  getSchemaEditorValue = (item) => {
    let param = {
      schemaId: item.id,
      versionId: this.props.selectedParameterVersion,
      isSchemaUpdate: true,
    };
    this.props.getSchemaEditorValues(param);
  };

  saveParameterSchema = (formData) => {
    this.props.saveParameterSchema(formData);
  };

  onEditorDataChange = (data) => {
    this.props.updateParameterSchema(data);
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.parameterSchema.length > 0) {
      this.setState({ parameterSchema: nextProps.parameterSchema });
    }
  };

  render() {
    const { classes, parameterSet, selectedParameterSet } = this.props;
    const { selectedSchema, parameterSchema } = this.state;
    return (
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Grid item xs={12} className={classes.gridSection}>
              <SelectContainer
                type={"Engine parameter set"}
                title={"Engine parameter set"}
                style={{ paddingBottom: "15px" }}
                value={selectedParameterSet || ""}
                optionLabel={"name"}
                valueLabel={"id"}
                options={parameterSet}
                handleChange={(e) => this.handleParameterSetChange(e)}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridSection}>
              {selectedSchema.schemaData && (
                <ParameterEditor
                  schema={selectedSchema.schemaData}
                  formData={selectedSchema.schemaFormData}
                  schemaId={selectedSchema.id}
                  parameterSet={selectedSchema.parameter_set}
                  onSave={this.saveParameterSchema}
                  rowData={selectedSchema}
                  showForm={false}
                  showSchema={true}
                  isUISchema={false}
                  onEditorDataChange={this.onEditorDataChange}
                  title={"Schema"}
                ></ParameterEditor>
              )}
            </Grid>
            <Grid item xs={12} className={classes.gridSection}>
              {selectedSchema.schemaData && (
                <ParameterEditor
                  schema={selectedSchema.schemaUIData}
                  formData={selectedSchema.schemaFormData}
                  schemaId={selectedSchema.id}
                  parameterSet={selectedSchema.parameter_set}
                  onSave={this.saveParameterSchema}
                  rowData={selectedSchema}
                  showForm={false}
                  showSchema={true}
                  isUISchema={true}
                  onEditorDataChange={this.onEditorDataChange}
                  title={"Schema UI"}
                ></ParameterEditor>
              )}
            </Grid>
          </Grid>
          <Grid item xs={7}>
            {parameterSchema.map((item) => (
              <div className={classes.rootAccordian}>
                <Accordion
                  onChange={() => this.onAccordianToggle(item)}
                  expanded={item.isExpanded}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      {item.name}
                    </Typography>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails>
                    {item.schemaData && (
                      <ParameterEditor
                        schema={item.schemaData}
                        formData={item.schemaFormData}
                        schemaId={item.id}
                        parameterSet={item.parameter_set}
                        onSave={this.saveParameterSchema}
                        rowData={item}
                        showForm={true}
                        showSchema={false}
                        showFormSave={false}
                        isReadOnly={true}
                        uiSchema={item.schemaUIData}
                      ></ParameterEditor>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.parameterView;
};
const mapDispatchToProps = (dispatch) => ({
  getOrganization: () => dispatch(GetOrganization()),
  getParameterSet: () => dispatch(GetParameterSet()),
  getParameterVersion: (filter) => dispatch(GetParameterVersion(filter)),
  getParmeterSchema: (paramSet) => dispatch(GetEditParameterSchema(paramSet)),
  getSchemaEditorValues: (param) => dispatch(GetParameterUISchemaEditor(param)),
  setSelectedOrganization: (orgId) => dispatch(SetOrganization(orgId)),
  setSelectedParameterSet: (paramSetId) =>
    dispatch(SetParameterSet(paramSetId)),
  setSelectedParameterVersion: (paramVerId) =>
    dispatch(SetParameterVersion(paramVerId)),
  resetParameterVersion: () => dispatch(ReSetParameterVersion()),
  reset: () => dispatch(ReSetParameterView()),
  saveParameterSchema: (data) => dispatch(SaveParameterSchema(data)),
  updateParameterSchema: (schema) => dispatch(UpdateParameterSchema(schema)),
});
export default withStyles(useStyles)(
  connect(mapStateToProps, mapDispatchToProps)(EditSchema)
);
