import React, { useState } from 'react';
import Editor from "@monaco-editor/react";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, withStyles } from "@material-ui/core/styles";


const CollapsibleEditor = ({ options, editorDidMount, classes }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed(prevState => !prevState);
    };

    return (
        <div>
            <div className={classes.showFilters}>
                <p>{isCollapsed ? 'Expand Filter Options' : 'Collapse Filter Options'}</p>
                <Checkbox
                    color="default"
                    checked={!isCollapsed}
                    default={false}
                    onClick={toggleCollapse}
                />
            </div>
            {!isCollapsed && (
                <>
                    <p>Loads File Filters</p>
                    <Editor
                        height="60vh"
                        theme={"light"}
                        language={"json"}
                        value={"[]"}
                        editorDidMount={editorDidMount}
                        options={options}
                    />
                </>
            )}
        </div>
    );
};

export default CollapsibleEditor;
