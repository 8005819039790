import React from "react";
import clsx from "clsx";
import { Drawer, Grid, Avatar, Typography, Button } from "@material-ui/core/";
import { Close, ArrowForward, Sync } from "@material-ui/icons";
import { FilterIcon } from "../../common/icons";
import SelectContainer from "../../common/SelectContainer";
import axios from "../../utils/axios_instance";
import draftStore from "../../utils/draftStore";
import { DateTimeContainer } from "../../common/DateTimeContainer";

class FilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      runActions: [],
      selectedRunAction: "",
      filter: draftStore.get("im-run-filter") ?? {},
      runSuccessful: [
        { id: "true", value: "true" },
        { id: "false", value: "false" },
      ],
    };
  }

  componentDidMount() {
    this.getRunActionFilter();
  }

  getRunActionFilter = async () => {
    const response = await axios.get(
      "/parameters/im-runs-api/im-runs-dropdown-filter/"
    );
    let runActions = [],
      organizations = [];
    if (response && response.data.run_action) {
      response.data.run_action.forEach((item) => {
        runActions.push({ id: item[0], value: item[0] });
      });
      response.data.organization.forEach((item) => {
        organizations.push({ id: item[0], value: item[0] });
      });

      this.setState({ runActions, organizations });
    }
  };

  onReset = () => {
    draftStore.remove("im-run-filter");
    this.setState({ filter: {}, dateRange: {}, dateSelector: [] });
    this.props.onSave();
  };

  handleChange = (e, name) => {
    const { filter } = this.state;
    filter[name] = e.target.value;
    this.setState({ filter });
  };

  applyFilter = () => {
    draftStore.set("im-run-filter", this.state.filter);
    this.props.onSave();
  };

  handleOnDateChange = (selectedDate, name) => {
    const { filter } = this.state;
    filter[name] = selectedDate;
    this.setState({ filter });
  };

  render() {
    const { classes, onClose, showFilter } = this.props;
    const { runActions, organizations, filter, runSuccessful } = this.state;
    return (
      <Drawer anchor={"right"} open={showFilter} onClose={(e) => onClose(e)}>
        <div className={classes.sidedrawer}>
          <Grid
            container
            style={{
              height: "56px",
              padding: "5px",
              borderBottom: "1px solid #312e3a",
            }}
            justify="flex-end"
          >
            <Grid item xs={1}>
              <Avatar className={clsx(classes.icon, classes.disableIcon)}>
                <FilterIcon style={{ fontSize: "1.2rem" }} />
              </Avatar>
            </Grid>
            <Grid
              item
              xs={8}
              style={{
                padding: "10px 20px",
                fontWeight: 600,
                marginRight: "15px",
              }}
            >
              Filter
            </Grid>

            <Grid item xs={2}>
              <Avatar
                className={clsx(classes.icon)}
                style={{ marginLeft: "16px" }}
                onClick={(e) => onClose(e)}
              >
                <Close />
              </Avatar>
            </Grid>
          </Grid>
          <div style={{ padding: "15px 40px" }}>
            <Typography
              variant="h4"
              style={{ fontSize: "1.5rem", paddingBottom: "15px" }}
            >
              <div style={{ fontWeight: "600" }}>Mapping Runs / </div>
              <div>Filter</div>
            </Typography>
            <div style={{ paddingBottom: "15px" }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SelectContainer
                    type={"Organization"}
                    title={"Organization"}
                    value={filter.organization__subdomain || ""}
                    style={{ paddingBottom: "15px" }}
                    handleChange={(e) =>
                      this.handleChange(e, "organization__subdomain")
                    }
                    optionLabel={"id"}
                    valueLabel={"value"}
                    options={organizations || []}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectContainer
                    type={"RunAction"}
                    title={"Run Action"}
                    value={filter.run_action || ""}
                    style={{ paddingBottom: "15px" }}
                    handleChange={(e) => this.handleChange(e, "run_action")}
                    optionLabel={"id"}
                    valueLabel={"value"}
                    options={runActions || []}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectContainer
                    type={"successfull"}
                    title={"Successfull"}
                    value={filter.is_successful || ""}
                    style={{ paddingBottom: "15px" }}
                    handleChange={(e) => this.handleChange(e, "is_successful")}
                    optionLabel={"id"}
                    valueLabel={"value"}
                    options={runSuccessful}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateTimeContainer
                    type={"StartDate"}
                    title={"Start Date"}
                    classes={classes}
                    disableUnderline={true}
                    handleChange={(e) =>
                      this.handleOnDateChange(e, "start_date")
                    }
                    value={filter.start_date}
                    style={{ paddingBottom: "10px" }}
                    onlyDatePicker={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateTimeContainer
                    type={"End Date"}
                    title={"End Date"}
                    classes={classes}
                    disableUnderline={true}
                    handleChange={(e) => this.handleOnDateChange(e, "end_date")}
                    value={filter.end_date}
                    style={{ paddingBottom: "10px" }}
                    onlyDatePicker={true}
                  />
                </Grid>
              </Grid>
            </div>
            <Button
              variant="contained"
              color="default"
              onClick={(e) => this.applyFilter()}
              className="primaryBtn"
              endIcon={<ArrowForward />}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="default"
              onClick={this.onReset}
              className="secondaryBtn"
              endIcon={<Sync />}
            >
              Reset
            </Button>
          </div>
        </div>
      </Drawer>
    );
  }
}
export default FilterContainer;
