import { Button, Grid, Typography, withStyles } from "@material-ui/core";
import Form from "@rjsf/core";
import React, { Component } from "react";
import Editor from "@monaco-editor/react";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import OverrideParameterDialog from "./dialogOverrideParam";
import API from "../../utils/axios_instance";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  favourite: {
    color: "blue",
    paddingRight: "30px",
    display: "none",
    cursor: "pointer",
  },
  favSelected: {
    color: "blue",
    paddingRight: "30px",
    cursor: "pointer",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
  },
  saveFormBtn: {
    background: "#47434f",
    height: "32px",
    color: "#ffffff",
  },
  saveFormBtnHide: {
    background: "#47434f",
    height: "32px",
    color: "#ffffff",
    display: "none",
  },
});

class ReactForm extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.formDataEditor = React.createRef();
    this.state = {
      isEditorReady: false,
      language: "json",
      theme: "light",
      showOverrideParamDialog: false,
      paramName: "",
      overrideParameterValue: null,
    };
  }
  componentDidMount = () => {};

  handleEditorDidMount(_, editorReference) {
    this.myRef.current = editorReference;
    this.setState({ isEditorReady: true });
    setTimeout(() => {
      this.myRef.current.getAction("editor.action.formatDocument").run();
    }, 200);
  }
  handleFormDataEditorDidMount(_, editorReference) {
    this.formDataEditor.current = editorReference;
    this.setState({ isEditorReady: true });
    setTimeout(() => {
      this.formDataEditor.current
        .getAction("editor.action.formatDocument")
        .run();
    }, 200);
  }

  onSubmit = ({ formData }) => {
    let jsonData = {
      schemaId: this.props.schemaId,
      parameterVersion: this.props.parameterVersion,
      schema: { values_json: formData },
      rowData: this.props.rowData,
      isSchemaUpdate: false,
    };
    this.props.onSave(jsonData);
  };

  applyEditorChange = () => {
    this.props.onEditorDataChange({
      schema: JSON.parse(this.myRef.current.getValue()),
      schemaId: this.props.schemaId,
      isUISchema: this.props.isUISchema,
    });

    setTimeout(() => {
      this.myRef.current.getAction("editor.action.formatDocument").run();
    }, 200);
  };

  onSaveSchemaData = () => {
    let jsonData = {
      schemaId: this.props.schemaId,
      parameterVersion: this.props.parameterVersion,
      rowData: this.props.rowData,
      schema: { schema_json: JSON.parse(this.myRef.current.getValue()) },
      isSchemaUpdate: true,
      isUISchema: this.props.isUISchema,
    };
    if (this.props.isUISchema) {
      jsonData.schema = {
        schema_ui_json: JSON.parse(this.myRef.current.getValue()),
      };
    }

    this.props.onSave(jsonData);
  };

  CustomFieldTemplate = (props) => {
    const { label, children, schema, description, errors, help } = props;
    const showFavIcon = this.props.showFormSaveBtn;
    const customFieldId = label ? label.replaceAll(" ", "") : "";
    return (
      <div
        id="myJsonForm"
        style={{
          display: "flex",
          paddingBottom: "10px",
          float: "left",
          width: "100%",
        }}
      >
        {schema.type && label && showFavIcon && (
          <>
            <StarIcon
              id={customFieldId + "myfav"}
              onClick={() =>
                this.onFavouriteClick(customFieldId + "myfav", props)
              }
              style={{
                color: "rgb(0, 128, 240)",
                paddingRight: "30px",
                display: "none",
                cursor: "pointer",
              }}
            ></StarIcon>
            <StarBorderIcon
              id={customFieldId}
              onClick={() => this.onFavouriteClick(customFieldId, props)}
              style={{
                color: "rgb(0, 128, 240)",
                paddingRight: "30px",
                cursor: "pointer",
              }}
            ></StarBorderIcon>
            <CreateIcon
              style={{ paddingRight: "10px" }}
              onClick={() => this.onOverrideItem(children._owner.key, props)}
            ></CreateIcon>
          </>
        )}
        {description && description.props.description && (
          <Tooltip title={description}>
            <IconButton
              aria-label="info"
              style={{ margin: "-12px", paddingRight: "15px" }}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
        <div
          style={{ width: "100%", display: "contents", whiteSpace: "nowrap" }}
        >
          {schema.type &&
            schema.type !== "boolean" &&
            schema.type !== "array" && <span id="myformLabel">{label} </span>}
          <Grid item xs={12} style={{ display: "flex", margin: 0 }}>
            {children}
            {errors}
            {help}
          </Grid>
        </div>
      </div>
    );
  };

  ArrayFieldTemplate = (jsonProps) => {
    return (
      <Grid>
        {jsonProps.title}
        {jsonProps.items.map((element) => (
          <div style={{ flexGrow: "1" }}>
            <Grid container spacing={0} style={{ display: "flex" }}>
              <Grid item xs={8}>
                <Grid>{element.children}</Grid>
              </Grid>
              {element.hasRemove && (
                <Grid item xs={4}>
                  <Grid style={{ textAlign: "center" }}>
                    <DeleteIcon
                      onClick={element.onDropIndexClick(element.index)}
                      style={{
                        color: "#47434f",
                        cursor: "pointer",
                        fontSize: "20px",
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        ))}
        {jsonProps.items.length === 0 && <br />}
        {jsonProps.canAdd && (
          <Button
            variant="contained"
            type="button"
            onClick={jsonProps.onAddClick}
            style={{
              background: "#47434f",
              height: "32px",
              color: "#ffffff",
              margin: "4px 0px 2px 0px",
            }}
          >
            Add Item
          </Button>
        )}
      </Grid>
    );
  };

  onFavouriteClick = (id, item) => {
    let myCustomId = id + "myfav";
    let isActive = true;
    if (id.indexOf("myfav") > -1) {
      document.getElementById(id).style.display = "none";
      let oldid = id.substring(0, id.length - 5);
      document.getElementById(oldid).style.display = "";
      isActive = false;
    } else {
      document.getElementById(myCustomId).style.display = "";
      document.getElementById(id).style.display = "none";
      isActive = true;
    }
    this.props.favouriteClick({
      favItem: item,
      schemaId: this.props.schemaId,
      isActive,
    });
  };

  setFavourite = () => {
    const myFav = this.props.userFavourites;
    if (myFav && this.props.showFormSaveBtn) {
      setTimeout(() => {
        for (let i = 0; i < myFav.length; i++) {
          let propertyName = myFav[i].property_name.replaceAll(" ", "");
          if (document.getElementById(propertyName) && myFav[i].is_active) {
            document.getElementById(propertyName + "myfav").style.display = "";
            document.getElementById(propertyName).style.display = "none";
          }
        }
      }, 300);
    }
  };

  onSchemaDownload = async () => {
    const fileName = this.props.rowData.name;
    const json = JSON.stringify(this.props.formData);
    const blob = new Blob([json], { type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  onSaveSchemaValue = () => {
    let jsonData = {
      schemaId: this.props.schemaId,
      parameterVersion: this.props.parameterVersion,
      schema: { values_json: JSON.parse(this.myRef.current.getValue()) },
      rowData: this.props.rowData,
      isSchemaUpdate: false,
    };
    this.props.onSave(jsonData);
  };

  onOverrideItem = (id) => {
    this.setState({ showOverrideParamDialog: true, paramName: id });
  };

  closeOverrideParamDialog = () => {
    this.setState({ showOverrideParamDialog: false });
  };
  onParamValueOverride = (e) => {
    this.setState({ overrideParameterValue: e.target.value });
  };

  onSaveOverrideParameter = () => {
    const data = {
      org_id: this.props.selectedOrganization,
      application: this.props.selectedApplication,
      property_key: this.state.paramName,
      user_property_value: this.state.overrideParameterValue,
      parameter_schema: this.props.schemaId,
    };
    try {
      API.post("/parameters/user-overrides/", data);
      this.setState({
        overrideParameterValue: "",
        showOverrideParamDialog: false,
      });
      this.props.getParameterOverride();
    } catch (error) {}
  };

  render() {
    const {
      classes,
      showForm,
      showSchema,
      schema,
      formData,
      rowData,
      showFormSaveBtn,
      isReadOnly,
      title,
      uiSchema,
    } = this.props;

    const { showOverrideParamDialog, paramName } = this.state;

    const editorCode = JSON.stringify(schema);
    const formValue = JSON.stringify(formData);
    const myFormData = !isReadOnly ? formData : null;
    const { theme, language } = this.state;

    if (schema) {
      this.setFavourite();
    }
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          {showSchema && (
            <Grid item xs={12}>
              <Grid>
                {!isReadOnly && (
                  <Typography style={{ fontWeight: "600" }}>
                    {title} - {rowData.name}
                  </Typography>
                )}
                {isReadOnly && (
                  <div style={{ marginBottom: "15px", textAlign: "end" }}>
                    <Button
                      variant="contained"
                      style={{
                        background: "#47434f",
                        height: "32px",
                        marginRight: "1%",
                        color: "#ffffff",
                      }}
                      type="button"
                      onClick={this.onSchemaDownload}
                    >
                      <GetAppIcon></GetAppIcon>
                      Download
                    </Button>

                    <Button
                      variant="contained"
                      style={{
                        background: "#47434f",
                        height: "32px",
                        marginRight: "1%",
                        color: "#ffffff",
                      }}
                      type="button"
                      onClick={this.onSaveSchemaValue}
                    >
                      Save Changes
                    </Button>
                  </div>
                )}
                <Editor
                  height="60vh"
                  theme={theme}
                  language={language}
                  value={isReadOnly ? formValue : editorCode}
                  editorDidMount={this.handleEditorDidMount.bind(this)}
                  options={options}
                />
                {!isReadOnly && (
                  <div style={{ marginTop: "15px", textAlign: "end" }}>
                    <Button
                      variant="contained"
                      style={{
                        background: "#47434f",
                        height: "32px",
                        marginRight: "1%",
                        color: "#ffffff",
                      }}
                      type="button"
                      onClick={this.applyEditorChange}
                    >
                      Preview
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        background: "#47434f",
                        height: "32px",
                        color: "#ffffff",
                      }}
                      type="button"
                      onClick={this.onSaveSchemaData}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          )}
          {showForm && (
            <Grid item xs={12}>
              <Grid>
                <Form
                  schema={schema}
                  onSubmit={this.onSubmit}
                  formData={myFormData}
                  FieldTemplate={this.CustomFieldTemplate}
                  //ObjectFieldTemplate={this.ObjectFieldTemplate}
                  ArrayFieldTemplate={this.ArrayFieldTemplate}
                  // disabled={this.props.isReadOnly}
                  uiSchema={uiSchema}
                  liveValidate
                  liveOmit
                >
                  <div style={{ marginTop: "15px", textAlign: "end" }}>
                    <Button
                      disabled={!showFormSaveBtn}
                      variant="contained"
                      className={
                        showFormSaveBtn
                          ? classes.saveFormBtn
                          : classes.saveFormBtnHide
                      }
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </Grid>
            </Grid>
          )}
        </Grid>
        <OverrideParameterDialog
          show={showOverrideParamDialog}
          handleClose={this.closeOverrideParamDialog}
          handleSave={() => this.onSaveOverrideParameter()}
          onParamValueOverride={this.onParamValueOverride}
          paramName={paramName}
        ></OverrideParameterDialog>
      </div>
    );
  }
}

export default withStyles(useStyles)(ReactForm);

const options = {
  acceptSuggestionOnCommitCharacter: true,
  acceptSuggestionOnEnter: "on",
  accessibilitySupport: "auto",
  autoIndent: true,
  automaticLayout: true,
  codeLens: false,
  colorDecorators: true,
  contextmenu: true,
  cursorBlinking: "blink",
  cursorSmoothCaretAnimation: false,
  cursorStyle: "line",
  disableLayerHinting: false,
  disableMonospaceOptimizations: false,
  dragAndDrop: false,
  fixedOverflowWidgets: false,
  folding: true,
  foldingStrategy: "auto",
  fontLigatures: false,
  formatOnPaste: true,
  formatOnType: true,
  hideCursorInOverviewRuler: false,
  highlightActiveIndentGuide: false,
  links: true,
  mouseWheelZoom: false,
  multiCursorMergeOverlapping: false,
  multiCursorModifier: "alt",
  overviewRulerBorder: false,
  overviewRulerLanes: 2,
  quickSuggestions: true,
  quickSuggestionsDelay: 100,
  readOnly: false,
  renderControlCharacters: false,
  renderFinalNewline: true,
  renderIndentGuides: true,
  renderLineHighlight: "all",
  renderWhitespace: "none",
  revealHorizontalRightPadding: 30,
  roundedSelection: true,
  rulers: [],
  scrollBeyondLastColumn: 5,
  scrollBeyondLastLine: true,
  selectOnLineNumbers: true,
  selectionClipboard: true,
  selectionHighlight: true,
  showFoldingControls: "mouseover",
  smoothScrolling: true,
  suggestOnTriggerCharacters: true,
  wordBasedSuggestions: true,
  wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
  wordWrap: "off",
  wordWrapBreakAfterCharacters: "\t})]?|&,;",
  wordWrapBreakBeforeCharacters: "{([+",
  wordWrapBreakObtrusiveCharacters: ".",
  wordWrapColumn: 80,
  wordWrapMinified: false,
  wrappingIndent: "none",
};
