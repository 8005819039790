import {
  CUSTOMER_FILE_TYPES_SUCCESS,
  CUSTOMER_FILE_TYPES_FAILURE,
} from "../constants/actionTypes";

const initialState = {
  extraInputOptions: null,
  error: null,
};

const onCustomerFileTypeSucces = (state, action) => {
  return {
    ...state,
    extraInputOptions: action.mappings,
  };
};
const onCustomerFileTypeFailure = (state, action) => {
  return {
    ...state,
    extraInputOptions: null,
    error: action.error,
  };
};

const ReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_FILE_TYPES_SUCCESS:
      return onCustomerFileTypeSucces(state, action);
    case CUSTOMER_FILE_TYPES_FAILURE:
      return onCustomerFileTypeFailure(state, action);
    default:
      return state;
  }
};

export default ReportsReducer;
