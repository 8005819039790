import React, { Component } from "react";
import PublishIcon from "@material-ui/icons/Publish";
import { Avatar, Grid } from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import "./Dropzone.css";

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  onDragOver(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    this.setState({ hightlight: true });
  }

  onDragLeave(event) {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    const { placeholder } = this.props;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <div
              className={`Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
              onDragOver={this.onDragOver}
              onDragLeave={this.onDragLeave}
              onDrop={this.onDrop}
              onClick={this.openFileDialog}
            >
              <input
                ref={this.fileInputRef}
                className="fileInput"
                type="file"
                multiple
                onChange={this.onFilesAdded}
                accept=".tar, .tar.gz, .gz"
              />
              <Avatar style={{ backgroundColor: "#000000" }}>
                <PublishIcon style={{ color: "#FFFFFF" }} />
              </Avatar>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: placeholder || "Upload your <b>files</b> here",
                  }}
                />
                , or
                <span style={{ color: "#2d6bff" }}> browse</span>
              </span>
              <span className="supportFile">Supports: .tar, .tar.gz, .gz</span>
            </div>
            <FolderIcon
              className="folderIconHover"
              style={{ fontSize: 80, color: "#00b0ff" }}
            ></FolderIcon>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Dropzone;
