import {
  SIGNIN_START,
  SIGNIN_SUCCESS,
  SIGNIN_FAIL,
  SIGNOUT,
} from "../constants/actionTypes";
import instance from "../../utils/axios_instance";
import cookies from "../../utils/cookies";

export const SignInStart = () => {
  return {
    type: SIGNIN_START,
  };
};

export const SignInSuccess = ({ token, error }) => {
  return {
    type: SIGNIN_SUCCESS,
    token,
    error,
  };
};

export const SignInFail = ({ error }) => {
  return {
    type: SIGNIN_FAIL,
    error,
  };
};

export const tokenExpired = () => {
  return {
    type: SIGNIN_FAIL,
    error: "Your login session expired.",
  };
};

export const signOut = () => {
  return async (dispatch) => {
    dispatch({ type: SIGNOUT });
    // if (cookies.get("token"))
    //   await instance.post("/token-logout/", {
    //     token: cookies.get("token"),
    //   });
    // cookies.remove("token");
    // clearState();
  };
};

export const signIn = (username, password) => {
  return async (dispatch) => {
    dispatch(SignInStart());
    try {
      const res = await instance.post("/token/", { username, password });
      dispatch(SignInSuccess({ token: res.data.token, error: null }));
      cookies.set("token", res.data.token);
    } catch (err) {
      if (err && err.response) {
        return dispatch(
          SignInFail({
            error: "Unable to log in with the provided credentials",
          })
        );
      }
      dispatch(SignInFail({ error: "Error with login endpoint" }));
    }
  };
};
