import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import React, { Component } from "react";
import cookies from "../../utils/cookies";
import EnvConfig from "../../config/config";
// import LinearWithValueLabel from "../progressBar/customLoader";
import LinearWithValueLabel from "../progressBar/customLoader";
import Dropzone from "./Dropzone";
import Mapping from "./selectMapping";
import "./Upload.css";
import UploadFileHeader from "./uploadFileHeader";
import { Button } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { capitalize } from "lodash";
import { connect } from "react-redux";
import { GetCustomerFileType } from "../../store/actions";
import Message from "./Message";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "42px 40px 0px 40px",
    alignItems: "flex-start",
    textAlign: "center",
    overflow: "hidden",
    backgroundColor: "#ffffff",
  },
  btnUpload: {
    marginTop: theme.spacing(5),
    backgroundColor: "#312e3a",
    color: "#fff",
    justifyContent: "inherit",
    "&:hover": {
      backgroundColor: "#47434f",
    },
  },
});

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileObjects: [],
      maxFilesAllowed: 10,
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      fileType: this.props.entityType,
      entityType: {},
      disableUpload: false,
      fileId: null,
      reportId: this.props.reportId,
      reportData: { report_type: {} },
      mappings: [],
      selectedFileType: 0,
      previouslySelected: this.props.selected,
      progress: 0,
      showProgresBar: false,
      isFileUplodSuccess: false,
    };

    this.onBack = this.onBack.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.resetUploader = this.resetUploader.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  async componentDidMount() {
    await this.props.getCustomerFileTypes();
  }

  onFilesAdded(files) {
    let fileObjects = this.state.fileObjects;
    let hasFileExtensionErrors = false;
    let fileCount = 0;
    files.forEach((file) => {
      if (fileCount < this.state.maxFilesAllowed) {
        let fileObject = {};
        fileObject["file"] = file;
        if (
          !fileObject["file"].name.includes("tar") &&
          !fileObject["file"].name.includes("tar.gz") &&
          !fileObject["file"].name.includes(".gz")
        ) {
          fileObject["fileExtensionError"] =
            "The file uploaded is not supported.";
          hasFileExtensionErrors = true;
        } else {
          fileObject["fileExtensionError"] = null;
          fileObject["selectedApplication"] = null;
          fileObject["selectedEngine"] = null;
          fileObject["selectedOrganization"] = null;
        }
        fileObjects.push(fileObject);
        fileCount++;
      }
    });
    this.setState({
      fileObjects: fileObjects,
      disableUpload: hasFileExtensionErrors,
    });
  }

  async uploadFiles() {
    const { fileObjects } = this.state;
    if (fileObjects.length === 0) {
      return;
    }

    let files = fileObjects.filter(
      (f) =>
        f.selectedOrganization === null ||
        f.selectedEngine === null ||
        f.selectedApplication === null
    );
    if (files.length > 0) {
      return;
    }
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    fileObjects.forEach((fileObject, index) => {
      promises.push(
        this.sendRequest(
          fileObject["file"],
          fileObject.selectedOrganization,
          fileObject.selectedApplication,
          fileObject.selectedEngine,
          index
        )
      );
    });
    try {
      const response = await Promise.all(promises);
      let json_response = JSON.parse(response);
      if (
        json_response.success === false &&
        json_response.errors &&
        json_response.errors.length > 0
      ) {
        //toast.error(json_response.errors[0].error_message);
        this.setState({ successfullUploaded: false, uploading: false });
        return;
      }
      if (typeof json_response === "string") {
        this.setState({ successfullUploaded: false, uploading: false });
        return;
      }
      this.setState({ successfullUploaded: true, uploading: true });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: false, uploading: false });
    }
  }

  sendRequest = (file, selOrganization, selAapplication, selEngine, index) => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100,
            response: null,
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.onload = () => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = {
          state: "done",
          percentage: 100,
          response: req.response,
        }; // TODO: revisit removed JSON.parse(req.response)
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      };

      req.upload.addEventListener("error", () => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0, response: null };
        this.setState({ uploadProgress: copy });
        reject(req.response.json());
      });

      // Note: fileType here is the id for the file_entity_type
      // it is intended in future to be the value that holds whether or not
      // it is a configuration file (vs a customer file)
      // on the back end this type integer is used
      // to get the file_entity_type currently.
      const formData = new FormData();
      formData.append("file", file);
      formData.append("organization_subdomain", selOrganization);
      formData.append("engine", selEngine);
      formData.append("application", selAapplication);
      req.open(
        "POST",
        EnvConfig.getConfig().apiUrl + "extra-params/extra-input-file/upload/"
      );
      req.setRequestHeader("Authorization", "Bearer " + cookies.get("token"));
      req.onreadystatechange = () => {
        if (req.readyState === 4) {
          let json_response = JSON.parse(req.response);
          if (
            json_response.success === false &&
            json_response.errors &&
            json_response.errors.length > 0
          ) {
            const { fileObjects } = this.state;
            fileObjects[index]["error"] = json_response.errors;
            this.setState({ fileObjects });
            return false;
          }

          this.setState({ isFileUplodSuccess: true });
        }
      };
      req.send(formData);
    });
  };

  renderActions(classes) {
    if (this.state.successfullUploaded || this.state.disableUpload) {
      return (
        <Button
          variant="contained"
          onClick={() =>
            this.setState({
              fileObjects: [],
              successfullUploaded: false,
              disableUpload: false,
            })
          }
          endIcon={<ArrowForward />}
          className={classes.btnUpload}
        >
          Clear
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          onClick={this.uploadFiles}
          disabled={this.state.disableUpload}
          endIcon={<ArrowForward />}
          className={classes.btnUpload}
        >
          Upload
        </Button>
      );
    }
  }

  handleSelectMapping = (e, id, fileObject) => {
    let newFileObjects = this.state.fileObjects.slice();
    newFileObjects.forEach((newFileObject) => {
      if (newFileObject["file"].name === fileObject["file"].name) {
        switch (id) {
          case "selectedApplication":
            fileObject["selectedApplication"] = e.target.value;
            break;
          case "selectedOrganization":
            fileObject["selectedOrganization"] = e.target.value;
            break;
          case "selectedEngine":
            fileObject["selectedEngine"] = e.target.value;
            break;
          default:
            break;
        }
      }
    });

    if (newFileObjects.length > 1) {
      let uniqueFiles = [];
      newFileObjects.map((x) =>
        uniqueFiles.filter(
          (y) =>
            y.selectedApplication === x.selectedApplication &&
            y.selectedOrganization === x.selectedOrganization &&
            y.selectedEngine === x.selectedEngine
        ).length === 0
          ? uniqueFiles.push(x)
          : ""
      );
      if (uniqueFiles.length !== newFileObjects.length) {
        newFileObjects.forEach((newFileObject) => {
          if (newFileObject["file"].name === fileObject["file"].name) {
            fileObject["error"] =
              "One archive per organization/application & engine is valid.";
          }
        });
      } else {
        newFileObjects.forEach((newFileObject) => {
          if (newFileObject["file"].name === fileObject["file"].name) {
            fileObject["error"] = null;
          }
        });
      }
    }
    this.setState({
      fileObjects: newFileObjects,
    });
  };

  handleDeleteFile = (_e, fileObject) => {
    let files = [...this.state.fileObjects];
    let index = files.findIndex((f) => fileObject.file.name === f.file.name);
    files.splice(index, 1);
    let hasErrors = false;
    files.forEach((file) => {
      if (file["fileExtensionError"] !== null) {
        hasErrors = true;
      }
    });
    this.setState({ fileObjects: files, disableUpload: hasErrors });
  };

  renderProgressOrUpload({ placeholder }) {
    if (this.state.uploading || this.state.successfullUploaded) {
      const uploadProgress =
        this.state.uploadProgress[this.state.fileObjects[0]["file"].name];
      const progress = uploadProgress ? uploadProgress.percentage : 0;
      return (
        <React.Fragment>
          <LinearWithValueLabel load={progress}></LinearWithValueLabel>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Dropzone
            placeholder={placeholder}
            onFilesAdded={this.onFilesAdded}
            disabled={this.state.uploading || this.state.successfullUploaded}
          />
        </React.Fragment>
      );
    }
  }

  onUploadFileNameChange = (event) => {
    const { value } = event.target;
    if (!value || this.state.fileObjects.length === 0) return;
    console.log(this.state.fileObjects);
    let fileObjects = [...this.state.fileObjects];
    const myNewFile = new File([this.state.fileObjects[0]["file"]], value, {
      type: this.state.fileObjects[0]["file"].type,
    });
    console.log(myNewFile);
    fileObjects[0]["file"] = myNewFile;
    this.setState({ fileObjects });
  };

  resetUploader() {
    this.setState({
      fileObjects: [],
      maxFilesAllowed: 1,
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      fileType: this.props.entityType,
      entityType: {},
      disableUpload: false,
      fileId: null,
      reportId: this.props.reportId,
      reportData: { report_type: {} },
      mappings: [],
      selectedFileType: 0,
      previouslySelected: this.props.selected,
      progress: 0,
      showProgresBar: false,
      isFileUplodSuccess: false,
    });
  }

  onBack = () => {
    this.resetUploader();
  };

  render() {
    const { classes, runTypeName, extraInputOptions } = this.props;
    const appHeader = `File Upload`;
    const { isFileUplodSuccess } = this.state;
    if (isFileUplodSuccess)
      return (
        <Message
          onBack={this.onBack}
          title={"Thank you! Your data has been successfully uploaded."}
        />
      );
    return (
      <div className={classes.root}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{appHeader}</title>
        </Helmet>
        <React.Fragment>
          <div className="Content">
            <Grid container>
              <Grid item xs={12}>
                <UploadFileHeader title="Upload Files"></UploadFileHeader>
              </Grid>
              <Grid item xs={12}>
                {this.renderProgressOrUpload({
                  placeholder: `Select the files for the <b>${capitalize(
                    runTypeName
                  )}</b> run`,
                })}
              </Grid>

              {this.state.fileObjects.map((fileObject) => (
                <Grid item xs={12}>
                  <Mapping
                    fileObject={fileObject}
                    mappingId={fileObject["file_type"]}
                    handleMappingChange={this.handleSelectMapping}
                    extraInputOptions={extraInputOptions}
                    handleDeleteFile={this.handleDeleteFile}
                    handleUploadFileNameChange={this.onUploadFileNameChange}
                  ></Mapping>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className="Actions">{this.renderActions(classes)}</div>
          <br />
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.uploadFile;
};

const mapDispatchToProps = (dispatch) => ({
  getCustomerFileTypes: () => dispatch(GetCustomerFileType()),
});
export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(Upload)
);
