import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Button, Tooltip, Avatar } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { FilterIcon } from "../../common/icons";
import { SelectContainer } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  GetApplicationTypes,
  GetOrganization,
} from "../../store/actions/parameterViewActions";
import axios from "../../utils/axios_instance";
import { FilterSettingsPanel } from "./filterSettings";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "25px",
  },
  toggleButtonGroup: {
    background: "#332D3C",
    borderRadius: "100px",
    marginRight: "45px",
    marginTop: "20px",
    width: "100px",
    height: "36px",
  },
  toggleButton: {
    width: "100px",
    height: "36px",
    background: "#332D3C",
    borderRadius: "100px",
    color: "#ffffff",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 24px",
    cursor: "pointer",
    "&.Mui-selected": {
      background: "#453D51",
      color: "#ffffff",
      borderRadius: "100px",
      "&:hover": {
        background: "#453D51",
      },
    },
    "&:hover": {
      background: "#332D3C",
    },
  },
  icon: {
    backgroundColor: "#f0f0f0",
    color: "#000000",
    fontWeight: "500",
    margin: theme.spacing(1),
    width: "32px",
    cursor: "pointer",
    height: "32px",
    "& > *": {
      fontSize: "1.3rem",
    },
    "&:hover": {
      backgroundColor: "#e8e8e8",
    },
    "&:disabled": {
      backgroundColor: "#b1b3b3",
    },
  },
}));

export default function EditOrgSettings(props) {
  const { settings, onSaveSuccess, settingsOptions } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.parameterView);
  const [isEditMode, setEditMode] = useState(false);
  const [selectedApplication, setselectedApplication] = useState("");
  const [selectedOrg, setselectedselectedOrg] = useState("");
  const [settingName, setSettingname] = useState("");
  const [settingStatus, setsettingStatus] = useState("off");
  const [error, setError] = useState(null);
  const [showFilterPanel, setShowFilterPanel] = useState(false);

  const [applicationSettingsOption, setapplicationSettingsOption] = useState(
    []
  );
  useEffect(() => {
    dispatch(GetApplicationTypes());
    dispatch(GetOrganization());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (settings) {
      setSettingname(settings.application_setting_name);
      setselectedApplication(settings.application_setting.application);
      setselectedselectedOrg(settings.organization.subdomain);
      setsettingStatus(settings.application_value ? "on" : "off");
      setEditMode(true);
      const filteredSettings = settingsOptions.filter((item) => {
        return item.application === settings.application_setting.application;
      });
      setapplicationSettingsOption(filteredSettings);
    } else {
      setapplicationSettingsOption(settingsOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const handleApplicationChange = (e) => {
    const application = e.target.value;
    setselectedApplication(application);
    const filteredSettings = settingsOptions.filter((item) => {
      return item.application === application;
    });
    setapplicationSettingsOption(filteredSettings);
  };

  const handleOrganizationChange = (e) => {
    setselectedselectedOrg(e.target.value);
  };

  const handleSettingNameChange = (e) => {
    setSettingname(e.target.value);
  };

  const onApplyFilter = () => {
    setShowFilterPanel(false);
  };

  const createUpdateSettings = async () => {
    setError(null);
    if (!settingName && !selectedApplication && !selectedOrg && !settingStatus)
      return;

    try {
      if (isEditMode) {
        const data = {
          name: settingName,
          organization_subdomain: selectedOrg,
          application_value: settingStatus === "on" ? true : false,
        };
        const response = await axios
          .put(`application-setting/operational/${settings.id}/`, data)
          .catch(function (error) {
            if (error.response) {
              setError(error.response.data.error);
            }
          });

        if (response) {
          setselectedApplication("");
          setselectedselectedOrg("");
          setsettingStatus("off");
          setSettingname("");
          onSaveSuccess();
        }
      } else {
        const data = {
          name: settingName,
          organization_subdomain: selectedOrg,
          application_value: settingStatus === "on" ? true : false,
        };
        const response = await axios
          .post(`/application-setting/operational/`, data)
          .catch(function (error) {
            if (error.response) {
              setError(error.response.data.error);
            }
          });

        if (response) {
          setselectedApplication("");
          setselectedselectedOrg("");
          setsettingStatus("off");
          setSettingname("");
          onSaveSuccess();
        }
      }
    } catch (error) {
      setError("some error occured.");
    }
  };

  const changeSettingValue = (e, nextView) => {
    if (nextView === null) return;

    setsettingStatus(nextView);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid item xs={11} style={{ display: "flex" }}>
            <SelectContainer
              style={{ width: "300px", marginRight: "25px" }}
              type={"Organization"}
              title={"Organization"}
              value={selectedOrg || ""}
              optionLabel={"name"}
              valueLabel={"subdomain"}
              options={state.organization}
              handleChange={(e) => handleOrganizationChange(e)}
            />

            <SelectContainer
              style={{ width: "300px", marginRight: "25px" }}
              type={"Application Select"}
              title={"Application"}
              value={selectedApplication || ""}
              optionLabel={"name"}
              valueLabel={"id"}
              options={state.applicationTypes}
              handleChange={(e) => handleApplicationChange(e)}
            />

            <SelectContainer
              style={{ width: "300px", marginRight: "25px" }}
              type={"settingName"}
              title={"Setting Name"}
              value={settingName || ""}
              optionLabel={"name"}
              valueLabel={"id"}
              options={applicationSettingsOption}
              handleChange={(e) => handleSettingNameChange(e)}
            />

            <ToggleButtonGroup
              exclusive
              className={classes.toggleButtonGroup}
              value={settingStatus}
              aria-label="settingstatus"
              onChange={changeSettingValue}
            >
              <ToggleButton
                className={classes.toggleButton}
                value="on"
                aria-label="on"
              >
                On
              </ToggleButton>
              <ToggleButton
                className={classes.toggleButton}
                value="off"
                aria-label="off"
              >
                Off
              </ToggleButton>
            </ToggleButtonGroup>

            <Button
              style={{ width: "200px", margin: "0px !important" }}
              variant="contained"
              color="default"
              className="primaryBtnSmll"
              endIcon={<ArrowForward />}
              onClick={() => createUpdateSettings()}
            >
              {isEditMode ? "Update" : "Create"}
            </Button>
          </Grid>

          <Grid item xs={1} container justify="flex-end">
            <Tooltip title="Filter">
              <Avatar
                onClick={(e) => setShowFilterPanel(true)}
                className={classes.icon}
              >
                <FilterIcon fill="#ffffff" />
              </Avatar>
            </Tooltip>
          </Grid>
        </Grid>
        {error !== null && (
          <Grid item xs={12} style={{ color: "red" }}>
            {error}
          </Grid>
        )}
        <FilterSettingsPanel
          onClose={(e) => setShowFilterPanel(false)}
          onSave={onApplyFilter}
          classes={classes}
          showFilter={showFilterPanel}
        />
      </Grid>
    </div>
  );
}
