import {
  PARAMETER_SET_FAIL,
  PARAMETER_SET_SUCCESS,
  PARAMETER_VERSION_SUCCESS,
  PARAMETER_VERSION__FAIL,
  ORGANIZATION_FAIL,
  ORGANIZATION_SUCCESS,
  PARAMETER_SCHEMA_FAIL,
  PARAMETER_SCHEMA_SUCCESS,
  PARAMETER_READ_ONLY_STATUS_SUCCESS,
  SET_PARAMETER_READ_ONLY_STATUS_SUCCESS,
  SCHEMA_EDITOR_SUCCESS,
  SET_ORGANIZATION,
  SET_PARAMETER_VERSION,
  SET_PARAMETER_SET,
  RESET_PARAMETER_VERSION,
  RESET_PARAMETER_VIEW,
  SAVE_PARAMETER_SCHEMA_FAIL,
  SAVE_PARAMETER_SCHEMA_SUCCESS,
  UPDATE_PARAMETER_SCHEMA,
  COPY_PARAMETER_VERSION_SUCCESS,
  COPY_PARAMETER_VERSION_FAIL,
  RESET_PARAMETER_ON_COPY,
  GET_USER_FAVOURITES,
  SAVE_USER_FAVOURITES_FAIL,
  SAVE_USER_FAVOURITES_SUCCESS,
  SET_SELECTED_FAVOURITES,
  SHOW_SELECTED_FAVOURITES,
  SET_APPLICATION,
  UPDATE_INCULDE_VERSION,
  GET_APPLICATION_TYPES,
  DELETE_PARAM_VERSION_SUCCESS,
  DELETE_PARAM_VERSION_FAIL,
  HIDE_ERROR_MESSAGE,
  SET_ORGANTIZATION_FILTER,
} from "../constants/actionTypes";
import API from "../../utils/axios_instance";

export const GetOrganizationSuccess = (organization) => {
  return {
    type: ORGANIZATION_SUCCESS,
    organization,
  };
};
export const GetOrganizationFail = (error) => {
  return {
    type: ORGANIZATION_FAIL,
    error,
  };
};
export const GetOrganization = () => {
  return async (dispatch) => {
    try {
      const res = await API.get(
        "/parameters/parameter-values/editor/get-organizations/"
      );
      dispatch(GetOrganizationSuccess(res.data.organizations));
    } catch (err) {
      if (err && err.response) {
        return dispatch(GetOrganizationFail(err.response));
      }
      dispatch(GetOrganizationFail(err));
    }
  };
};

export const GetParameterSetSuccess = (parameterSet) => {
  return {
    type: PARAMETER_SET_SUCCESS,
    parameterSet,
  };
};
export const GetParameterSetFail = (error) => {
  return {
    type: PARAMETER_SET_FAIL,
    error,
  };
};
export const GetParameterSet = () => {
  return async (dispatch) => {
    try {
      const res = await API.get("/parameters/parameter-set/");
      dispatch(GetParameterSetSuccess(res.data.results));
    } catch (err) {
      if (err && err.response) {
        return dispatch(GetParameterSetFail(err.response));
      }
      dispatch(GetParameterSetFail(err));
    }
  };
};

export const GetParameterVersionSuccess = (parameterVersion) => {
  return {
    type: PARAMETER_VERSION_SUCCESS,
    parameterVersion,
  };
};
export const GetParameterVersionFail = (error) => {
  return {
    type: PARAMETER_VERSION__FAIL,
    error,
  };
};
export const GetParameterVersion = (filter) => {
  return async (dispatch) => {
    try {
      const query = [];
      for (let key in filter) {
        if (key === "organization" && filter[key]) {
          query.push(`org=${filter[key].subdomain}`);
        }
        if (key === "parameterSet" && filter[key]) {
          query.push(`set=${filter[key].id}`);
        }
      }
      const res = await API.get(
        `/parameters/parameter-version/?${query.join("&")}`
      );
      dispatch(GetParameterVersionSuccess(res.data.results));
    } catch (err) {
      if (err && err.response) {
        return dispatch(GetParameterVersionFail(err.response));
      }
      dispatch(GetParameterVersionFail(err));
    }
  };
};

export const GetParameterSchemaSuccess = (parameterSchema) => {
  return {
    type: PARAMETER_SCHEMA_SUCCESS,
    parameterSchema,
  };
};
export const GetReadOnlyStatus = (read_only) => {
  return {
    type: PARAMETER_READ_ONLY_STATUS_SUCCESS,
    read_only,
  };
};
export const SetReadOnlyStatus = (read_only) => {
  return {
    type: SET_PARAMETER_READ_ONLY_STATUS_SUCCESS,
    read_only,
  };
};

export const GetParameterSchemaFail = (error) => {
  return {
    type: PARAMETER_SCHEMA_FAIL,
    error,
  };
};
export const GetParameterSchema = (paramVersion) => {
  return async (dispatch) => {
    try {
      const res = await API.get(
        `parameters/schema-detail-version/${paramVersion}/`
      );
      dispatch(GetParameterSchemaSuccess(res.data.parameter_schema));
      dispatch(GetReadOnlyStatus(res.data.read_only));
    } catch (err) {
      if (err && err.response) {
        return dispatch(GetParameterSchemaFail(err.response));
      }
      dispatch(GetParameterSchemaFail(err));
    }
  };
};

export const SetParameterReadOnlyStatus = (read_only) => {
  return async (dispatch) => {
    try {
      dispatch(SetReadOnlyStatus(read_only));
    } catch (err) {
      console.error(err)
    }
  };
};

export const GetParameterUISchemaEditorSuccess = (schemaEditor) => {
  return {
    type: SCHEMA_EDITOR_SUCCESS,
    schemaEditor,
  };
};
export const GetParameterUISchemaEditor = (data) => {
  return async (dispatch) => {
    try {
      let url = "";
      if (data.isSchemaUpdate) {
        url = `/parameters/parameter-schema/editor/${data.schemaId}/`;
      } else {
        url = `/parameters/parameter-values/editor/${data.schemaId}/${data.parameterVersion}/`;
      }
      const res = await API.get(url);
      dispatch(
        GetParameterUISchemaEditorSuccess({
          schemaId: data.schemaId,
          versionId: data.versionId,
          schemaData: res.data,
        })
      );
    } catch (err) {
      if (err && err.response) {
        return dispatch(GetParameterUISchemaEditorSuccess({}));
      }
      dispatch(GetParameterUISchemaEditorSuccess({}));
    }
  };
};

export const SetOrganization = (selectedOrganization) => {
  return {
    type: SET_ORGANIZATION,
    selectedOrganization,
  };
};
export const SetParameterSet = (selectedParameterSet) => {
  return {
    type: SET_PARAMETER_SET,
    selectedParameterSet,
  };
};
export const SetParameterVersion = (selectedParameterVersion) => {
  return {
    type: SET_PARAMETER_VERSION,
    selectedParameterVersion,
  };
};

export const ReSetParameterVersion = () => {
  return {
    type: RESET_PARAMETER_VERSION,
  };
};

export const ReSetParameterView = () => {
  return {
    type: RESET_PARAMETER_VIEW,
  };
};

export const SaveParameterSchemaSuccess = (status) => {
  return {
    type: SAVE_PARAMETER_SCHEMA_SUCCESS,
    status,
  };
};
export const SaveParameterSchemaFail = (error) => {
  return {
    type: SAVE_PARAMETER_SCHEMA_FAIL,
    error,
  };
};
export const SaveParameterSchema = (data) => {
  return async (dispatch) => {
    try {
      let url = "";
      if (data.isSchemaUpdate && data.isUISchema) {
        url = `/parameters/parameter-schema-ui/editor/${data.schemaId}/`;
      } else if (data.isSchemaUpdate) {
        url = `/parameters/parameter-schema/editor/${data.schemaId}/`;
      } else {
        url = `/parameters/parameter-values/editor/${data.schemaId}/${data.parameterVersion}/`;
      }
      //console.log(url);
      const res = await API.patch(url, data.schema);
      dispatch(SaveParameterSchemaSuccess(res.data));
    } catch (err) {
      if (err && err.response) {
        return dispatch(SaveParameterSchemaFail(err.response));
      }
      dispatch(SaveParameterSchemaFail(err));
    }
  };
};

export const UpdateParameterSchema = (schema) => {
  return {
    type: UPDATE_PARAMETER_SCHEMA,
    schema,
  };
};

export const CopyParameterVersionSuccess = (newParameterVersion) => {
  return {
    type: COPY_PARAMETER_VERSION_SUCCESS,
    newParameterVersion,
  };
};
export const CopyParameterVersionFail = (error) => {
  return {
    type: COPY_PARAMETER_VERSION_FAIL,
    error,
  };
};
export const CopyParameterVersion = (copyData) => {
  return async (dispatch) => {
    try {
      const res = await API.post(
        `/parameters/copy-parameter-version/`,
        copyData
      );
      dispatch(CopyParameterVersionSuccess(res.data));
    } catch (err) {
      if (err && err.response) {
        return dispatch(CopyParameterVersionFail(err.response));
      }
      dispatch(CopyParameterVersionFail(err));
    }
  };
};

export const ReSetParameterVersiononCopy = () => {
  return {
    type: RESET_PARAMETER_ON_COPY,
  };
};

export const GetUserFavouritesSuccess = (favourites) => {
  return {
    type: GET_USER_FAVOURITES,
    favourites,
  };
};
export const GetUserFavourites = (parameterVersion) => {
  return async (dispatch) => {
    try {
      const res = await API.get(
        `/parameters/get-user-favourites/${parameterVersion}/`
      );
      dispatch(GetUserFavouritesSuccess(res.data.favourites));
    } catch (err) {
      if (err && err.response) {
        return dispatch(GetUserFavouritesSuccess(err.response));
      }
      dispatch(GetUserFavouritesSuccess(err));
    }
  };
};

export const SaveUserFavouritesSuccess = (favourites) => {
  return {
    type: SAVE_USER_FAVOURITES_SUCCESS,
    favourites,
  };
};
export const SaveUserFavouritesFail = (favSaveError) => {
  return {
    type: SAVE_USER_FAVOURITES_FAIL,
    favSaveError,
  };
};
export const SaveUserFavourites = (favourites) => {
  return async (dispatch) => {
    try {
      const res = await API.post(`/parameters/set-user-favourite/`, favourites);
      dispatch(SaveUserFavouritesSuccess(res.data));
    } catch (err) {
      if (err && err.response) {
        return dispatch(SaveUserFavouritesFail(err.response));
      }
      dispatch(SaveUserFavouritesFail(err));
    }
  };
};

export const SetFavouritItem = (selectedFavourites) => {
  return {
    type: SET_SELECTED_FAVOURITES,
    selectedFavourites,
  };
};

export const ShowSelectedFavourites = (selectedFavourites) => {
  return {
    type: SHOW_SELECTED_FAVOURITES,
    selectedFavourites,
  };
};
export const SetSelectedApplication = (selectedApplication) => {
  return {
    type: SET_APPLICATION,
    selectedApplication,
  };
};

export const UpdateInculdedVersionSuccess = (schema) => {
  return {
    type: UPDATE_INCULDE_VERSION,
    schema,
  };
};
export const UpdateInculdedVersion = (schema) => {
  return async (dispatch) => {
    try {
      const res = await API.patch(`/parameters/parameter-values/`, schema);
      dispatch(UpdateInculdedVersionSuccess(res.data));
    } catch (err) {
      if (err && err.response) {
        return dispatch(UpdateInculdedVersionSuccess(err.response));
      }
      dispatch(UpdateInculdedVersionSuccess(err));
    }
  };
};

export const GetEditParameterSchemaSuccess = (parameterSchema) => {
  return {
    type: PARAMETER_SCHEMA_SUCCESS,
    parameterSchema,
  };
};
export const GetEditParameterSchemaFail = (error) => {
  return {
    type: PARAMETER_SCHEMA_FAIL,
    error,
  };
};
export const GetEditParameterSchema = (setId) => {
  return async (dispatch) => {
    try {
      const url = `/parameters/parameter-schema/?set=${setId}`;

      const res = await API.get(url);
      dispatch(GetEditParameterSchemaSuccess(res.data.results));
    } catch (err) {
      if (err && err.response) {
        return dispatch(GetEditParameterSchemaFail(err.response));
      }
      dispatch(GetEditParameterSchemaFail(err));
    }
  };
};

export const GetApplicationTypesSuccess = (applicationTypes) => {
  return {
    type: GET_APPLICATION_TYPES,
    applicationTypes,
  };
};
export const GetApplicationTypes = (setId) => {
  return async (dispatch) => {
    try {
      const url = `/parameters/application-names/`;

      const res = await API.get(url);
      dispatch(GetApplicationTypesSuccess(res.data.applications));
    } catch (err) {
      if (err && err.response) {
        return dispatch(GetApplicationTypesSuccess([]));
      }
      dispatch(GetApplicationTypesSuccess([]));
    }
  };
};

export const GetParameterSetByApplicationSuccess = (parameterSet) => {
  return {
    type: PARAMETER_SET_SUCCESS,
    parameterSet,
  };
};
export const GetParameterSetByApplicationFail = (error) => {
  return {
    type: PARAMETER_SET_FAIL,
    error,
  };
};
export const GetParameterSetByApplication = (appName) => {
  return async (dispatch) => {
    try {
      const res = await API.get(`/parameters/parameter-sets/${appName}/`);
      dispatch(GetParameterSetByApplicationSuccess(res.data.parameter_sets));
    } catch (err) {
      if (err && err.response) {
        return dispatch(GetParameterSetByApplicationFail(err.response));
      }
      dispatch(GetParameterSetByApplicationFail(err));
    }
  };
};

export const DeleteParameterVersionSuccess = (deletedItem) => {
  return {
    type: DELETE_PARAM_VERSION_SUCCESS,
    deletedItem,
  };
};
export const DeleteParameterVersionFail = (error) => {
  return {
    type: DELETE_PARAM_VERSION_FAIL,
    error,
  };
};
export const DeleteParameterVersion = (versionId) => {
  return async (dispatch) => {
    try {
      const res = await API.delete(
        `/parameters/parameter-version/${versionId}/`
      );
      dispatch(DeleteParameterVersionSuccess(res.data));
    } catch (err) {
      if (err && err.response) {
        return dispatch(DeleteParameterVersionFail(err.response));
      }
      dispatch(DeleteParameterVersionFail(err));
    }
  };
};

export const HideErrorMessage = () => {
  return {
    type: HIDE_ERROR_MESSAGE,
  };
};

export const SetOrganizationFilter = (organizationFilter) => {
  return {
    type: SET_ORGANTIZATION_FILTER,
    organizationFilter,
  };
};
