//SignIn
export const SIGNIN_START = "SIGNIN_START";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "SIGNIN_FAIL";
export const SIGNOUT = "SIGNOUT";

//Dispatch
export const LOADS_START = "LOADS_START";
export const LOADS_SUCCESS = "LOADS_SUCCESS";
export const LOADS_FAIL = "LOADS_FAIL";
export const LOADS_CLEAR = "LOADS_CLEAR";
export const LOADS_LOADING = "LOADS_LOADING";

//Driver
export const DRIVER_START = "DRIVER_START";
export const DRIVER_SUCCESS = "DRIVER_SUCCESS";
export const DRIVER_FAIL = "DRIVER_FAIL";
export const DRIVER_CLEAR = "DRIVER_CLEAR";
export const DRIVER_LOADING = "DRIVER_LOADING";

//ParameterView
export const ORGANIZATION_SUCCESS = "ORGANIZATION_SUCCESS";
export const ORGANIZATION_FAIL = "ORGANIZATION_FAIL";
export const PARAMETER_SET_SUCCESS = "PARAMETER_SET_SUCCESS";
export const PARAMETER_SET_FAIL = "PARAMETER_SET_FAIL";
export const PARAMETER_VERSION_SUCCESS = "PARAMETER_VERSION_SUCCESS";
export const PARAMETER_VERSION__FAIL = "PARAMETER_VERSION__FAIL";
export const PARAMETER_SCHEMA_SUCCESS = "PARAMETER_SCHEMA_SUCCESS";
export const PARAMETER_READ_ONLY_STATUS_SUCCESS = "PARAMETER_READ_ONLY_STATUS_SUCCESS";
export const SET_PARAMETER_READ_ONLY_STATUS_SUCCESS = "SET_PARAMETER_READ_ONLY_STATUS_SUCCESS";
export const PARAMETER_SCHEMA_FAIL = "PARAMETER_SCHEMA_FAIL";
export const SCHEMA_EDITOR_SUCCESS = "SCHEMA_EDITOR_SUCCESS";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const SET_PARAMETER_SET = "SET_PARAMETER_SET";
export const SET_PARAMETER_VERSION = "SET_PARAMETER_VERSION";
export const RESET_PARAMETER_VERSION = "RESET_PARAMETER_VERSION";
export const RESET_PARAMETER_VIEW = "RESET_PARAMETER_VIEW";
export const SAVE_PARAMETER_SCHEMA_SUCCESS = "SAVE_PARAMETER_SCHEMA_SUCCESS";
export const SAVE_PARAMETER_SCHEMA_FAIL = "SAVE_PARAMETER_SCHEMA_FAIL";
export const UPDATE_PARAMETER_SCHEMA = "UPDATE_PARAMETER_SCHEMA";
export const COPY_PARAMETER_VERSION_SUCCESS = "COPY_PARAMETER_VERSION_SUCCESS";
export const COPY_PARAMETER_VERSION_FAIL = "COPY_PARAMETER_VERSION_FAIL";
export const RESET_PARAMETER_ON_COPY = "RESET_PARAMETER_ON_COPY";
export const SAVE_USER_FAVOURITES_SUCCESS = "SAVE_USER_FAVOURITES_SUCCESS";
export const SAVE_USER_FAVOURITES_FAIL = "SAVE_USER_FAVOURITES_FAIL";
export const GET_USER_FAVOURITES = "GET_USER_FAVOURITES";
export const SET_SELECTED_FAVOURITES = "SET_SELECTED_FAVOURITES";
export const SHOW_SELECTED_FAVOURITES = "SHOW_SELECTED_FAVOURITES";
export const SET_APPLICATION = "SET_APPLICATION";
export const UPDATE_INCULDE_VERSION = "UPDATE_INCULDE_VERSION";
export const GET_APPLICATION_TYPES = "GET_APPLICATION_TYPES";
export const DELETE_PARAM_VERSION_SUCCESS = "DELETE_PARAM_VERSION_SUCCESS";
export const DELETE_PARAM_VERSION_FAIL = "DELETE_PARAM_VERSION_FAIL";
export const HIDE_ERROR_MESSAGE = "HIDE_ERROR_MESSAGE";
export const SET_ORGANTIZATION_FILTER = "SET_ORGANIZATION_FILTER";

//Upload
export const CUSTOMER_FILE_TYPES_SUCCESS = "CUSTOMER_FILE_TYPES_SUCCESS";
export const CUSTOMER_FILE_TYPES_FAILURE = "CUSTOMER_FILE_TYPES_FAILURE";
