import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Paper,
  TableFooter,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Avatar,
  Link
} from "@material-ui/core/";
import APIV2 from "../../utils/axios_instance_v2";
import Moment from "react-moment";
import FilterPanel from "./FilterPanel";
import { FilterIcon } from "../../common/icons";
import PublishIcon from "@material-ui/icons/Publish";
import draftStore from "../../utils/draftStore";
import { CloudUpload } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import { compose } from 'redux'


function withFeatureFlags(Component) {
  return function WrappedComponent(props) {

    return <Component {...props} />;
  }
}

const TopActionItems = (props) => {
  const {classes, showFilterPanel} = props;

  return (
    <Grid item xs={6} container justifyContent="flex-end">
      <Tooltip title="New Individual Run">
          <Link href="/individual-runs">
            <Avatar
              className={classes.icon}
            >
              <PublishIcon fill="#ffffff" />
            </Avatar>
          </Link>
      </Tooltip>
      <Tooltip title="Upload Run">
          <Link href="/upload-runs">
            <Avatar
              className={classes.icon}
            >
              <CloudUpload fill="#ffffff" />
            </Avatar>
          </Link>
      </Tooltip>
      <Tooltip title="Filter">
        <Avatar
          onClick={(e) => showFilterPanel()}
          className={classes.icon}
        >
          <FilterIcon fill="#ffffff" />
        </Avatar>
      </Tooltip>
    </Grid>
  )
}

const styles = (theme) => ({
  root: {
    paddingTop: "20px",
  },
  btnStyle: {
    marginTop: theme.spacing(2),
    backgroundColor: "#312e3a",
    color: "#fff",
    justifyContent: "inherit",
    "&:hover": {
      backgroundColor: "#47434f",
    },
  },
  capitalize: {
    textTransform: "capitalize",
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  tableHeader: {
    backgroundColor: "rgb(51, 45, 60)",
    color: "#fff",
    textAlign: "left",
  },
  paperStyle: {
    paddingTop: "10%",
    textAlign: "center",
  },
  actionLink: {
    cursor: "pointer",
    padding: "6px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  sidedrawer: {
    width: "35vw",
  },
  icon: {
    backgroundColor: "#f0f0f0",
    color: "#000000",
    fontWeight: "500",
    margin: theme.spacing(1),
    width: "32px",
    cursor: "pointer",
    height: "32px",
    "& > *": {
      fontSize: "1.3rem",
    },
    "&:hover": {
      backgroundColor: "#e8e8e8",
    },
    "&:disabled": {
      backgroundColor: "#b1b3b3",
    },
  },
  stickyHeader: {
    maxHeight: "calc(100vh - 194px)",
  },
  tableCell: {
    padding: "8px",
  },
});

const defaultNumberRows = 20;

class EngineRuns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      engineRuns: [],
      showMaestroSmartTlRuns: false,
      rowsPerPage: defaultNumberRows,
      count: 0,
      page: 0,
      sort: {
        key: "created_at",
        column: "created_at",
        isAsc: false,
      },
      headers: [
        {
          sort: true,
          sortKey: "run_action",
          label: "Run Action",
          column: "run_action",
        },
        {
          sort: true,
          sortKey: "organization__subdomain",
          label: "Organization",
          column: "organization.subdomain",
        },
        {
          sort: true,
          sortKey: "run_id",
          label: "Run Id",
          column: "run_id",
        },
        {
          sort: true,
          sortKey: "task_id",
          label: "Task Id",
          column: "task_id",
        },
        {
          sort: true,
          sortKey: "is_successful",
          label: "Successful",
          column: "is_successful",
        },
        {
          sort: true,
          sortKey: "created_at",
          label: "Created Date",
          column: "created_at",
        },
        {
          sort: false,
          label: "Run Status",
          column: "status",
        },
        {
          sort: false,
          label: "Run Duration",
          column: "duration",
        },
        {
          sort: true,
          sortKey: "source",
          label: "Source",
          column: "source",
        },
      ],
      showFilterPanel: false,
      filter: {},
    };
    this.getEngineRuns = this.getEngineRuns.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.setShowMaestroSmartTlRuns = this.setShowMaestroSmartTlRuns.bind(this)
  }

  componentDidMount() {
    this.getEngineRuns();
  }

  async getEngineRuns() {
    const { rowsPerPage, page, sort, showMaestroSmartTlRuns } = this.state;
    let offset = page * rowsPerPage,
      limit = rowsPerPage;
    let ordering = sort.isAsc ? sort.key : `-${sort.key}`;
    let selectedFilter = {
      ordering,
      limit,
      offset,
    };
    if (showMaestroSmartTlRuns) {
      selectedFilter = {
        ...selectedFilter,
        include_maestro_child_runs: true
      }
    }
    let filter = draftStore.get("engine-run-filter") ?? {};
    let myfilter = { ...selectedFilter, ...filter };
    const query = [];
    let url = "/parameters/engine-runs-api/";
    for (let key in myfilter) query.push(`${key}=${myfilter[key]}`);
    const {
      data: { results, count },
    } = await APIV2.get(`${url}?${query.join("&")}`);
    this.setState({ engineRuns: [], count }, () => {
      this.setState({ engineRuns: results });
    });
  }

  setSort = (event, property) => {
    const { sort } = this.state;
    sort.isAsc = sort.key === property.sortKey ? !sort.isAsc : true;
    sort.key = sort.isAsc ? property.sortKey : `${property.sortKey}`;
    sort.column = property.column || property.sortKey;
    this.setState({ sort }, () => {
      this.getEngineRuns();
    });
  };

  handleChangePage(event, page) {
    this.setState({ page }, () => {
      this.getEngineRuns();
    });
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value, page: 0 }, () => {
      this.getEngineRuns();
    });
  }

  setShowMaestroSmartTlRuns(event) {
    this.setState({ showMaestroSmartTlRuns: !this.state.showMaestroSmartTlRuns, page: 0 }, () => {
      this.getEngineRuns();
    });
  }

  engineRunView = (run) => {
    this.props.history.push(`engine-runs/${run.pk}`);
  };

  showFilterPanel = () => {
    this.setState({showFilterPanel: true});
  }

  onApplyFilter = () => {
    this.setState({ showFilterPanel: false });
    this.getEngineRuns();
  };

  getRunId = (run) =>{
    let hasValidRunId = run.payload != null && run.payload.length > 0;

    if(hasValidRunId){
      return run.payload[0].run_id;
    } 

    return "";
  }

  getRunIdV2 = (run) => {
    return run?.external_run_id
  }

  getTaskId = (run) =>{
    let hasValidRunId = run.payload != null && run.payload.length > 0;

    if(hasValidRunId){
      return run.payload[0].task_id;
    } 

    return "";
  }

  getTaskIdV2 = (run) =>{
    return run?.task_id
  }

  getSource = (run) =>{
    return run.source || 'smart-tl'
  }

  getStatus = (run) => {
      let status = "";

      let hasValidRunStatus = run.payload != null && run.payload.length > 0 && run.payload[0].status_payload != null;

      if(hasValidRunStatus) {
        status = run.payload[0].status_payload.sucessful;
      } else if(run.is_successful != null){
        status = run.is_successful;
      } else{
        status = "";
      }

      let formattedStatus = status.toString().toUpperCase();

      return formattedStatus
  }

  render() {
    const { classes } = this.props;
    const { engineRuns, page, count, rowsPerPage, headers, sort } = this.state;
    return (
      <div className={classes.root}>
        <Grid
          container
          className={classes.header}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container item xs={11}>
            <Grid item xs={6}>
              <Typography
                variant="h4"
                style={{ fontSize: "1.6rem", paddingBottom: "10px" }}
              >
                SMART-TL Engine Runs
              </Typography>
            </Grid>

            <TopActionItems classes={classes} showFilterPanel={this.showFilterPanel}/>

            <Grid item xs={6}>
                Include Maestro child runs
                <Tooltip title="Include in version">
                  <Checkbox
                    color="default"
                    checked={this.state.showMaestroSmartTlRuns}
                    onClick={() => this.setShowMaestroSmartTlRuns(!this.state.showMaestroSmartTlRuns)}
                  />
                </Tooltip>
            </Grid>
            <Grid item xs={12} className={classes.tableContainer}>
              <TableContainer
                component={Paper}
                className={classes.stickyHeader}
              >
                <Table
                  className={classes.table}
                  stickyHeader
                  aria-label="simple table"
                >
                  <TableHead style={{ padding: 0 }}>
                    <TableRow style={{ backgroundColor: "#ffffff" }}>
                      {headers.map((header, idx) => (
                        <TableCell
                          align={"left"}
                          style={{ padding: "8px", minWidth: "100px" }}
                          key={idx}
                        >
                          <TableSortLabel
                            active={sort.key === header.sortKey}
                            hideSortIcon={!header.sort}
                            direction={sort.isAsc ? "asc" : "desc"}
                            onClick={(event) =>
                              header.sort && this.setSort(event, header)
                            }
                            style={{ fontWeight: 600 }}
                          >
                            {header.label || ""}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {engineRuns.length === 0 && (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tableCell}
                          colSpan={4}
                          style={{ textAlign: "center", fontWeight: 600 }}
                        >
                          No Engine Run Found.
                        </TableCell>
                      </TableRow>
                    )}
                    {engineRuns.length > 0 &&
                      engineRuns.map((run, index) => (
                        <TableRow
                          data-testid={`smart-tl-run-${index}`}
                          key={`${run.run_action}-${index}`}
                          onClick={() => this.engineRunView(run)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            {run.run_action}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            {run.organization ? run.organization.subdomain : ""}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            {this.getRunIdV2(run)}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            {this.getTaskIdV2(run)}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            {this.getStatus(run)}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            <Moment
                              parse="YYYY-MM-DD HH:mm:ss"
                              format="YYYY-MM-DD HH:mm"
                            >
                              {run.created_at}
                            </Moment>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            {run.status}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            {run.duration > -1 && (
                              <span>{run.duration} minutes</span>
                            )}
                            {run.duration == -1 && (
                              <span>N/A</span>
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableCell}
                          >
                            {this.getSource(run)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableFooter>
                    <TableRow style={{ backgroundColor: "#ffffff" }}>
                      <TableCell align="right" style={{ padding: 0 }}>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50]}
                          component="div"
                          count={count}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          backIconButtonProps={{
                            style: {
                              minWidth: "26px",
                              padding: "7px",
                              marginRight: "10px",
                            },
                          }}
                          nextIconButtonProps={{
                            style: { minWidth: "26px", padding: "7px" },
                          }}
                          onPageChange={this.handleChangePage}
                          onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <FilterPanel
            onClose={(e) => this.setState({ showFilterPanel: false })}
            onSave={this.onApplyFilter}
            classes={classes}
            showFilter={this.state.showFilterPanel}
          />
        </Grid>
      </div>
    );
  }
}

export default compose(
  withFeatureFlags,
  withStyles(styles, { withTheme: true })
)(EngineRuns)
