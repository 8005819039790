import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Link,
  AppBar,
  Toolbar,
  CssBaseline,
  Button,
  Grid,
  Typography,
  Paper,
} from "@material-ui/core/";
import {
  MuiThemeProvider,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import { ArrowForward } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { signIn, signOut, tokenExpired } from "../../store/actions/index";
import { withAuth0 } from "@auth0/auth0-react";

const styles = (theme) => ({
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    alignItems: "center",
    padding: `${theme.spacing(7)}px ${theme.spacing(7)}px ${theme.spacing(
      4
    )}px`,
    boxShadow: "none",
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(5),
    backgroundColor: "#312e3a",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#47434f",
    },
  },
  maxHeight: {
    height: "100vh",
    backgroundColor: "white",
  },
  help: {
    display: "box",
    boxPack: "center",
    boxAlign: "center",
    textAlign: "right",
  },
  bannerImage: {
    backgroundImage: "url('assets/images/signin-background.png')",
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  labelIcon: {
    fontSize: "1.2rem",
  },
  title: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(),
  },
  footer: {
    // position: "fixed",
    bottom: theme.spacing(5),
    padding: `${theme.spacing(5)}px ${theme.spacing(7)}px ${theme.spacing(
      4
    )}px`,
  },
});

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ["IBM Plex Sans", "sans-serif"].join(","),
    fontWeight: 600,
  },
});

class Login extends React.Component {
  state = {
    userName: "",
    password: "",
  };

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  getToken = (event) => {
    event.preventDefault();
    this.props.signIn(this.state.userName, this.state.password);
  };

  componentWillMount() {
    this.props.signOut();
  }

  componentDidMount() {
    // const {
    //   location: { hash },
    // } = this.props;
    // if (hash.includes("expired")) {
    //   this.props.tokenExpired();
    //   window.location.hash = "";
    // }
  }

  render() {
    const {
      classes,
      auth0: { isAuthenticated, isLoading, loginWithRedirect },
    } = this.props;
    if (isLoading) return null;
    if (isAuthenticated) return <Redirect to="/" />;
    return (
      <MuiThemeProvider theme={theme}>
        <React.Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Login</title>
          </Helmet>
          <CssBaseline />
          <Grid container>
            <Grid item md={4} className={classes.maxHeight}>
              <AppBar position="static" color="default">
                <Toolbar>
                  <Typography variant="h6" className={classes.title}>
                    <img
                      alt="logo"
                      src="assets/images/New_ODLogo_Black.png"
                      width="200px"
                      height="20px"
                    />
                  </Typography>
                </Toolbar>
              </AppBar>
              <Paper className={classes.paper}>
                <Typography
                  component="h1"
                  style={{ fontSize: "2rem", fontWeight: 600 }}
                >
                  Sign In
                </Typography>
                <form className={classes.form}>
                  {/* {error && (
                    <FormHelperText error={true}>{error}</FormHelperText>
                  )}
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="name-simple">Username</InputLabel>
                    <Input
                      id="name-simple"
                      name="name-simple"
                      value={this.state.userName}
                      endAdornment={
                        <InputAdornment>
                          <Person className={classes.labelIcon} />
                        </InputAdornment>
                      }
                      onChange={this.handleChange("userName")}
                    />
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password-simple">Password</InputLabel>
                    <Input
                      name="password-simple"
                      type="password"
                      id="password-simple"
                      autoComplete="current-password"
                      value={this.state.password}
                      endAdornment={
                        <InputAdornment>
                          <Lock className={classes.labelIcon} />
                        </InputAdornment>
                      }
                      onChange={this.handleChange("password")}
                    />
                  </FormControl>
                  <Grid container>
                    <Grid item md={8}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.checkedA}
                              name="checkedA"
                            />
                          }
                          label="Remember Me"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={4} className={classes.help}>
                      <Link href="#">Need help?</Link>
                    </Grid>
                  </Grid> */}
                  <Button
                    // type="submit"
                    fullWidth
                    variant="contained"
                    // onClick={this.getToken}
                    onClick={loginWithRedirect}
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                      fontWeight: 600,
                    }}
                    endIcon={<ArrowForward />}
                    className={classes.submit}
                  >
                    Sign in
                  </Button>
                </form>
              </Paper>
              <footer className={classes.footer}>
                <b>Warning:</b> Use of this System is Restricted to Authorized
                Users. <Link href="#">Read More</Link>
              </footer>
            </Grid>
            <Grid item md={8} className={classes.bannerImage}></Grid>
          </Grid>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state.auth;
};

const mapDispatchToProps = (dispatch) => ({
  signIn: (username, password) => dispatch(signIn(username, password)),
  signOut: () => dispatch(signOut()),
  tokenExpired: () => dispatch(tokenExpired()),
});

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(withAuth0(Login))
);
