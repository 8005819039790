import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({});

const SelectContainer = ({
  style,
  type,
  options,
  title,
  value,
  valueLabel,
  optionLabel,
  handleChange,
  classes,
  variant = "outlined",
  additionalLabel = null,
}) => {
  return (
    <FormControl variant={variant} style={{ width: "100%", ...style }}>
      <InputLabel htmlFor={`${type}-native-label-placeholder`}>
        {title}
      </InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        label={title}
        classes={classes}
        inputProps={{
          name: title,
          id: `${title}-native-label-placeholder`,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {options &&
          options.map((item, idx) => (
            <MenuItem
              key={idx}
              value={valueLabel ? item[valueLabel] : item.value}
              style={{
                paddingLeft: "5px",
              }}
            >
              {optionLabel
                ? additionalLabel
                  ? `${item[optionLabel]}, ${item[additionalLabel]}`
                  : item[optionLabel]
                : item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(SelectContainer);
