import axios from "axios";
import EnvConfig from "../config/config";
import cookies from "./cookies";

const instance = axios.create({
  baseURL: EnvConfig.getConfig().apiV2Url,
  timeout: 100000,
  headers: { "Content-Type": "application/json" },
});

instance.interceptors.request.use(
  (config) => {
    if (!["/token-logout/", "/token/"].includes(config.url))
      config.headers.Authorization = `Bearer ${cookies.get("token")}`;
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401)
      window.location.pathname = "/login";
    return Promise.reject(error);
  }
);

export default instance;
