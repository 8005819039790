import React, { Component } from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";

const styles = () => ({
  header: {
    fontFamily: "IBM Plex Sans",
    fontSize: "24px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#000000",
    textAlign: "justify",
  },
  container: {
    backgroundColor: "#FFFF",
    padding: "15px",
    // minHeight: "50px",
  },
  titleBox: {
    minHeight: "32px",
    borderRadius: "16px",
    backgroundColor: "#f0f0f0",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "2px",
  },
  title: {
    fontSize: " 12px",
    color: "#000000",
    fontWeight: "600",
  },
});

class UploadFileHeader extends Component {
  state = {};

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid item xs={4}>
              <Typography variant="h4" className={classes.header}>
                {this.props.title ? this.props.title : "Report Header"}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              &nbsp;
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(UploadFileHeader);
