const k8sConfigs = {
    apiUrl: process.env.REACT_APP_PARAMETERS_API,
    env: "dev",
    domain: "core-ai.io",
    clientSideId: "620eacbb1663d70c84ca1b1f",
    sdkKey: "sdk-fc31a1c3-21b4-45e3-8353-e4c1186107ce",
    missionControlBaseUrl: "https://dev.app.optimaldynamics.io/mc",
  };
  
export default k8sConfigs;
