import devConfigs from "./dev_env";
import demoConfigs from "./demo_env";
import localConfigs from "./local_env";
import k8sConfigs from "./k8s_env";
import prodConfigs from "./prod_env";
import qaConfigs from "./qa_env";

class EnvConfig {
  constructor() {
    if (process.env.REACT_APP_ENV === "development") {
      this.config = devConfigs;
    } else if (process.env.REACT_APP_ENV === "demo") {
      this.config = demoConfigs;
    } else if (process.env.REACT_APP_ENV === "local") {
      this.config = localConfigs;
    } else if (process.env.REACT_APP_ENV === "k8s") {
      this.config = k8sConfigs;
    } else if (process.env.REACT_APP_ENV === "qa") {
      this.config = qaConfigs;
    } else if (process.env.REACT_APP_ENV === "production") {
      this.config = prodConfigs;
    } else {
      this.config = devConfigs;
    }
  }

  static getConfig() {
    if (process.env.REACT_APP_ENV === "development") {
      return devConfigs;
    } else if (process.env.REACT_APP_ENV === "demo") {
      return demoConfigs;
    } else if (process.env.REACT_APP_ENV === "local") {
      return localConfigs;
    } else if (process.env.REACT_APP_ENV === "k8s") {
      return k8sConfigs;
    } else if (process.env.REACT_APP_ENV === "qa") {
      return qaConfigs;
    } else if (process.env.REACT_APP_ENV === "production") {
      return prodConfigs;
    } else {
      return devConfigs;
    }
  }
}

export default EnvConfig;
