import React from 'react';

const renderListItems = (data) => {
    console.log(`Data is ${JSON.stringify(data)}`)
  return Object.keys(data).map((key) => {
    if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
      return (
        <li key={key}>
          <strong>{key}</strong>
          <ul>{renderListItems(data[key])}</ul>
        </li>
      );
    } else {
      return (
        <li key={key}>
          <strong>{key}</strong>: {data[key]}
        </li>
      );
    }
  });
};

const MetricData = ({ data }) => {
  return (
    <div>
      <ul>{renderListItems(data)}</ul>
    </div>
  );
};

export default MetricData;
