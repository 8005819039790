import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import reducers from "./reducers";
import thunk from "redux-thunk";
import { loadState, saveState } from "./persistedStore";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadState();

export default function configureStore() {
  const store = createStore(
    combineReducers({
      ...reducers,
    }),
    persistedState,
    composeEnhancers(applyMiddleware(thunk))
  );

  store.subscribe(() => {
    saveState({
      loads: store.getState().loads,
    });
  });
  return store;
}
