import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import axios from "../../utils/axios_instance";
import EditIcon from "@material-ui/icons/Edit";
import EditAppSettings from "./editAppSettings";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationDialog from "./confirmationDialog";

const columns = [
  { id: "application", label: "Application", minWidth: 120, align: "left" },
  { id: "name", label: "Name", minWidth: 120, align: "left" },
  {
    id: "created_at",
    label: "Created At",
    minWidth: 120,
    align: "left",
  },
  {
    id: "updated_at",
    label: "Updated At",
    minWidth: 120,
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 120,
    align: "left",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: 0,
    margin: 0,
  },
  container: {
    maxHeight: "calc(100vh - 305px)",
  },
  tableHead: {
    fontWeight: 700,
    fontSize: "14px",
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [rows, SetRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [isEditRow, setEditRow] = useState(false);
  const [editedItem, setEditItem] = useState(null);
  const [showConfirmationDialog, setshowConfirmationDialog] = useState(false);

  useEffect(() => {
    getAppSetting();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAppSetting = async () => {
    const response = await axios.get("/application-setting/?limit=1000");
    if (response && response.status === 200) {
      SetRows(response.data.results);
    } else {
      SetRows([]);
    }
  };

  const editRow = (data) => {
    setEditItem(data);
    setEditRow(true);
  };

  const deleteRow = async () => {
    try {
      const response = await axios.delete(
        `/application-setting/${editedItem.id}/`
      );
      if (response) {
        getAppSetting();
        setshowConfirmationDialog(false);
        setEditItem(null);
        setEditRow(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTableCell = (row, column) => {
    const value = row[column.id];
    if (column.id !== "action") {
      return (
        <TableCell
          key={column.id}
          align={column.align}
          style={{ padding: "8px" }}
        >
          {value}
        </TableCell>
      );
    } else {
      return (
        <TableCell
          key={column.id}
          align={column.align}
          style={{ padding: "8px" }}
        >
          <EditIcon
            style={{ color: "#47434f", cursor: "pointer", marginRight: "15px" }}
            onClick={() => editRow(row)}
          />

          <DeleteIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => onConfirmDelete(row)}
          />
        </TableCell>
      );
    }
  };

  const onConfirmDelete = (row) => {
    setshowConfirmationDialog(true);
    setEditItem(row);
  };

  return (
    <>
      {isEditRow && (
        <EditAppSettings
          settings={editedItem}
          onSaveSuccess={() => {
            setEditRow(false);
            getAppSetting();
          }}
        />
      )}
      {!isEditRow && <EditAppSettings onSaveSuccess={() => getAppSetting()} />}
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#ffffff" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      padding: "8px",
                    }}
                    className={classes.tableHead}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => getTableCell(row, column))}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {showConfirmationDialog && (
        <ConfirmationDialog
          showDialog={showConfirmationDialog}
          msg={`Please confirm the deletion of ${editedItem.name}`}
          title="Delete App Settings?"
          handleAgree={() => deleteRow()}
          handleClose={() => setshowConfirmationDialog(false)}
        />
      )}
    </>
  );
}
