import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import EditAppSettings from "./appSettings";
import OrgSettings from "./orgSettings";
import OnboardingStatus from "./onboardingStatus";
import { Paper } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "10px 48px 0px 48px",
    boxShadow: "none",
  },

  indicator: {
    backgroundColor: "white",
  },
}));

export default function SchemView() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <AppBar
        position="static"
        style={{
          height: "48px",
          background: "rgb(71, 67, 79)",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab label="Edit Settings" {...a11yProps(0)} />
          <Tab label="Assign Settings" {...a11yProps(1)} />
          <Tab label="Onboarding Status" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <EditAppSettings />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrgSettings />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OnboardingStatus />
      </TabPanel>
    </Paper>
  );
}
